/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Balance,
    BalanceFromJSON,
    BalanceToJSON,
    UserMe,
    UserMeFromJSON,
    UserMeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMeWithBalance
 */
export interface UserMeWithBalance {
    /**
     * 
     * @type {string}
     * @memberof UserMeWithBalance
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeWithBalance
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    isMod?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserMeWithBalance
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof UserMeWithBalance
     */
    pic?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    isExternal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMeWithBalance
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    emailVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    emailNotify: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    iosPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    androidPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    browserPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeWithBalance
     */
    hasPassword: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMeWithBalance
     */
    intercomIdentity?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserMeWithBalance
     */
    categorySubscriptions?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserMeWithBalance
     */
    balance: number;
}

export function UserMeWithBalanceFromJSON(json: any): UserMeWithBalance {
    return {
        'userId': json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isMod': !exists(json, 'isMod') ? undefined : json['isMod'],
        'created': new Date(json['created']),
        'pic': !exists(json, 'pic') ? undefined : json['pic'],
        'isExternal': !exists(json, 'isExternal') ? undefined : json['isExternal'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailVerified': !exists(json, 'emailVerified') ? undefined : json['emailVerified'],
        'emailNotify': json['emailNotify'],
        'iosPush': json['iosPush'],
        'androidPush': json['androidPush'],
        'browserPush': json['browserPush'],
        'hasPassword': json['hasPassword'],
        'intercomIdentity': !exists(json, 'intercomIdentity') ? undefined : json['intercomIdentity'],
        'categorySubscriptions': !exists(json, 'categorySubscriptions') ? undefined : json['categorySubscriptions'],
        'balance': json['balance'],
    };
}

export function UserMeWithBalanceToJSON(value?: UserMeWithBalance): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'userId': value.userId,
        'name': value.name,
        'isMod': value.isMod,
        'created': value.created.toISOString(),
        'pic': value.pic,
        'isExternal': value.isExternal,
        'email': value.email,
        'emailVerified': value.emailVerified,
        'emailNotify': value.emailNotify,
        'iosPush': value.iosPush,
        'androidPush': value.androidPush,
        'browserPush': value.browserPush,
        'hasPassword': value.hasPassword,
        'intercomIdentity': value.intercomIdentity,
        'categorySubscriptions': value.categorySubscriptions,
        'balance': value.balance,
    };
}


