/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    SubscriptionEventTypeComment,
    SubscriptionEventTypeCommentFromJSON,
    SubscriptionEventTypeCommentToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubscriptionListenerComment
 */
export interface SubscriptionListenerComment {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionListenerComment
     */
    listenerUrl: string;
    /**
     * 
     * @type {SubscriptionEventTypeComment}
     * @memberof SubscriptionListenerComment
     */
    eventType: SubscriptionEventTypeComment;
}

export function SubscriptionListenerCommentFromJSON(json: any): SubscriptionListenerComment {
    return {
        'listenerUrl': json['listenerUrl'],
        
        'eventType': SubscriptionEventTypeCommentFromJSON(json['eventType']),
    };
}

export function SubscriptionListenerCommentToJSON(value?: SubscriptionListenerComment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'listenerUrl': value.listenerUrl,
        
        'eventType': SubscriptionEventTypeCommentToJSON(value.eventType),
    };
}


