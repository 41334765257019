/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Credits,
    CreditsFromJSON,
    CreditsToJSON,
    Onboarding,
    OnboardingFromJSON,
    OnboardingToJSON,
} from './';

/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * 
     * @type {Credits}
     * @memberof Users
     */
    credits?: Credits;
    /**
     * 
     * @type {Onboarding}
     * @memberof Users
     */
    onboarding: Onboarding;
}

export function UsersFromJSON(json: any): Users {
    return {
        
        'credits': !exists(json, 'credits') ? undefined : CreditsFromJSON(json['credits']),
        
        'onboarding': OnboardingFromJSON(json['onboarding']),
    };
}

export function UsersToJSON(value?: Users): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'credits': CreditsToJSON(value.credits),
        
        'onboarding': OnboardingToJSON(value.onboarding),
    };
}


