/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AnonymousSignup,
    AnonymousSignupFromJSON,
    AnonymousSignupToJSON,
    EmailSignup,
    EmailSignupFromJSON,
    EmailSignupToJSON,
    NotificationMethodsOauth,
    NotificationMethodsOauthFromJSON,
    NotificationMethodsOauthToJSON,
    SsoSignup,
    SsoSignupFromJSON,
    SsoSignupToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotificationMethods
 */
export interface NotificationMethods {
    /**
     * 
     * @type {AnonymousSignup}
     * @memberof NotificationMethods
     */
    anonymous?: AnonymousSignup;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationMethods
     */
    browserPush: boolean;
    /**
     * 
     * @type {EmailSignup}
     * @memberof NotificationMethods
     */
    email?: EmailSignup;
    /**
     * 
     * @type {SsoSignup}
     * @memberof NotificationMethods
     */
    sso?: SsoSignup;
    /**
     * 
     * @type {Array<NotificationMethodsOauth>}
     * @memberof NotificationMethods
     */
    oauth: Array<NotificationMethodsOauth>;
}

export function NotificationMethodsFromJSON(json: any): NotificationMethods {
    return {
        
        'anonymous': !exists(json, 'anonymous') ? undefined : AnonymousSignupFromJSON(json['anonymous']),
        'browserPush': json['browserPush'],
        
        'email': !exists(json, 'email') ? undefined : EmailSignupFromJSON(json['email']),
        
        'sso': !exists(json, 'sso') ? undefined : SsoSignupFromJSON(json['sso']),
        
        'oauth': (json['oauth'] as Array<any>).map(NotificationMethodsOauthFromJSON),
        
    };
}

export function NotificationMethodsToJSON(value?: NotificationMethods): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'anonymous': AnonymousSignupToJSON(value.anonymous),
        'browserPush': value.browserPush,
        
        'email': EmailSignupToJSON(value.email),
        
        'sso': SsoSignupToJSON(value.sso),
        
        'oauth': (value.oauth as Array<any>).map(NotificationMethodsOauthToJSON),
        
    };
}


