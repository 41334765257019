/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConfigAndBindAllResultByProjectId,
    ConfigAndBindAllResultByProjectIdFromJSON,
    ConfigAndBindAllResultByProjectIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigAndBindAllResult
 */
export interface ConfigAndBindAllResult {
    /**
     * 
     * @type {{ [key: string]: ConfigAndBindAllResultByProjectId; }}
     * @memberof ConfigAndBindAllResult
     */
    byProjectId: { [key: string]: ConfigAndBindAllResultByProjectId; };
}

export function ConfigAndBindAllResultFromJSON(json: any): ConfigAndBindAllResult {
    return {
        
        'byProjectId': Object.fromEntries(Object.entries(json['byProjectId']).map(([k, v]) => [k, ConfigAndBindAllResultByProjectIdFromJSON(v)])),
        
    };
}

export function ConfigAndBindAllResultToJSON(value?: ConfigAndBindAllResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'byProjectId': Object.fromEntries(Object.entries(value.byProjectId).map(([k, v]) => [k, ConfigAndBindAllResultByProjectIdToJSON(v)])),
        
    };
}


