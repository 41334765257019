/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    ideaId: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    commentId: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    parentCommentId?: string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    childCommentCount: number;
    /**
     * Author of the comment. If null, comment is deleted
     * @type {string}
     * @memberof Comment
     */
    authorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    authorName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Comment
     */
    authorIsMod?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    created: Date;
    /**
     * If set, comment was last edited at this time. If deleted, this is deleted time.
     * @type {Date}
     * @memberof Comment
     */
    edited?: Date;
    /**
     * If set, comment represents a merged postid
     * @type {string}
     * @memberof Comment
     */
    mergedPostId?: string;
    /**
     * Shows the title of the merged post, whereas content represent description of the post
     * @type {string}
     * @memberof Comment
     */
    mergedPostTitle?: string;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    mergedTime?: Date;
    /**
     * Comment content. If null, comment is deleted
     * @type {string}
     * @memberof Comment
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    voteValue: number;
}

export function CommentFromJSON(json: any): Comment {
    return {
        'ideaId': json['ideaId'],
        'commentId': json['commentId'],
        'parentCommentId': !exists(json, 'parentCommentId') ? undefined : json['parentCommentId'],
        'childCommentCount': json['childCommentCount'],
        'authorUserId': !exists(json, 'authorUserId') ? undefined : json['authorUserId'],
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'authorIsMod': !exists(json, 'authorIsMod') ? undefined : json['authorIsMod'],
        'created': new Date(json['created']),
        'edited': !exists(json, 'edited') ? undefined : new Date(json['edited']),
        'mergedPostId': !exists(json, 'mergedPostId') ? undefined : json['mergedPostId'],
        'mergedPostTitle': !exists(json, 'mergedPostTitle') ? undefined : json['mergedPostTitle'],
        'mergedTime': !exists(json, 'mergedTime') ? undefined : new Date(json['mergedTime']),
        'content': !exists(json, 'content') ? undefined : json['content'],
        'voteValue': json['voteValue'],
    };
}

export function CommentToJSON(value?: Comment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ideaId': value.ideaId,
        'commentId': value.commentId,
        'parentCommentId': value.parentCommentId,
        'childCommentCount': value.childCommentCount,
        'authorUserId': value.authorUserId,
        'authorName': value.authorName,
        'authorIsMod': value.authorIsMod,
        'created': value.created.toISOString(),
        'edited': value.edited === undefined ? undefined : value.edited.toISOString(),
        'mergedPostId': value.mergedPostId,
        'mergedPostTitle': value.mergedPostTitle,
        'mergedTime': value.mergedTime === undefined ? undefined : value.mergedTime.toISOString(),
        'content': value.content,
        'voteValue': value.voteValue,
    };
}


