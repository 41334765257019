/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Hits
 */
export interface Hits {
    /**
     * 
     * @type {number}
     * @memberof Hits
     */
    value: number;
    /**
     * 
     * @type {boolean}
     * @memberof Hits
     */
    isGte?: boolean;
}

export function HitsFromJSON(json: any): Hits {
    return {
        'value': json['value'],
        'isGte': !exists(json, 'isGte') ? undefined : json['isGte'],
    };
}

export function HitsToJSON(value?: Hits): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'value': value.value,
        'isGte': value.isGte,
    };
}


