/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Tag,
    TagFromJSON,
    TagToJSON,
    TagGroup,
    TagGroupFromJSON,
    TagGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface Tagging
 */
export interface Tagging {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Tagging
     */
    tags: Array<Tag>;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof Tagging
     */
    tagGroups: Array<TagGroup>;
}

export function TaggingFromJSON(json: any): Tagging {
    return {
        
        'tags': (json['tags'] as Array<any>).map(TagFromJSON),
        
        
        'tagGroups': (json['tagGroups'] as Array<any>).map(TagGroupFromJSON),
        
    };
}

export function TaggingToJSON(value?: Tagging): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'tags': (value.tags as Array<any>).map(TagToJSON),
        
        
        'tagGroups': (value.tagGroups as Array<any>).map(TagGroupToJSON),
        
    };
}


