/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Account,
    AccountFromJSON,
    AccountToJSON,
    ProjectAdminAllOf,
    ProjectAdminAllOfFromJSON,
    ProjectAdminAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectAdmin
 */
export interface ProjectAdmin {
    /**
     * 
     * @type {string}
     * @memberof ProjectAdmin
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdmin
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdmin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdmin
     */
    role: ProjectAdminRoleEnum;
}

export function ProjectAdminFromJSON(json: any): ProjectAdmin {
    return {
        'accountId': json['accountId'],
        'name': json['name'],
        'email': json['email'],
        'role': json['role'],
    };
}

export function ProjectAdminToJSON(value?: ProjectAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'accountId': value.accountId,
        'name': value.name,
        'email': value.email,
        'role': value.role,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProjectAdminRoleEnum {
    Owner = 'Owner',
    Admin = 'Admin'
}


