/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountBillingPayment,
    AccountBillingPaymentFromJSON,
    AccountBillingPaymentToJSON,
    AccountBillingPaymentActionRequired,
    AccountBillingPaymentActionRequiredFromJSON,
    AccountBillingPaymentActionRequiredToJSON,
    Invoices,
    InvoicesFromJSON,
    InvoicesToJSON,
    Plan,
    PlanFromJSON,
    PlanToJSON,
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountBilling
 */
export interface AccountBilling {
    /**
     * 
     * @type {Plan}
     * @memberof AccountBilling
     */
    plan: Plan;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AccountBilling
     */
    subscriptionStatus: SubscriptionStatus;
    /**
     * 
     * @type {AccountBillingPayment}
     * @memberof AccountBilling
     */
    payment?: AccountBillingPayment;
    /**
     * 
     * @type {Date}
     * @memberof AccountBilling
     */
    billingPeriodEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof AccountBilling
     */
    trackedUsers?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountBilling
     */
    postCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountBilling
     */
    teammateCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountBilling
     */
    teammateMax?: number;
    /**
     * 
     * @type {Array<Plan>}
     * @memberof AccountBilling
     */
    availablePlans: Array<Plan>;
    /**
     * 
     * @type {Invoices}
     * @memberof AccountBilling
     */
    invoices: Invoices;
    /**
     * 
     * @type {number}
     * @memberof AccountBilling
     */
    accountReceivable: number;
    /**
     * 
     * @type {number}
     * @memberof AccountBilling
     */
    accountPayable: number;
    /**
     * 
     * @type {Plan}
     * @memberof AccountBilling
     */
    endOfTermChangeToPlan?: Plan;
    /**
     * 
     * @type {AccountBillingPaymentActionRequired}
     * @memberof AccountBilling
     */
    paymentActionRequired?: AccountBillingPaymentActionRequired;
    /**
     * 
     * @type {string}
     * @memberof AccountBilling
     */
    purchasedLicenseKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBilling
     */
    appliedLicenseKey?: string;
}

export function AccountBillingFromJSON(json: any): AccountBilling {
    return {
        
        'plan': PlanFromJSON(json['plan']),
        
        'subscriptionStatus': SubscriptionStatusFromJSON(json['subscriptionStatus']),
        
        'payment': !exists(json, 'payment') ? undefined : AccountBillingPaymentFromJSON(json['payment']),
        'billingPeriodEnd': !exists(json, 'billingPeriodEnd') ? undefined : new Date(json['billingPeriodEnd']),
        'trackedUsers': !exists(json, 'trackedUsers') ? undefined : json['trackedUsers'],
        'postCount': !exists(json, 'postCount') ? undefined : json['postCount'],
        'teammateCount': !exists(json, 'teammateCount') ? undefined : json['teammateCount'],
        'teammateMax': !exists(json, 'teammateMax') ? undefined : json['teammateMax'],
        
        'availablePlans': (json['availablePlans'] as Array<any>).map(PlanFromJSON),
        
        
        'invoices': InvoicesFromJSON(json['invoices']),
        'accountReceivable': json['accountReceivable'],
        'accountPayable': json['accountPayable'],
        
        'endOfTermChangeToPlan': !exists(json, 'endOfTermChangeToPlan') ? undefined : PlanFromJSON(json['endOfTermChangeToPlan']),
        
        'paymentActionRequired': !exists(json, 'paymentActionRequired') ? undefined : AccountBillingPaymentActionRequiredFromJSON(json['paymentActionRequired']),
        'purchasedLicenseKey': !exists(json, 'purchasedLicenseKey') ? undefined : json['purchasedLicenseKey'],
        'appliedLicenseKey': !exists(json, 'appliedLicenseKey') ? undefined : json['appliedLicenseKey'],
    };
}

export function AccountBillingToJSON(value?: AccountBilling): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'plan': PlanToJSON(value.plan),
        
        'subscriptionStatus': SubscriptionStatusToJSON(value.subscriptionStatus),
        
        'payment': AccountBillingPaymentToJSON(value.payment),
        'billingPeriodEnd': value.billingPeriodEnd === undefined ? undefined : value.billingPeriodEnd.toISOString(),
        'trackedUsers': value.trackedUsers,
        'postCount': value.postCount,
        'teammateCount': value.teammateCount,
        'teammateMax': value.teammateMax,
        
        'availablePlans': (value.availablePlans as Array<any>).map(PlanToJSON),
        
        
        'invoices': InvoicesToJSON(value.invoices),
        'accountReceivable': value.accountReceivable,
        'accountPayable': value.accountPayable,
        
        'endOfTermChangeToPlan': PlanToJSON(value.endOfTermChangeToPlan),
        
        'paymentActionRequired': AccountBillingPaymentActionRequiredToJSON(value.paymentActionRequired),
        'purchasedLicenseKey': value.purchasedLicenseKey,
        'appliedLicenseKey': value.appliedLicenseKey,
    };
}


