/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    LandingLink,
    LandingLinkFromJSON,
    LandingLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface LayoutLanding
 */
export interface LayoutLanding {
    /**
     * 
     * @type {Array<LandingLink>}
     * @memberof LayoutLanding
     */
    links: Array<LandingLink>;
}

export function LayoutLandingFromJSON(json: any): LayoutLanding {
    return {
        
        'links': (json['links'] as Array<any>).map(LandingLinkFromJSON),
        
    };
}

export function LayoutLandingToJSON(value?: LayoutLanding): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'links': (value.links as Array<any>).map(LandingLinkToJSON),
        
    };
}


