/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CreditIncome
 */
export interface CreditIncome {
    /**
     * 
     * @type {string}
     * @memberof CreditIncome
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof CreditIncome
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditIncome
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditIncome
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof CreditIncome
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreditIncome
     */
    summary?: string;
}

export function CreditIncomeFromJSON(json: any): CreditIncome {
    return {
        'guid': json['guid'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'transactionId': json['transactionId'],
        'amount': json['amount'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function CreditIncomeToJSON(value?: CreditIncome): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'guid': value.guid,
        'email': value.email,
        'name': value.name,
        'transactionId': value.transactionId,
        'amount': value.amount,
        'summary': value.summary,
    };
}


