/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CommentVoteGetOwnResponse,
    CommentVoteGetOwnResponseFromJSON,
    CommentVoteGetOwnResponseToJSON,
    CommentVoteUpdate,
    CommentVoteUpdateFromJSON,
    CommentVoteUpdateToJSON,
    CommentVoteUpdateResponse,
    CommentVoteUpdateResponseFromJSON,
    CommentVoteUpdateResponseToJSON,
    ErrorResponse2,
    ErrorResponse2FromJSON,
    ErrorResponse2ToJSON,
    IdeaVoteGetOwnResponse,
    IdeaVoteGetOwnResponseFromJSON,
    IdeaVoteGetOwnResponseToJSON,
    IdeaVoteUpdate,
    IdeaVoteUpdateFromJSON,
    IdeaVoteUpdateToJSON,
    IdeaVoteUpdateResponse,
    IdeaVoteUpdateResponseFromJSON,
    IdeaVoteUpdateResponseToJSON,
    UserMe,
    UserMeFromJSON,
    UserMeToJSON,
} from '../models';

export interface CategorySubscribeRequest {
    projectId: string;
    categoryId: string;
    subscribe: boolean;
}

export interface CommentVoteGetOwnRequest {
    projectId: string;
    commentIds: Array<string>;
    myOwnCommentIds: Array<string>;
}

export interface CommentVoteUpdateRequest {
    projectId: string;
    ideaId: string;
    commentId: string;
    commentVoteUpdate: CommentVoteUpdate;
}

export interface IdeaVoteGetOwnRequest {
    projectId: string;
    ideaIds: Array<string>;
    myOwnIdeaIds: Array<string>;
}

export interface IdeaVoteUpdateRequest {
    projectId: string;
    ideaId: string;
    ideaVoteUpdate: IdeaVoteUpdate;
}

/**
 * no description
 */
export interface VoteApiInterface {

    /**
     */
    categorySubscribe(requestParameters: CategorySubscribeRequest, headerExtra?:{[key:string]:string}): Promise<UserMe>;

    /**
     */
    commentVoteGetOwn(requestParameters: CommentVoteGetOwnRequest, headerExtra?:{[key:string]:string}): Promise<CommentVoteGetOwnResponse>;

    /**
     */
    commentVoteUpdate(requestParameters: CommentVoteUpdateRequest, headerExtra?:{[key:string]:string}): Promise<CommentVoteUpdateResponse>;

    /**
     */
    ideaVoteGetOwn(requestParameters: IdeaVoteGetOwnRequest, headerExtra?:{[key:string]:string}): Promise<IdeaVoteGetOwnResponse>;

    /**
     */
    ideaVoteUpdate(requestParameters: IdeaVoteUpdateRequest, headerExtra?:{[key:string]:string}): Promise<IdeaVoteUpdateResponse>;
}

/**
 * no description
 */
export class VoteApi extends runtime.BaseAPI implements VoteApiInterface {

    /**
     */
    async categorySubscribeRaw(requestParameters: CategorySubscribeRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserMe>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling categorySubscribe.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling categorySubscribe.');
        }

        if (requestParameters.subscribe === null || requestParameters.subscribe === undefined) {
            throw new runtime.RequiredError('subscribe','Required parameter requestParameters.subscribe was null or undefined when calling categorySubscribe.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.subscribe !== undefined) {
            queryParameters['subscribe'] = requestParameters.subscribe;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/subscribe/category/{categoryId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMeFromJSON(jsonValue));
    }

    /**
     */
    async categorySubscribe(requestParameters: CategorySubscribeRequest, headerExtra?:{[key:string]:string}): Promise<UserMe> {
        const response = await this.categorySubscribeRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentVoteGetOwnRaw(requestParameters: CommentVoteGetOwnRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CommentVoteGetOwnResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentVoteGetOwn.');
        }

        if (requestParameters.commentIds === null || requestParameters.commentIds === undefined) {
            throw new runtime.RequiredError('commentIds','Required parameter requestParameters.commentIds was null or undefined when calling commentVoteGetOwn.');
        }

        if (requestParameters.myOwnCommentIds === null || requestParameters.myOwnCommentIds === undefined) {
            throw new runtime.RequiredError('myOwnCommentIds','Required parameter requestParameters.myOwnCommentIds was null or undefined when calling commentVoteGetOwn.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.commentIds) {
            queryParameters['commentIds'] = requestParameters.commentIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.myOwnCommentIds) {
            queryParameters['myOwnCommentIds'] = requestParameters.myOwnCommentIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/vote/comments`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentVoteGetOwnResponseFromJSON(jsonValue));
    }

    /**
     */
    async commentVoteGetOwn(requestParameters: CommentVoteGetOwnRequest, headerExtra?:{[key:string]:string}): Promise<CommentVoteGetOwnResponse> {
        const response = await this.commentVoteGetOwnRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentVoteUpdateRaw(requestParameters: CommentVoteUpdateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CommentVoteUpdateResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentVoteUpdate.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling commentVoteUpdate.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling commentVoteUpdate.');
        }

        if (requestParameters.commentVoteUpdate === null || requestParameters.commentVoteUpdate === undefined) {
            throw new runtime.RequiredError('commentVoteUpdate','Required parameter requestParameters.commentVoteUpdate was null or undefined when calling commentVoteUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}/vote/comment/{commentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CommentVoteUpdateToJSON(requestParameters.commentVoteUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentVoteUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async commentVoteUpdate(requestParameters: CommentVoteUpdateRequest, headerExtra?:{[key:string]:string}): Promise<CommentVoteUpdateResponse> {
        const response = await this.commentVoteUpdateRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaVoteGetOwnRaw(requestParameters: IdeaVoteGetOwnRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaVoteGetOwnResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaVoteGetOwn.');
        }

        if (requestParameters.ideaIds === null || requestParameters.ideaIds === undefined) {
            throw new runtime.RequiredError('ideaIds','Required parameter requestParameters.ideaIds was null or undefined when calling ideaVoteGetOwn.');
        }

        if (requestParameters.myOwnIdeaIds === null || requestParameters.myOwnIdeaIds === undefined) {
            throw new runtime.RequiredError('myOwnIdeaIds','Required parameter requestParameters.myOwnIdeaIds was null or undefined when calling ideaVoteGetOwn.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ideaIds) {
            queryParameters['ideaIds'] = requestParameters.ideaIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.myOwnIdeaIds) {
            queryParameters['myOwnIdeaIds'] = requestParameters.myOwnIdeaIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/vote/ideas`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaVoteGetOwnResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaVoteGetOwn(requestParameters: IdeaVoteGetOwnRequest, headerExtra?:{[key:string]:string}): Promise<IdeaVoteGetOwnResponse> {
        const response = await this.ideaVoteGetOwnRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaVoteUpdateRaw(requestParameters: IdeaVoteUpdateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaVoteUpdateResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaVoteUpdate.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaVoteUpdate.');
        }

        if (requestParameters.ideaVoteUpdate === null || requestParameters.ideaVoteUpdate === undefined) {
            throw new runtime.RequiredError('ideaVoteUpdate','Required parameter requestParameters.ideaVoteUpdate was null or undefined when calling ideaVoteUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/vote/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaVoteUpdateToJSON(requestParameters.ideaVoteUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaVoteUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaVoteUpdate(requestParameters: IdeaVoteUpdateRequest, headerExtra?:{[key:string]:string}): Promise<IdeaVoteUpdateResponse> {
        const response = await this.ideaVoteUpdateRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
