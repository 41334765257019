/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface PageExplorerAllOfAllowCreate
 */
export interface PageExplorerAllOfAllowCreate {
    /**
     * 
     * @type {string}
     * @memberof PageExplorerAllOfAllowCreate
     */
    actionTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PageExplorerAllOfAllowCreate
     */
    actionTitleLong?: string;
}

export function PageExplorerAllOfAllowCreateFromJSON(json: any): PageExplorerAllOfAllowCreate {
    return {
        'actionTitle': !exists(json, 'actionTitle') ? undefined : json['actionTitle'],
        'actionTitleLong': !exists(json, 'actionTitleLong') ? undefined : json['actionTitleLong'],
    };
}

export function PageExplorerAllOfAllowCreateToJSON(value?: PageExplorerAllOfAllowCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'actionTitle': value.actionTitle,
        'actionTitleLong': value.actionTitleLong,
    };
}


