/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Idea
 */
export interface Idea {
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    ideaId: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    authorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    authorName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    authorIsMod?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Idea
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    response?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    responseAuthorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    responseAuthorName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Idea
     */
    responseEdited?: Date;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    statusId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Idea
     */
    tagIds: Array<string>;
    /**
     * Total comment count
     * @type {number}
     * @memberof Idea
     */
    commentCount: number;
    /**
     * Count of immediate child comments only
     * @type {number}
     * @memberof Idea
     */
    childCommentCount: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    funded?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    fundGoal?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    fundersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    voteValue?: number;
    /**
     * Combined value of all expressions. Not the expression count.
     * @type {number}
     * @memberof Idea
     */
    expressionsValue?: number;
    /**
     * Mapping expression display to count
     * @type {{ [key: string]: number; }}
     * @memberof Idea
     */
    expressions?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof Idea
     */
    linkedToPostIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Idea
     */
    linkedFromPostIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    mergedToPostId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Idea
     */
    mergedToPostTime?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Idea
     */
    mergedPostIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    linkedGitHubUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    coverImg?: string;
}

export function IdeaFromJSON(json: any): Idea {
    return {
        'ideaId': json['ideaId'],
        'authorUserId': json['authorUserId'],
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'authorIsMod': !exists(json, 'authorIsMod') ? undefined : json['authorIsMod'],
        'created': new Date(json['created']),
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'responseAuthorUserId': !exists(json, 'responseAuthorUserId') ? undefined : json['responseAuthorUserId'],
        'responseAuthorName': !exists(json, 'responseAuthorName') ? undefined : json['responseAuthorName'],
        'responseEdited': !exists(json, 'responseEdited') ? undefined : new Date(json['responseEdited']),
        'categoryId': json['categoryId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'tagIds': json['tagIds'],
        'commentCount': json['commentCount'],
        'childCommentCount': json['childCommentCount'],
        'funded': !exists(json, 'funded') ? undefined : json['funded'],
        'fundGoal': !exists(json, 'fundGoal') ? undefined : json['fundGoal'],
        'fundersCount': !exists(json, 'fundersCount') ? undefined : json['fundersCount'],
        'voteValue': !exists(json, 'voteValue') ? undefined : json['voteValue'],
        'expressionsValue': !exists(json, 'expressionsValue') ? undefined : json['expressionsValue'],
        'expressions': !exists(json, 'expressions') ? undefined : json['expressions'],
        'linkedToPostIds': !exists(json, 'linkedToPostIds') ? undefined : json['linkedToPostIds'],
        'linkedFromPostIds': !exists(json, 'linkedFromPostIds') ? undefined : json['linkedFromPostIds'],
        'mergedToPostId': !exists(json, 'mergedToPostId') ? undefined : json['mergedToPostId'],
        'mergedToPostTime': !exists(json, 'mergedToPostTime') ? undefined : new Date(json['mergedToPostTime']),
        'mergedPostIds': !exists(json, 'mergedPostIds') ? undefined : json['mergedPostIds'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'linkedGitHubUrl': !exists(json, 'linkedGitHubUrl') ? undefined : json['linkedGitHubUrl'],
        'coverImg': !exists(json, 'coverImg') ? undefined : json['coverImg'],
    };
}

export function IdeaToJSON(value?: Idea): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ideaId': value.ideaId,
        'authorUserId': value.authorUserId,
        'authorName': value.authorName,
        'authorIsMod': value.authorIsMod,
        'created': value.created.toISOString(),
        'title': value.title,
        'description': value.description,
        'response': value.response,
        'responseAuthorUserId': value.responseAuthorUserId,
        'responseAuthorName': value.responseAuthorName,
        'responseEdited': value.responseEdited === undefined ? undefined : value.responseEdited.toISOString(),
        'categoryId': value.categoryId,
        'statusId': value.statusId,
        'tagIds': value.tagIds,
        'commentCount': value.commentCount,
        'childCommentCount': value.childCommentCount,
        'funded': value.funded,
        'fundGoal': value.fundGoal,
        'fundersCount': value.fundersCount,
        'voteValue': value.voteValue,
        'expressionsValue': value.expressionsValue,
        'expressions': value.expressions,
        'linkedToPostIds': value.linkedToPostIds,
        'linkedFromPostIds': value.linkedFromPostIds,
        'mergedToPostId': value.mergedToPostId,
        'mergedToPostTime': value.mergedToPostTime === undefined ? undefined : value.mergedToPostTime.toISOString(),
        'mergedPostIds': value.mergedPostIds,
        'order': value.order,
        'linkedGitHubUrl': value.linkedGitHubUrl,
        'coverImg': value.coverImg,
    };
}


