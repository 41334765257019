/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserLogin
 */
export interface UserLogin {
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    token?: string;
}

export function UserLoginFromJSON(json: any): UserLogin {
    return {
        'email': json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function UserLoginToJSON(value?: UserLogin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'password': value.password,
        'token': value.token,
    };
}


