/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Content,
    ContentFromJSON,
    ContentToJSON,
    CookieConsent,
    CookieConsentFromJSON,
    CookieConsentToJSON,
    Integrations,
    IntegrationsFromJSON,
    IntegrationsToJSON,
    LangWhitelist,
    LangWhitelistFromJSON,
    LangWhitelistToJSON,
    Layout,
    LayoutFromJSON,
    LayoutToJSON,
    Style,
    StyleFromJSON,
    StyleToJSON,
    Users,
    UsersFromJSON,
    UsersToJSON,
} from './';

/**
 * Config properties visible both to client app and admins.
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    schemaVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    name: string;
    /**
     * Location of the logo to display.
     * @type {string}
     * @memberof Config
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    langDefault?: string;
    /**
     * 
     * @type {LangWhitelist}
     * @memberof Config
     */
    langWhitelist?: LangWhitelist;
    /**
     * 
     * @type {boolean}
     * @memberof Config
     */
    noIndex?: boolean;
    /**
     * 
     * @type {CookieConsent}
     * @memberof Config
     */
    cookieConsent: CookieConsent;
    /**
     * 
     * @type {Layout}
     * @memberof Config
     */
    layout: Layout;
    /**
     * 
     * @type {Content}
     * @memberof Config
     */
    content: Content;
    /**
     * 
     * @type {Style}
     * @memberof Config
     */
    style: Style;
    /**
     * 
     * @type {Users}
     * @memberof Config
     */
    users: Users;
    /**
     * 
     * @type {Integrations}
     * @memberof Config
     */
    integrations: Integrations;
}

export function ConfigFromJSON(json: any): Config {
    return {
        'schemaVersion': !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
        'projectId': json['projectId'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'name': json['name'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'slug': json['slug'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'langDefault': !exists(json, 'langDefault') ? undefined : json['langDefault'],
        
        'langWhitelist': !exists(json, 'langWhitelist') ? undefined : LangWhitelistFromJSON(json['langWhitelist']),
        'noIndex': !exists(json, 'noIndex') ? undefined : json['noIndex'],
        
        'cookieConsent': CookieConsentFromJSON(json['cookieConsent']),
        
        'layout': LayoutFromJSON(json['layout']),
        
        'content': ContentFromJSON(json['content']),
        
        'style': StyleFromJSON(json['style']),
        
        'users': UsersFromJSON(json['users']),
        
        'integrations': IntegrationsFromJSON(json['integrations']),
    };
}

export function ConfigToJSON(value?: Config): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'schemaVersion': value.schemaVersion,
        'projectId': value.projectId,
        'website': value.website,
        'name': value.name,
        'logoUrl': value.logoUrl,
        'slug': value.slug,
        'domain': value.domain,
        'langDefault': value.langDefault,
        
        'langWhitelist': LangWhitelistToJSON(value.langWhitelist),
        'noIndex': value.noIndex,
        
        'cookieConsent': CookieConsentToJSON(value.cookieConsent),
        
        'layout': LayoutToJSON(value.layout),
        
        'content': ContentToJSON(value.content),
        
        'style': StyleToJSON(value.style),
        
        'users': UsersToJSON(value.users),
        
        'integrations': IntegrationsToJSON(value.integrations),
    };
}


