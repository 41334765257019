/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountUpdateAdminPaymentToken
 */
export interface AccountUpdateAdminPaymentToken {
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdminPaymentToken
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdminPaymentToken
     */
    token: string;
}

export function AccountUpdateAdminPaymentTokenFromJSON(json: any): AccountUpdateAdminPaymentToken {
    return {
        'type': json['type'],
        'token': json['token'],
    };
}

export function AccountUpdateAdminPaymentTokenToJSON(value?: AccountUpdateAdminPaymentToken): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'token': value.token,
    };
}


