/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CookieYes
 */
export interface CookieYes {
    /**
     * 
     * @type {string}
     * @memberof CookieYes
     */
    clientId: string;
}

export function CookieYesFromJSON(json: any): CookieYes {
    return {
        'clientId': json['clientId'],
    };
}

export function CookieYesToJSON(value?: CookieYes): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clientId': value.clientId,
    };
}


