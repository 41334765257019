/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagId: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    color?: string;
}

export function TagFromJSON(json: any): Tag {
    return {
        'tagId': json['tagId'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function TagToJSON(value?: Tag): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'tagId': value.tagId,
        'name': value.name,
        'color': value.color,
    };
}


