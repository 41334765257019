/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConfigAdmin,
    ConfigAdminFromJSON,
    ConfigAdminToJSON,
    ConfigAndBindAllResult,
    ConfigAndBindAllResultFromJSON,
    ConfigAndBindAllResultToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ImportResponse,
    ImportResponseFromJSON,
    ImportResponseToJSON,
    NewProjectResult,
    NewProjectResultFromJSON,
    NewProjectResultToJSON,
    ProjectAdminsInviteResult,
    ProjectAdminsInviteResultFromJSON,
    ProjectAdminsInviteResultToJSON,
    ProjectAdminsListResult,
    ProjectAdminsListResultFromJSON,
    ProjectAdminsListResultToJSON,
    VersionedConfigAdmin,
    VersionedConfigAdminFromJSON,
    VersionedConfigAdminToJSON,
} from '../models';

export interface ConfigGetAdminRequest {
    projectId: string;
}

export interface ConfigSetAdminRequest {
    projectId: string;
    configAdmin: ConfigAdmin;
    versionLast?: string;
}

export interface ProjectAdminsInviteAdminRequest {
    projectId: string;
    email: string;
}

export interface ProjectAdminsListAdminRequest {
    projectId: string;
}

export interface ProjectAdminsRemoveAdminRequest {
    projectId: string;
    accountId?: string;
    invitationId?: string;
}

export interface ProjectCreateAdminRequest {
    configAdmin: ConfigAdmin;
}

export interface ProjectDeleteAdminRequest {
    projectId: string;
}

export interface ProjectExportAdminRequest {
    projectId: string;
    includePosts?: boolean;
    includeUsers?: boolean;
    includeComments?: boolean;
}

export interface ProjectImportPostAdminRequest {
    projectId: string;
    categoryId: string;
    authorUserId: string;
    indexTitle: number;
    body: Blob;
    firstRowIsHeader?: boolean;
    indexDescription?: number;
    indexStatusId?: number;
    indexStatusName?: number;
    indexTagIds?: number;
    indexTagNames?: number;
    indexVoteValue?: number;
    indexDateTime?: number;
    tzOffInMin?: number;
}

/**
 * no description
 */
export interface ProjectAdminApiInterface {

    /**
     */
    configGetAdmin(requestParameters: ConfigGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<VersionedConfigAdmin>;

    /**
     */
    configGetAllAndUserBindAllAdmin(headerExtra?:{[key:string]:string}): Promise<ConfigAndBindAllResult>;

    /**
     */
    configSetAdmin(requestParameters: ConfigSetAdminRequest, headerExtra?:{[key:string]:string}): Promise<VersionedConfigAdmin>;

    /**
     */
    projectAdminsInviteAdmin(requestParameters: ProjectAdminsInviteAdminRequest, headerExtra?:{[key:string]:string}): Promise<ProjectAdminsInviteResult>;

    /**
     */
    projectAdminsListAdmin(requestParameters: ProjectAdminsListAdminRequest, headerExtra?:{[key:string]:string}): Promise<ProjectAdminsListResult>;

    /**
     */
    projectAdminsRemoveAdmin(requestParameters: ProjectAdminsRemoveAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    projectCreateAdmin(requestParameters: ProjectCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<NewProjectResult>;

    /**
     */
    projectDeleteAdmin(requestParameters: ProjectDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    projectExportAdmin(requestParameters: ProjectExportAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.FileDownload>;

    /**
     */
    projectImportPostAdmin(requestParameters: ProjectImportPostAdminRequest, headerExtra?:{[key:string]:string}): Promise<ImportResponse>;
}

/**
 * no description
 */
export class ProjectAdminApi extends runtime.BaseAPI implements ProjectAdminApiInterface {

    /**
     */
    async configGetAdminRaw(requestParameters: ConfigGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<VersionedConfigAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling configGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/config`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionedConfigAdminFromJSON(jsonValue));
    }

    /**
     */
    async configGetAdmin(requestParameters: ConfigGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<VersionedConfigAdmin> {
        const response = await this.configGetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async configGetAllAndUserBindAllAdminRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConfigAndBindAllResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/config/admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigAndBindAllResultFromJSON(jsonValue));
    }

    /**
     */
    async configGetAllAndUserBindAllAdmin(headerExtra?:{[key:string]:string}): Promise<ConfigAndBindAllResult> {
        const response = await this.configGetAllAndUserBindAllAdminRaw(headerExtra);
        return await response.value();
    }

    /**
     */
    async configSetAdminRaw(requestParameters: ConfigSetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<VersionedConfigAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling configSetAdmin.');
        }

        if (requestParameters.configAdmin === null || requestParameters.configAdmin === undefined) {
            throw new runtime.RequiredError('configAdmin','Required parameter requestParameters.configAdmin was null or undefined when calling configSetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.versionLast !== undefined) {
            queryParameters['versionLast'] = requestParameters.versionLast;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/config`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigAdminToJSON(requestParameters.configAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionedConfigAdminFromJSON(jsonValue));
    }

    /**
     */
    async configSetAdmin(requestParameters: ConfigSetAdminRequest, headerExtra?:{[key:string]:string}): Promise<VersionedConfigAdmin> {
        const response = await this.configSetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async projectAdminsInviteAdminRaw(requestParameters: ProjectAdminsInviteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ProjectAdminsInviteResult>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectAdminsInviteAdmin.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling projectAdminsInviteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/admins`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectAdminsInviteResultFromJSON(jsonValue));
    }

    /**
     */
    async projectAdminsInviteAdmin(requestParameters: ProjectAdminsInviteAdminRequest, headerExtra?:{[key:string]:string}): Promise<ProjectAdminsInviteResult> {
        const response = await this.projectAdminsInviteAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async projectAdminsListAdminRaw(requestParameters: ProjectAdminsListAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ProjectAdminsListResult>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectAdminsListAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/admins`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectAdminsListResultFromJSON(jsonValue));
    }

    /**
     */
    async projectAdminsListAdmin(requestParameters: ProjectAdminsListAdminRequest, headerExtra?:{[key:string]:string}): Promise<ProjectAdminsListResult> {
        const response = await this.projectAdminsListAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async projectAdminsRemoveAdminRaw(requestParameters: ProjectAdminsRemoveAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectAdminsRemoveAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.accountId !== undefined) {
            queryParameters['accountId'] = requestParameters.accountId;
        }

        if (requestParameters.invitationId !== undefined) {
            queryParameters['invitationId'] = requestParameters.invitationId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/admins`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectAdminsRemoveAdmin(requestParameters: ProjectAdminsRemoveAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.projectAdminsRemoveAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async projectCreateAdminRaw(requestParameters: ProjectCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<NewProjectResult>> {
        if (requestParameters.configAdmin === null || requestParameters.configAdmin === undefined) {
            throw new runtime.RequiredError('configAdmin','Required parameter requestParameters.configAdmin was null or undefined when calling projectCreateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigAdminToJSON(requestParameters.configAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NewProjectResultFromJSON(jsonValue));
    }

    /**
     */
    async projectCreateAdmin(requestParameters: ProjectCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<NewProjectResult> {
        const response = await this.projectCreateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async projectDeleteAdminRaw(requestParameters: ProjectDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectDeleteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectDeleteAdmin(requestParameters: ProjectDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.projectDeleteAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async projectExportAdminRaw(requestParameters: ProjectExportAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<runtime.FileDownload>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectExportAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includePosts !== undefined) {
            queryParameters['includePosts'] = requestParameters.includePosts;
        }

        if (requestParameters.includeUsers !== undefined) {
            queryParameters['includeUsers'] = requestParameters.includeUsers;
        }

        if (requestParameters.includeComments !== undefined) {
            queryParameters['includeComments'] = requestParameters.includeComments;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/export`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.FileDownloadApiResponse(response);
    }

    /**
     */
    async projectExportAdmin(requestParameters: ProjectExportAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.FileDownload> {
        const response = await this.projectExportAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async projectImportPostAdminRaw(requestParameters: ProjectImportPostAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ImportResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectImportPostAdmin.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling projectImportPostAdmin.');
        }

        if (requestParameters.authorUserId === null || requestParameters.authorUserId === undefined) {
            throw new runtime.RequiredError('authorUserId','Required parameter requestParameters.authorUserId was null or undefined when calling projectImportPostAdmin.');
        }

        if (requestParameters.indexTitle === null || requestParameters.indexTitle === undefined) {
            throw new runtime.RequiredError('indexTitle','Required parameter requestParameters.indexTitle was null or undefined when calling projectImportPostAdmin.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling projectImportPostAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.firstRowIsHeader !== undefined) {
            queryParameters['firstRowIsHeader'] = requestParameters.firstRowIsHeader;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.authorUserId !== undefined) {
            queryParameters['authorUserId'] = requestParameters.authorUserId;
        }

        if (requestParameters.indexTitle !== undefined) {
            queryParameters['indexTitle'] = requestParameters.indexTitle;
        }

        if (requestParameters.indexDescription !== undefined) {
            queryParameters['indexDescription'] = requestParameters.indexDescription;
        }

        if (requestParameters.indexStatusId !== undefined) {
            queryParameters['indexStatusId'] = requestParameters.indexStatusId;
        }

        if (requestParameters.indexStatusName !== undefined) {
            queryParameters['indexStatusName'] = requestParameters.indexStatusName;
        }

        if (requestParameters.indexTagIds !== undefined) {
            queryParameters['indexTagIds'] = requestParameters.indexTagIds;
        }

        if (requestParameters.indexTagNames !== undefined) {
            queryParameters['indexTagNames'] = requestParameters.indexTagNames;
        }

        if (requestParameters.indexVoteValue !== undefined) {
            queryParameters['indexVoteValue'] = requestParameters.indexVoteValue;
        }

        if (requestParameters.indexDateTime !== undefined) {
            queryParameters['indexDateTime'] = requestParameters.indexDateTime;
        }

        if (requestParameters.tzOffInMin !== undefined) {
            queryParameters['tzOffInMin'] = requestParameters.tzOffInMin;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/csv';

        const response = await this.request({
            path: `/project/{projectId}/admin/import/post`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectImportPostAdmin(requestParameters: ProjectImportPostAdminRequest, headerExtra?:{[key:string]:string}): Promise<ImportResponse> {
        const response = await this.projectImportPostAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
