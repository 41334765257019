/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    SupportMessage,
    SupportMessageFromJSON,
    SupportMessageToJSON,
} from '../models';

export interface SupportMessageRequest {
    supportMessage: SupportMessage;
}

/**
 * no description
 */
export interface SupportApiInterface {

    /**
     */
    supportMessage(requestParameters: SupportMessageRequest, headerExtra?:{[key:string]:string}): Promise<void>;
}

/**
 * no description
 */
export class SupportApi extends runtime.BaseAPI implements SupportApiInterface {

    /**
     */
    async supportMessageRaw(requestParameters: SupportMessageRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.supportMessage === null || requestParameters.supportMessage === undefined) {
            throw new runtime.RequiredError('supportMessage','Required parameter requestParameters.supportMessage was null or undefined when calling supportMessage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/support/message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupportMessageToJSON(requestParameters.supportMessage),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supportMessage(requestParameters: SupportMessageRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.supportMessageRaw(requestParameters, headerExtra);
    }

}
