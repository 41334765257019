/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Plan,
    PlanFromJSON,
    PlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface ViewCouponResponse
 */
export interface ViewCouponResponse {
    /**
     * 
     * @type {Plan}
     * @memberof ViewCouponResponse
     */
    plan?: Plan;
    /**
     * 
     * @type {boolean}
     * @memberof ViewCouponResponse
     */
    redeemedByYou?: boolean;
}

export function ViewCouponResponseFromJSON(json: any): ViewCouponResponse {
    return {
        
        'plan': !exists(json, 'plan') ? undefined : PlanFromJSON(json['plan']),
        'redeemedByYou': !exists(json, 'redeemedByYou') ? undefined : json['redeemedByYou'],
    };
}

export function ViewCouponResponseToJSON(value?: ViewCouponResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'plan': PlanToJSON(value.plan),
        'redeemedByYou': value.redeemedByYou,
    };
}


