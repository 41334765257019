/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Comment,
    CommentFromJSON,
    CommentToJSON,
    CommentCreate,
    CommentCreateFromJSON,
    CommentCreateToJSON,
    CommentSearch,
    CommentSearchFromJSON,
    CommentSearchToJSON,
    CommentSearchResponse,
    CommentSearchResponseFromJSON,
    CommentSearchResponseToJSON,
    CommentUpdate,
    CommentUpdateFromJSON,
    CommentUpdateToJSON,
    CommentWithVote,
    CommentWithVoteFromJSON,
    CommentWithVoteToJSON,
    ErrorResponse2,
    ErrorResponse2FromJSON,
    ErrorResponse2ToJSON,
    IdeaCommentSearch,
    IdeaCommentSearchFromJSON,
    IdeaCommentSearchToJSON,
    IdeaCommentSearchResponse,
    IdeaCommentSearchResponseFromJSON,
    IdeaCommentSearchResponseToJSON,
} from '../models';

export interface CommentCreateRequest {
    projectId: string;
    ideaId: string;
    commentCreate: CommentCreate;
}

export interface CommentDeleteRequest {
    projectId: string;
    ideaId: string;
    commentId: string;
}

export interface CommentSearchRequest {
    projectId: string;
    commentSearch: CommentSearch;
    cursor?: string;
}

export interface CommentUpdateRequest {
    projectId: string;
    ideaId: string;
    commentId: string;
    commentUpdate: CommentUpdate;
}

export interface IdeaCommentSearchRequest {
    projectId: string;
    ideaId: string;
    ideaCommentSearch: IdeaCommentSearch;
}

/**
 * no description
 */
export interface CommentApiInterface {

    /**
     */
    commentCreate(requestParameters: CommentCreateRequest, headerExtra?:{[key:string]:string}): Promise<CommentWithVote>;

    /**
     */
    commentDelete(requestParameters: CommentDeleteRequest, headerExtra?:{[key:string]:string}): Promise<Comment>;

    /**
     */
    commentSearch(requestParameters: CommentSearchRequest, headerExtra?:{[key:string]:string}): Promise<CommentSearchResponse>;

    /**
     */
    commentUpdate(requestParameters: CommentUpdateRequest, headerExtra?:{[key:string]:string}): Promise<Comment>;

    /**
     */
    ideaCommentSearch(requestParameters: IdeaCommentSearchRequest, headerExtra?:{[key:string]:string}): Promise<IdeaCommentSearchResponse>;
}

/**
 * no description
 */
export class CommentApi extends runtime.BaseAPI implements CommentApiInterface {

    /**
     */
    async commentCreateRaw(requestParameters: CommentCreateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CommentWithVote>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentCreate.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling commentCreate.');
        }

        if (requestParameters.commentCreate === null || requestParameters.commentCreate === undefined) {
            throw new runtime.RequiredError('commentCreate','Required parameter requestParameters.commentCreate was null or undefined when calling commentCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}/comment`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentCreateToJSON(requestParameters.commentCreate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentWithVoteFromJSON(jsonValue));
    }

    /**
     */
    async commentCreate(requestParameters: CommentCreateRequest, headerExtra?:{[key:string]:string}): Promise<CommentWithVote> {
        const response = await this.commentCreateRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentDeleteRaw(requestParameters: CommentDeleteRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentDelete.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling commentDelete.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling commentDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}/comment/{commentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     */
    async commentDelete(requestParameters: CommentDeleteRequest, headerExtra?:{[key:string]:string}): Promise<Comment> {
        const response = await this.commentDeleteRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentSearchRaw(requestParameters: CommentSearchRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CommentSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentSearch.');
        }

        if (requestParameters.commentSearch === null || requestParameters.commentSearch === undefined) {
            throw new runtime.RequiredError('commentSearch','Required parameter requestParameters.commentSearch was null or undefined when calling commentSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/commentsearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentSearchToJSON(requestParameters.commentSearch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async commentSearch(requestParameters: CommentSearchRequest, headerExtra?:{[key:string]:string}): Promise<CommentSearchResponse> {
        const response = await this.commentSearchRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentUpdateRaw(requestParameters: CommentUpdateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentUpdate.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling commentUpdate.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling commentUpdate.');
        }

        if (requestParameters.commentUpdate === null || requestParameters.commentUpdate === undefined) {
            throw new runtime.RequiredError('commentUpdate','Required parameter requestParameters.commentUpdate was null or undefined when calling commentUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}/comment/{commentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CommentUpdateToJSON(requestParameters.commentUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     */
    async commentUpdate(requestParameters: CommentUpdateRequest, headerExtra?:{[key:string]:string}): Promise<Comment> {
        const response = await this.commentUpdateRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaCommentSearchRaw(requestParameters: IdeaCommentSearchRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaCommentSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaCommentSearch.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaCommentSearch.');
        }

        if (requestParameters.ideaCommentSearch === null || requestParameters.ideaCommentSearch === undefined) {
            throw new runtime.RequiredError('ideaCommentSearch','Required parameter requestParameters.ideaCommentSearch was null or undefined when calling ideaCommentSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}/commentsearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaCommentSearchToJSON(requestParameters.ideaCommentSearch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaCommentSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaCommentSearch(requestParameters: IdeaCommentSearchRequest, headerExtra?:{[key:string]:string}): Promise<IdeaCommentSearchResponse> {
        const response = await this.ideaCommentSearchRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
