/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaCreate,
    IdeaCreateFromJSON,
    IdeaCreateToJSON,
    IdeaCreateAdminAllOf,
    IdeaCreateAdminAllOfFromJSON,
    IdeaCreateAdminAllOfToJSON,
    NotifySubscribers,
    NotifySubscribersFromJSON,
    NotifySubscribersToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaCreateAdmin
 */
export interface IdeaCreateAdmin {
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    authorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    categoryId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaCreateAdmin
     */
    tagIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    response?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    statusId?: string;
    /**
     * 
     * @type {number}
     * @memberof IdeaCreateAdmin
     */
    fundGoal?: number;
    /**
     * 
     * @type {NotifySubscribers}
     * @memberof IdeaCreateAdmin
     */
    notifySubscribers?: NotifySubscribers;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaCreateAdmin
     */
    linkedFromPostIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IdeaCreateAdmin
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreateAdmin
     */
    coverImg?: string;
}

export function IdeaCreateAdminFromJSON(json: any): IdeaCreateAdmin {
    return {
        'authorUserId': json['authorUserId'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'categoryId': json['categoryId'],
        'tagIds': json['tagIds'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'fundGoal': !exists(json, 'fundGoal') ? undefined : json['fundGoal'],
        
        'notifySubscribers': !exists(json, 'notifySubscribers') ? undefined : NotifySubscribersFromJSON(json['notifySubscribers']),
        'linkedFromPostIds': !exists(json, 'linkedFromPostIds') ? undefined : json['linkedFromPostIds'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'coverImg': !exists(json, 'coverImg') ? undefined : json['coverImg'],
    };
}

export function IdeaCreateAdminToJSON(value?: IdeaCreateAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'authorUserId': value.authorUserId,
        'title': value.title,
        'description': value.description,
        'categoryId': value.categoryId,
        'tagIds': value.tagIds,
        'response': value.response,
        'statusId': value.statusId,
        'fundGoal': value.fundGoal,
        
        'notifySubscribers': NotifySubscribersToJSON(value.notifySubscribers),
        'linkedFromPostIds': value.linkedFromPostIds,
        'order': value.order,
        'coverImg': value.coverImg,
    };
}


