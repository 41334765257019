/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserBindOauthToken,
    UserBindOauthTokenFromJSON,
    UserBindOauthTokenToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserBind
 */
export interface UserBind {
    /**
     * 
     * @type {string}
     * @memberof UserBind
     */
    ssoToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBind
     */
    authToken?: string;
    /**
     * 
     * @type {UserBindOauthToken}
     * @memberof UserBind
     */
    oauthToken?: UserBindOauthToken;
    /**
     * 
     * @type {string}
     * @memberof UserBind
     */
    browserPushToken?: string;
}

export function UserBindFromJSON(json: any): UserBind {
    return {
        'ssoToken': !exists(json, 'ssoToken') ? undefined : json['ssoToken'],
        'authToken': !exists(json, 'authToken') ? undefined : json['authToken'],
        
        'oauthToken': !exists(json, 'oauthToken') ? undefined : UserBindOauthTokenFromJSON(json['oauthToken']),
        'browserPushToken': !exists(json, 'browserPushToken') ? undefined : json['browserPushToken'],
    };
}

export function UserBindToJSON(value?: UserBind): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ssoToken': value.ssoToken,
        'authToken': value.authToken,
        
        'oauthToken': UserBindOauthTokenToJSON(value.oauthToken),
        'browserPushToken': value.browserPushToken,
    };
}


