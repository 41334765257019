/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableRepo
 */
export interface AvailableRepo {
    /**
     * 
     * @type {number}
     * @memberof AvailableRepo
     */
    installationId: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableRepo
     */
    repositoryId: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableRepo
     */
    name: string;
}

export function AvailableRepoFromJSON(json: any): AvailableRepo {
    return {
        'installationId': json['installationId'],
        'repositoryId': json['repositoryId'],
        'name': json['name'],
    };
}

export function AvailableRepoToJSON(value?: AvailableRepo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'installationId': value.installationId,
        'repositoryId': value.repositoryId,
        'name': value.name,
    };
}


