/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CommentCreate
 */
export interface CommentCreate {
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    parentCommentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    mergedPostId?: string;
}

export function CommentCreateFromJSON(json: any): CommentCreate {
    return {
        'parentCommentId': !exists(json, 'parentCommentId') ? undefined : json['parentCommentId'],
        'content': json['content'],
        'mergedPostId': !exists(json, 'mergedPostId') ? undefined : json['mergedPostId'],
    };
}

export function CommentCreateToJSON(value?: CommentCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'parentCommentId': value.parentCommentId,
        'content': value.content,
        'mergedPostId': value.mergedPostId,
    };
}


