/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface GitHubStatusSync
 */
export interface GitHubStatusSync {
    /**
     * 
     * @type {Array<string>}
     * @memberof GitHubStatusSync
     */
    closedStatuses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GitHubStatusSync
     */
    closedStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof GitHubStatusSync
     */
    openStatus?: string;
}

export function GitHubStatusSyncFromJSON(json: any): GitHubStatusSync {
    return {
        'closedStatuses': !exists(json, 'closedStatuses') ? undefined : json['closedStatuses'],
        'closedStatus': !exists(json, 'closedStatus') ? undefined : json['closedStatus'],
        'openStatus': !exists(json, 'openStatus') ? undefined : json['openStatus'],
    };
}

export function GitHubStatusSyncToJSON(value?: GitHubStatusSync): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'closedStatuses': value.closedStatuses,
        'closedStatus': value.closedStatus,
        'openStatus': value.openStatus,
    };
}


