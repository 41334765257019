/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConfigAndUserBindSlugResult,
    ConfigAndUserBindSlugResultFromJSON,
    ConfigAndUserBindSlugResultToJSON,
    ConfigBindSlugResult,
    ConfigBindSlugResultFromJSON,
    ConfigBindSlugResultToJSON,
    ErrorResponse2,
    ErrorResponse2FromJSON,
    ErrorResponse2ToJSON,
    UserBind,
    UserBindFromJSON,
    UserBindToJSON,
    UserBindResponse,
    UserBindResponseFromJSON,
    UserBindResponseToJSON,
} from '../models';

export interface ConfigAndUserBindSlugRequest {
    slug: string;
    userBind: UserBind;
}

export interface ConfigBindSlugRequest {
    slug: string;
}

export interface UserBindSlugRequest {
    slug: string;
    userBind: UserBind;
}

/**
 * no description
 */
export interface ProjectApiInterface {

    /**
     */
    configAndUserBindSlug(requestParameters: ConfigAndUserBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<ConfigAndUserBindSlugResult>;

    /**
     */
    configBindSlug(requestParameters: ConfigBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<ConfigBindSlugResult>;

    /**
     */
    userBindSlug(requestParameters: UserBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<UserBindResponse>;
}

/**
 * no description
 */
export class ProjectApi extends runtime.BaseAPI implements ProjectApiInterface {

    /**
     */
    async configAndUserBindSlugRaw(requestParameters: ConfigAndUserBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConfigAndUserBindSlugResult>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling configAndUserBindSlug.');
        }

        if (requestParameters.userBind === null || requestParameters.userBind === undefined) {
            throw new runtime.RequiredError('userBind','Required parameter requestParameters.userBind was null or undefined when calling configAndUserBindSlug.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/slug/{slug}/bind/configAndUser`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserBindToJSON(requestParameters.userBind),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigAndUserBindSlugResultFromJSON(jsonValue));
    }

    /**
     */
    async configAndUserBindSlug(requestParameters: ConfigAndUserBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<ConfigAndUserBindSlugResult> {
        const response = await this.configAndUserBindSlugRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async configBindSlugRaw(requestParameters: ConfigBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConfigBindSlugResult>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling configBindSlug.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/slug/{slug}/bind/config`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigBindSlugResultFromJSON(jsonValue));
    }

    /**
     */
    async configBindSlug(requestParameters: ConfigBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<ConfigBindSlugResult> {
        const response = await this.configBindSlugRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userBindSlugRaw(requestParameters: UserBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserBindResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling userBindSlug.');
        }

        if (requestParameters.userBind === null || requestParameters.userBind === undefined) {
            throw new runtime.RequiredError('userBind','Required parameter requestParameters.userBind was null or undefined when calling userBindSlug.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/slug/{slug}/bind/user`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserBindToJSON(requestParameters.userBind),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBindResponseFromJSON(jsonValue));
    }

    /**
     */
    async userBindSlug(requestParameters: UserBindSlugRequest, headerExtra?:{[key:string]:string}): Promise<UserBindResponse> {
        const response = await this.userBindSlugRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
