/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    HistogramInterval,
    HistogramIntervalFromJSON,
    HistogramIntervalToJSON,
    HistogramSearchAdmin,
    HistogramSearchAdminFromJSON,
    HistogramSearchAdminToJSON,
    IdeaHistogramSearchAdminAllOf,
    IdeaHistogramSearchAdminAllOfFromJSON,
    IdeaHistogramSearchAdminAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaHistogramSearchAdmin
 */
export interface IdeaHistogramSearchAdmin {
    /**
     * 
     * @type {Date}
     * @memberof IdeaHistogramSearchAdmin
     */
    filterCreatedStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IdeaHistogramSearchAdmin
     */
    filterCreatedEnd?: Date;
    /**
     * 
     * @type {HistogramInterval}
     * @memberof IdeaHistogramSearchAdmin
     */
    interval?: HistogramInterval;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaHistogramSearchAdmin
     */
    filterCategoryIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaHistogramSearchAdmin
     */
    filterStatusIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaHistogramSearchAdmin
     */
    filterTagIds?: Array<string>;
}

export function IdeaHistogramSearchAdminFromJSON(json: any): IdeaHistogramSearchAdmin {
    return {
        'filterCreatedStart': !exists(json, 'filterCreatedStart') ? undefined : new Date(json['filterCreatedStart']),
        'filterCreatedEnd': !exists(json, 'filterCreatedEnd') ? undefined : new Date(json['filterCreatedEnd']),
        
        'interval': !exists(json, 'interval') ? undefined : HistogramIntervalFromJSON(json['interval']),
        'filterCategoryIds': !exists(json, 'filterCategoryIds') ? undefined : json['filterCategoryIds'],
        'filterStatusIds': !exists(json, 'filterStatusIds') ? undefined : json['filterStatusIds'],
        'filterTagIds': !exists(json, 'filterTagIds') ? undefined : json['filterTagIds'],
    };
}

export function IdeaHistogramSearchAdminToJSON(value?: IdeaHistogramSearchAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filterCreatedStart': value.filterCreatedStart === undefined ? undefined : value.filterCreatedStart.toISOString().substr(0,10),
        'filterCreatedEnd': value.filterCreatedEnd === undefined ? undefined : value.filterCreatedEnd.toISOString().substr(0,10),
        
        'interval': HistogramIntervalToJSON(value.interval),
        'filterCategoryIds': value.filterCategoryIds,
        'filterStatusIds': value.filterStatusIds,
        'filterTagIds': value.filterTagIds,
    };
}


