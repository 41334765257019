/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    notificationId: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    relatedIdeaId?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    relatedCommentId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    description: string;
}

export function NotificationFromJSON(json: any): Notification {
    return {
        'projectId': json['projectId'],
        'notificationId': json['notificationId'],
        'userId': json['userId'],
        'relatedIdeaId': !exists(json, 'relatedIdeaId') ? undefined : json['relatedIdeaId'],
        'relatedCommentId': !exists(json, 'relatedCommentId') ? undefined : json['relatedCommentId'],
        'created': new Date(json['created']),
        'description': json['description'],
    };
}

export function NotificationToJSON(value?: Notification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'projectId': value.projectId,
        'notificationId': value.notificationId,
        'userId': value.userId,
        'relatedIdeaId': value.relatedIdeaId,
        'relatedCommentId': value.relatedCommentId,
        'created': value.created.toISOString(),
        'description': value.description,
    };
}


