/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserMeWithBalance,
    UserMeWithBalanceFromJSON,
    UserMeWithBalanceToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserCreateResponse
 */
export interface UserCreateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateResponse
     */
    requiresEmailVerification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateResponse
     */
    requiresEmailLogin?: boolean;
    /**
     * 
     * @type {UserMeWithBalance}
     * @memberof UserCreateResponse
     */
    user?: UserMeWithBalance;
}

export function UserCreateResponseFromJSON(json: any): UserCreateResponse {
    return {
        'requiresEmailVerification': !exists(json, 'requiresEmailVerification') ? undefined : json['requiresEmailVerification'],
        'requiresEmailLogin': !exists(json, 'requiresEmailLogin') ? undefined : json['requiresEmailLogin'],
        
        'user': !exists(json, 'user') ? undefined : UserMeWithBalanceFromJSON(json['user']),
    };
}

export function UserCreateResponseToJSON(value?: UserCreateResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'requiresEmailVerification': value.requiresEmailVerification,
        'requiresEmailLogin': value.requiresEmailLogin,
        
        'user': UserMeWithBalanceToJSON(value.user),
    };
}


