/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ContentUploadResponse
 */
export interface ContentUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof ContentUploadResponse
     */
    url: string;
}

export function ContentUploadResponseFromJSON(json: any): ContentUploadResponse {
    return {
        'url': json['url'],
    };
}

export function ContentUploadResponseToJSON(value?: ContentUploadResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
    };
}


