/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Menu
 */
export interface Menu {
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    menuId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Menu
     */
    pageIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    icon?: string;
}

export function MenuFromJSON(json: any): Menu {
    return {
        'menuId': json['menuId'],
        'pageIds': json['pageIds'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function MenuToJSON(value?: Menu): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'menuId': value.menuId,
        'pageIds': value.pageIds,
        'name': value.name,
        'icon': value.icon,
    };
}


