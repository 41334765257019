/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    VoteOption,
    VoteOptionFromJSON,
    VoteOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommentVoteUpdate
 */
export interface CommentVoteUpdate {
    /**
     * 
     * @type {VoteOption}
     * @memberof CommentVoteUpdate
     */
    vote: VoteOption;
}

export function CommentVoteUpdateFromJSON(json: any): CommentVoteUpdate {
    return {
        
        'vote': VoteOptionFromJSON(json['vote']),
    };
}

export function CommentVoteUpdateToJSON(value?: CommentVoteUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'vote': VoteOptionToJSON(value.vote),
    };
}


