/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Expression,
    ExpressionFromJSON,
    ExpressionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Expressing
 */
export interface Expressing {
    /**
     * 
     * @type {boolean}
     * @memberof Expressing
     */
    limitEmojiPerIdea: boolean;
    /**
     * 
     * @type {Array<Expression>}
     * @memberof Expressing
     */
    limitEmojiSet?: Array<Expression>;
}

export function ExpressingFromJSON(json: any): Expressing {
    return {
        'limitEmojiPerIdea': json['limitEmojiPerIdea'],
        
        'limitEmojiSet': !exists(json, 'limitEmojiSet') ? undefined : (json['limitEmojiSet'] as Array<any>).map(ExpressionFromJSON),
        
    };
}

export function ExpressingToJSON(value?: Expressing): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'limitEmojiPerIdea': value.limitEmojiPerIdea,
        
        'limitEmojiSet': value.limitEmojiSet === undefined ? undefined : (value.limitEmojiSet as Array<any>).map(ExpressionToJSON),
        
    };
}


