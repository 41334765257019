/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Whitelabel
 */
export interface Whitelabel {
    /**
     * 
     * @type {string}
     * @memberof Whitelabel
     */
    poweredBy: WhitelabelPoweredByEnum;
}

export function WhitelabelFromJSON(json: any): Whitelabel {
    return {
        'poweredBy': json['poweredBy'],
    };
}

export function WhitelabelToJSON(value?: Whitelabel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'poweredBy': value.poweredBy,
    };
}

/**
* @export
* @enum {string}
*/
export enum WhitelabelPoweredByEnum {
    Show = 'Show',
    Minimal = 'Minimal',
    Hidden = 'Hidden'
}


