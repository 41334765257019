/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TagGroup
 */
export interface TagGroup {
    /**
     * 
     * @type {string}
     * @memberof TagGroup
     */
    tagGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof TagGroup
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TagGroup
     */
    minRequired?: number;
    /**
     * 
     * @type {number}
     * @memberof TagGroup
     */
    maxRequired?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TagGroup
     */
    userSettable: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagGroup
     */
    tagIds: Array<string>;
}

export function TagGroupFromJSON(json: any): TagGroup {
    return {
        'tagGroupId': json['tagGroupId'],
        'name': json['name'],
        'minRequired': !exists(json, 'minRequired') ? undefined : json['minRequired'],
        'maxRequired': !exists(json, 'maxRequired') ? undefined : json['maxRequired'],
        'userSettable': json['userSettable'],
        'tagIds': json['tagIds'],
    };
}

export function TagGroupToJSON(value?: TagGroup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'tagGroupId': value.tagGroupId,
        'name': value.name,
        'minRequired': value.minRequired,
        'maxRequired': value.maxRequired,
        'userSettable': value.userSettable,
        'tagIds': value.tagIds,
    };
}


