/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AvailableRepo,
    AvailableRepoFromJSON,
    AvailableRepoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AvailableRepos
 */
export interface AvailableRepos {
    /**
     * 
     * @type {Array<AvailableRepo>}
     * @memberof AvailableRepos
     */
    repos: Array<AvailableRepo>;
}

export function AvailableReposFromJSON(json: any): AvailableRepos {
    return {
        
        'repos': (json['repos'] as Array<any>).map(AvailableRepoFromJSON),
        
    };
}

export function AvailableReposToJSON(value?: AvailableRepos): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'repos': (value.repos as Array<any>).map(AvailableRepoToJSON),
        
    };
}


