/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    InvitationAdmin,
    InvitationAdminFromJSON,
    InvitationAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectAdminsInviteResult
 */
export interface ProjectAdminsInviteResult {
    /**
     * 
     * @type {InvitationAdmin}
     * @memberof ProjectAdminsInviteResult
     */
    invitation: InvitationAdmin;
}

export function ProjectAdminsInviteResultFromJSON(json: any): ProjectAdminsInviteResult {
    return {
        
        'invitation': InvitationAdminFromJSON(json['invitation']),
    };
}

export function ProjectAdminsInviteResultToJSON(value?: ProjectAdminsInviteResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'invitation': InvitationAdminToJSON(value.invitation),
    };
}


