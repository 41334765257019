/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
/**
 * 
 * @export
 * @enum {string}
 */
export enum HistogramInterval {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    YEAR = 'YEAR'
}

export function HistogramIntervalFromJSON(json: any): HistogramInterval {
    return json as HistogramInterval;
}

export function HistogramIntervalToJSON(value?: HistogramInterval): any {
    return value as any;
}

