/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { Configuration } from '../runtime';
// eslint-disable-next-line no-unused-vars
import * as CommentApi from './CommentApi';
// eslint-disable-next-line no-unused-vars
import * as ContentApi from './ContentApi';
// eslint-disable-next-line no-unused-vars
import * as CreditApi from './CreditApi';
// eslint-disable-next-line no-unused-vars
import * as IdeaApi from './IdeaApi';
// eslint-disable-next-line no-unused-vars
import * as NotificationApi from './NotificationApi';
// eslint-disable-next-line no-unused-vars
import * as ProjectApi from './ProjectApi';
// eslint-disable-next-line no-unused-vars
import * as UserApi from './UserApi';
// eslint-disable-next-line no-unused-vars
import * as VoteApi from './VoteApi';

export * from './CommentApi';
export * from './ContentApi';
export * from './CreditApi';
export * from './IdeaApi';
export * from './NotificationApi';
export * from './ProjectApi';
export * from './UserApi';
export * from './VoteApi';

export interface ApiInterface extends CommentApi.CommentApiInterface, ContentApi.ContentApiInterface, CreditApi.CreditApiInterface, IdeaApi.IdeaApiInterface, NotificationApi.NotificationApiInterface, ProjectApi.ProjectApiInterface, UserApi.UserApiInterface, VoteApi.VoteApiInterface {};
export class Api {
    apis: any

    constructor(configuration?:Configuration, apiOverride?:ApiInterface) {
        this.apis = {};
        this.apis.CommentApi = apiOverride || new CommentApi.CommentApi(configuration);
        this.apis.ContentApi = apiOverride || new ContentApi.ContentApi(configuration);
        this.apis.CreditApi = apiOverride || new CreditApi.CreditApi(configuration);
        this.apis.IdeaApi = apiOverride || new IdeaApi.IdeaApi(configuration);
        this.apis.NotificationApi = apiOverride || new NotificationApi.NotificationApi(configuration);
        this.apis.ProjectApi = apiOverride || new ProjectApi.ProjectApi(configuration);
        this.apis.UserApi = apiOverride || new UserApi.UserApi(configuration);
        this.apis.VoteApi = apiOverride || new VoteApi.VoteApi(configuration);
    }

    getCommentApi():CommentApi.CommentApi {
        return this.apis.CommentApi;
    }

    getContentApi():ContentApi.ContentApi {
        return this.apis.ContentApi;
    }

    getCreditApi():CreditApi.CreditApi {
        return this.apis.CreditApi;
    }

    getIdeaApi():IdeaApi.IdeaApi {
        return this.apis.IdeaApi;
    }

    getNotificationApi():NotificationApi.NotificationApi {
        return this.apis.NotificationApi;
    }

    getProjectApi():ProjectApi.ProjectApi {
        return this.apis.ProjectApi;
    }

    getUserApi():UserApi.UserApi {
        return this.apis.UserApi;
    }

    getVoteApi():VoteApi.VoteApi {
        return this.apis.VoteApi;
    }
};
