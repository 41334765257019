/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConfigAdmin,
    ConfigAdminFromJSON,
    ConfigAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface VersionedConfigAdmin
 */
export interface VersionedConfigAdmin {
    /**
     * 
     * @type {ConfigAdmin}
     * @memberof VersionedConfigAdmin
     */
    config: ConfigAdmin;
    /**
     * 
     * @type {string}
     * @memberof VersionedConfigAdmin
     */
    version: string;
}

export function VersionedConfigAdminFromJSON(json: any): VersionedConfigAdmin {
    return {
        
        'config': ConfigAdminFromJSON(json['config']),
        'version': json['version'],
    };
}

export function VersionedConfigAdminToJSON(value?: VersionedConfigAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'config': ConfigAdminToJSON(value.config),
        'version': value.version,
    };
}


