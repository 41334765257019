/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
/**
 * Transaction type * Vote - Balance changes due to funding an idea * Income - Income based on project settings or via API. * Adjustment - Typically Admin initiated change in credit.
 * @export
 * @enum {string}
 */
export enum TransactionType {
    Vote = 'Vote',
    Income = 'Income',
    Adjustment = 'Adjustment'
}

export function TransactionTypeFromJSON(json: any): TransactionType {
    return json as TransactionType;
}

export function TransactionTypeToJSON(value?: TransactionType): any {
    return value as any;
}

