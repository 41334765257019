/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CommentWithVote,
    CommentWithVoteFromJSON,
    CommentWithVoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommentVoteUpdateResponse
 */
export interface CommentVoteUpdateResponse {
    /**
     * 
     * @type {CommentWithVote}
     * @memberof CommentVoteUpdateResponse
     */
    comment: CommentWithVote;
}

export function CommentVoteUpdateResponseFromJSON(json: any): CommentVoteUpdateResponse {
    return {
        
        'comment': CommentWithVoteFromJSON(json['comment']),
    };
}

export function CommentVoteUpdateResponseToJSON(value?: CommentVoteUpdateResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'comment': CommentWithVoteToJSON(value.comment),
    };
}


