/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface PlanPerk
 */
export interface PlanPerk {
    /**
     * 
     * @type {string}
     * @memberof PlanPerk
     */
    desc: string;
    /**
     * 
     * @type {string}
     * @memberof PlanPerk
     */
    terms?: string;
}

export function PlanPerkFromJSON(json: any): PlanPerk {
    return {
        'desc': json['desc'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
    };
}

export function PlanPerkToJSON(value?: PlanPerk): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'desc': value.desc,
        'terms': value.terms,
    };
}


