/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ImportResponse
 */
export interface ImportResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportResponse
     */
    userFacingMessage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportResponse
     */
    isError?: boolean;
}

export function ImportResponseFromJSON(json: any): ImportResponse {
    return {
        'userFacingMessage': !exists(json, 'userFacingMessage') ? undefined : json['userFacingMessage'],
        'isError': !exists(json, 'isError') ? undefined : json['isError'],
    };
}

export function ImportResponseToJSON(value?: ImportResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'userFacingMessage': value.userFacingMessage,
        'isError': value.isError,
    };
}


