/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Palette
 */
export interface Palette {
    /**
     * 
     * @type {boolean}
     * @memberof Palette
     */
    darkMode: boolean;
    /**
     * 
     * @type {string}
     * @memberof Palette
     */
    primary?: string;
    /**
     * 
     * @type {string}
     * @memberof Palette
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof Palette
     */
    background?: string;
    /**
     * 
     * @type {string}
     * @memberof Palette
     */
    backgroundPaper?: string;
    /**
     * 
     * @type {string}
     * @memberof Palette
     */
    expressionColor?: PaletteExpressionColorEnum;
    /**
     * 
     * @type {string}
     * @memberof Palette
     */
    funding?: string;
}

export function PaletteFromJSON(json: any): Palette {
    return {
        'darkMode': json['darkMode'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'background': !exists(json, 'background') ? undefined : json['background'],
        'backgroundPaper': !exists(json, 'backgroundPaper') ? undefined : json['backgroundPaper'],
        'expressionColor': !exists(json, 'expressionColor') ? undefined : json['expressionColor'],
        'funding': !exists(json, 'funding') ? undefined : json['funding'],
    };
}

export function PaletteToJSON(value?: Palette): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'darkMode': value.darkMode,
        'primary': value.primary,
        'text': value.text,
        'background': value.background,
        'backgroundPaper': value.backgroundPaper,
        'expressionColor': value.expressionColor,
        'funding': value.funding,
    };
}

/**
* @export
* @enum {string}
*/
export enum PaletteExpressionColorEnum {
    Color = 'Color',
    Washed = 'Washed',
    Gray = 'Gray'
}


