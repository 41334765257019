/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaAggregateResponse
 */
export interface IdeaAggregateResponse {
    /**
     * 
     * @type {number}
     * @memberof IdeaAggregateResponse
     */
    total: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IdeaAggregateResponse
     */
    statuses: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IdeaAggregateResponse
     */
    tags: { [key: string]: number; };
}

export function IdeaAggregateResponseFromJSON(json: any): IdeaAggregateResponse {
    return {
        'total': json['total'],
        'statuses': json['statuses'],
        'tags': json['tags'],
    };
}

export function IdeaAggregateResponseToJSON(value?: IdeaAggregateResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'total': value.total,
        'statuses': value.statuses,
        'tags': value.tags,
    };
}


