/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountFields,
    AccountFieldsFromJSON,
    AccountFieldsToJSON,
    CustomTerms,
    CustomTermsFromJSON,
    CustomTermsToJSON,
    NotificationMethods,
    NotificationMethodsFromJSON,
    NotificationMethodsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Onboarding
 */
export interface Onboarding {
    /**
     * 
     * @type {string}
     * @memberof Onboarding
     */
    visibility: OnboardingVisibilityEnum;
    /**
     * 
     * @type {AccountFields}
     * @memberof Onboarding
     */
    accountFields?: AccountFields;
    /**
     * 
     * @type {NotificationMethods}
     * @memberof Onboarding
     */
    notificationMethods: NotificationMethods;
    /**
     * 
     * @type {CustomTerms}
     * @memberof Onboarding
     */
    terms?: CustomTerms;
}

export function OnboardingFromJSON(json: any): Onboarding {
    return {
        'visibility': json['visibility'],
        
        'accountFields': !exists(json, 'accountFields') ? undefined : AccountFieldsFromJSON(json['accountFields']),
        
        'notificationMethods': NotificationMethodsFromJSON(json['notificationMethods']),
        
        'terms': !exists(json, 'terms') ? undefined : CustomTermsFromJSON(json['terms']),
    };
}

export function OnboardingToJSON(value?: Onboarding): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'visibility': value.visibility,
        
        'accountFields': AccountFieldsToJSON(value.accountFields),
        
        'notificationMethods': NotificationMethodsToJSON(value.notificationMethods),
        
        'terms': CustomTermsToJSON(value.terms),
    };
}

/**
* @export
* @enum {string}
*/
export enum OnboardingVisibilityEnum {
    Public = 'Public',
    Private = 'Private'
}


