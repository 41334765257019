/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    GitHubStatusSync,
    GitHubStatusSyncFromJSON,
    GitHubStatusSyncToJSON,
} from './';

/**
 * 
 * @export
 * @interface GitHub
 */
export interface GitHub {
    /**
     * 
     * @type {number}
     * @memberof GitHub
     */
    installationId: number;
    /**
     * 
     * @type {number}
     * @memberof GitHub
     */
    repositoryId: number;
    /**
     * 
     * @type {string}
     * @memberof GitHub
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GitHub
     */
    createWithCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof GitHub
     */
    initialStatusId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GitHub
     */
    createWithTags?: Array<string>;
    /**
     * 
     * @type {GitHubStatusSync}
     * @memberof GitHub
     */
    statusSync?: GitHubStatusSync;
    /**
     * 
     * @type {boolean}
     * @memberof GitHub
     */
    responseSync?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GitHub
     */
    commentSync?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GitHub
     */
    createReleaseWithCategoryId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GitHub
     */
    releaseNotifyAll?: boolean;
}

export function GitHubFromJSON(json: any): GitHub {
    return {
        'installationId': json['installationId'],
        'repositoryId': json['repositoryId'],
        'name': json['name'],
        'createWithCategoryId': json['createWithCategoryId'],
        'initialStatusId': !exists(json, 'initialStatusId') ? undefined : json['initialStatusId'],
        'createWithTags': !exists(json, 'createWithTags') ? undefined : json['createWithTags'],
        
        'statusSync': !exists(json, 'statusSync') ? undefined : GitHubStatusSyncFromJSON(json['statusSync']),
        'responseSync': !exists(json, 'responseSync') ? undefined : json['responseSync'],
        'commentSync': !exists(json, 'commentSync') ? undefined : json['commentSync'],
        'createReleaseWithCategoryId': !exists(json, 'createReleaseWithCategoryId') ? undefined : json['createReleaseWithCategoryId'],
        'releaseNotifyAll': !exists(json, 'releaseNotifyAll') ? undefined : json['releaseNotifyAll'],
    };
}

export function GitHubToJSON(value?: GitHub): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'installationId': value.installationId,
        'repositoryId': value.repositoryId,
        'name': value.name,
        'createWithCategoryId': value.createWithCategoryId,
        'initialStatusId': value.initialStatusId,
        'createWithTags': value.createWithTags,
        
        'statusSync': GitHubStatusSyncToJSON(value.statusSync),
        'responseSync': value.responseSync,
        'commentSync': value.commentSync,
        'createReleaseWithCategoryId': value.createReleaseWithCategoryId,
        'releaseNotifyAll': value.releaseNotifyAll,
    };
}


