/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface LangWhitelist
 */
export interface LangWhitelist {
    /**
     * 
     * @type {Array<string>}
     * @memberof LangWhitelist
     */
    langs: Array<string>;
}

export function LangWhitelistFromJSON(json: any): LangWhitelist {
    return {
        'langs': json['langs'],
    };
}

export function LangWhitelistToJSON(value?: LangWhitelist): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'langs': value.langs,
    };
}


