/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { Configuration } from '../runtime';
// eslint-disable-next-line no-unused-vars
import * as AccountAdminApi from './AccountAdminApi';
// eslint-disable-next-line no-unused-vars
import * as AccountSuperAdminApi from './AccountSuperAdminApi';
// eslint-disable-next-line no-unused-vars
import * as CommentAdminApi from './CommentAdminApi';
// eslint-disable-next-line no-unused-vars
import * as ContentAdminApi from './ContentAdminApi';
// eslint-disable-next-line no-unused-vars
import * as CreditAdminApi from './CreditAdminApi';
// eslint-disable-next-line no-unused-vars
import * as IdeaAdminApi from './IdeaAdminApi';
// eslint-disable-next-line no-unused-vars
import * as LlmAdminApi from './LlmAdminApi';
// eslint-disable-next-line no-unused-vars
import * as LlmSuperAdminApi from './LlmSuperAdminApi';
// eslint-disable-next-line no-unused-vars
import * as PlanAdminApi from './PlanAdminApi';
// eslint-disable-next-line no-unused-vars
import * as PlanSuperAdminApi from './PlanSuperAdminApi';
// eslint-disable-next-line no-unused-vars
import * as ProjectAdminApi from './ProjectAdminApi';
// eslint-disable-next-line no-unused-vars
import * as SupportApi from './SupportApi';
// eslint-disable-next-line no-unused-vars
import * as UserAdminApi from './UserAdminApi';

export * from './AccountAdminApi';
export * from './AccountSuperAdminApi';
export * from './CommentAdminApi';
export * from './ContentAdminApi';
export * from './CreditAdminApi';
export * from './IdeaAdminApi';
export * from './LlmAdminApi';
export * from './LlmSuperAdminApi';
export * from './PlanAdminApi';
export * from './PlanSuperAdminApi';
export * from './ProjectAdminApi';
export * from './SupportApi';
export * from './UserAdminApi';

export interface ApiInterface extends AccountAdminApi.AccountAdminApiInterface, AccountSuperAdminApi.AccountSuperAdminApiInterface, CommentAdminApi.CommentAdminApiInterface, ContentAdminApi.ContentAdminApiInterface, CreditAdminApi.CreditAdminApiInterface, IdeaAdminApi.IdeaAdminApiInterface, LlmAdminApi.LlmAdminApiInterface, LlmSuperAdminApi.LlmSuperAdminApiInterface, PlanAdminApi.PlanAdminApiInterface, PlanSuperAdminApi.PlanSuperAdminApiInterface, ProjectAdminApi.ProjectAdminApiInterface, SupportApi.SupportApiInterface, UserAdminApi.UserAdminApiInterface {};
export class Api {
    apis: any

    constructor(configuration?:Configuration, apiOverride?:ApiInterface) {
        this.apis = {};
        this.apis.AccountAdminApi = apiOverride || new AccountAdminApi.AccountAdminApi(configuration);
        this.apis.AccountSuperAdminApi = apiOverride || new AccountSuperAdminApi.AccountSuperAdminApi(configuration);
        this.apis.CommentAdminApi = apiOverride || new CommentAdminApi.CommentAdminApi(configuration);
        this.apis.ContentAdminApi = apiOverride || new ContentAdminApi.ContentAdminApi(configuration);
        this.apis.CreditAdminApi = apiOverride || new CreditAdminApi.CreditAdminApi(configuration);
        this.apis.IdeaAdminApi = apiOverride || new IdeaAdminApi.IdeaAdminApi(configuration);
        this.apis.LlmAdminApi = apiOverride || new LlmAdminApi.LlmAdminApi(configuration);
        this.apis.LlmSuperAdminApi = apiOverride || new LlmSuperAdminApi.LlmSuperAdminApi(configuration);
        this.apis.PlanAdminApi = apiOverride || new PlanAdminApi.PlanAdminApi(configuration);
        this.apis.PlanSuperAdminApi = apiOverride || new PlanSuperAdminApi.PlanSuperAdminApi(configuration);
        this.apis.ProjectAdminApi = apiOverride || new ProjectAdminApi.ProjectAdminApi(configuration);
        this.apis.SupportApi = apiOverride || new SupportApi.SupportApi(configuration);
        this.apis.UserAdminApi = apiOverride || new UserAdminApi.UserAdminApi(configuration);
    }

    getAccountAdminApi():AccountAdminApi.AccountAdminApi {
        return this.apis.AccountAdminApi;
    }

    getAccountSuperAdminApi():AccountSuperAdminApi.AccountSuperAdminApi {
        return this.apis.AccountSuperAdminApi;
    }

    getCommentAdminApi():CommentAdminApi.CommentAdminApi {
        return this.apis.CommentAdminApi;
    }

    getContentAdminApi():ContentAdminApi.ContentAdminApi {
        return this.apis.ContentAdminApi;
    }

    getCreditAdminApi():CreditAdminApi.CreditAdminApi {
        return this.apis.CreditAdminApi;
    }

    getIdeaAdminApi():IdeaAdminApi.IdeaAdminApi {
        return this.apis.IdeaAdminApi;
    }

    getLlmAdminApi():LlmAdminApi.LlmAdminApi {
        return this.apis.LlmAdminApi;
    }

    getLlmSuperAdminApi():LlmSuperAdminApi.LlmSuperAdminApi {
        return this.apis.LlmSuperAdminApi;
    }

    getPlanAdminApi():PlanAdminApi.PlanAdminApi {
        return this.apis.PlanAdminApi;
    }

    getPlanSuperAdminApi():PlanSuperAdminApi.PlanSuperAdminApi {
        return this.apis.PlanSuperAdminApi;
    }

    getProjectAdminApi():ProjectAdminApi.ProjectAdminApi {
        return this.apis.ProjectAdminApi;
    }

    getSupportApi():SupportApi.SupportApi {
        return this.apis.SupportApi;
    }

    getUserAdminApi():UserAdminApi.UserAdminApi {
        return this.apis.UserAdminApi;
    }
};
