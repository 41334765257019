/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaVoteUpdateExpressions,
    IdeaVoteUpdateExpressionsFromJSON,
    IdeaVoteUpdateExpressionsToJSON,
    VoteOption,
    VoteOptionFromJSON,
    VoteOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaVoteUpdate
 */
export interface IdeaVoteUpdate {
    /**
     * 
     * @type {number}
     * @memberof IdeaVoteUpdate
     */
    fundDiff?: number;
    /**
     * 
     * @type {VoteOption}
     * @memberof IdeaVoteUpdate
     */
    vote?: VoteOption;
    /**
     * 
     * @type {IdeaVoteUpdateExpressions}
     * @memberof IdeaVoteUpdate
     */
    expressions?: IdeaVoteUpdateExpressions;
}

export function IdeaVoteUpdateFromJSON(json: any): IdeaVoteUpdate {
    return {
        'fundDiff': !exists(json, 'fundDiff') ? undefined : json['fundDiff'],
        
        'vote': !exists(json, 'vote') ? undefined : VoteOptionFromJSON(json['vote']),
        
        'expressions': !exists(json, 'expressions') ? undefined : IdeaVoteUpdateExpressionsFromJSON(json['expressions']),
    };
}

export function IdeaVoteUpdateToJSON(value?: IdeaVoteUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'fundDiff': value.fundDiff,
        
        'vote': VoteOptionToJSON(value.vote),
        
        'expressions': IdeaVoteUpdateExpressionsToJSON(value.expressions),
    };
}


