/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CommentWithVote,
    CommentWithVoteFromJSON,
    CommentWithVoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaCommentSearchResponse
 */
export interface IdeaCommentSearchResponse {
    /**
     * 
     * @type {Array<CommentWithVote>}
     * @memberof IdeaCommentSearchResponse
     */
    results: Array<CommentWithVote>;
}

export function IdeaCommentSearchResponseFromJSON(json: any): IdeaCommentSearchResponse {
    return {
        
        'results': (json['results'] as Array<any>).map(CommentWithVoteFromJSON),
        
    };
}

export function IdeaCommentSearchResponseToJSON(value?: IdeaCommentSearchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'results': (value.results as Array<any>).map(CommentWithVoteToJSON),
        
    };
}


