/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PlanPerk,
    PlanPerkFromJSON,
    PlanPerkToJSON,
    PlanPricing,
    PlanPricingFromJSON,
    PlanPricingToJSON,
} from './';

/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    basePlanId: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    title: string;
    /**
     * 
     * @type {PlanPricing}
     * @memberof Plan
     */
    pricing?: PlanPricing;
    /**
     * 
     * @type {Array<PlanPerk>}
     * @memberof Plan
     */
    perks: Array<PlanPerk>;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    comingSoon?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    beta?: boolean;
}

export function PlanFromJSON(json: any): Plan {
    return {
        'basePlanId': json['basePlanId'],
        'title': json['title'],
        
        'pricing': !exists(json, 'pricing') ? undefined : PlanPricingFromJSON(json['pricing']),
        
        'perks': (json['perks'] as Array<any>).map(PlanPerkFromJSON),
        
        'comingSoon': !exists(json, 'comingSoon') ? undefined : json['comingSoon'],
        'beta': !exists(json, 'beta') ? undefined : json['beta'],
    };
}

export function PlanToJSON(value?: Plan): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'basePlanId': value.basePlanId,
        'title': value.title,
        
        'pricing': PlanPricingToJSON(value.pricing),
        
        'perks': (value.perks as Array<any>).map(PlanPerkToJSON),
        
        'comingSoon': value.comingSoon,
        'beta': value.beta,
    };
}


