/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaStatus
 */
export interface IdeaStatus {
    /**
     * 
     * @type {string}
     * @memberof IdeaStatus
     */
    statusId: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaStatus
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaStatus
     */
    nextStatusIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IdeaStatus
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaStatus
     */
    disableFunding: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaStatus
     */
    disableVoting: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaStatus
     */
    disableExpressions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaStatus
     */
    disableIdeaEdits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaStatus
     */
    disableComments: boolean;
}

export function IdeaStatusFromJSON(json: any): IdeaStatus {
    return {
        'statusId': json['statusId'],
        'name': json['name'],
        'nextStatusIds': !exists(json, 'nextStatusIds') ? undefined : json['nextStatusIds'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'disableFunding': json['disableFunding'],
        'disableVoting': json['disableVoting'],
        'disableExpressions': json['disableExpressions'],
        'disableIdeaEdits': json['disableIdeaEdits'],
        'disableComments': json['disableComments'],
    };
}

export function IdeaStatusToJSON(value?: IdeaStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'statusId': value.statusId,
        'name': value.name,
        'nextStatusIds': value.nextStatusIds,
        'color': value.color,
        'disableFunding': value.disableFunding,
        'disableVoting': value.disableVoting,
        'disableExpressions': value.disableExpressions,
        'disableIdeaEdits': value.disableIdeaEdits,
        'disableComments': value.disableComments,
    };
}


