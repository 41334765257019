/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Typography
 */
export interface Typography {
    /**
     * 
     * @type {string}
     * @memberof Typography
     */
    fontFamily?: string;
    /**
     * 
     * @type {number}
     * @memberof Typography
     */
    fontSize?: number;
}

export function TypographyFromJSON(json: any): Typography {
    return {
        'fontFamily': !exists(json, 'fontFamily') ? undefined : json['fontFamily'],
        'fontSize': !exists(json, 'fontSize') ? undefined : json['fontSize'],
    };
}

export function TypographyToJSON(value?: Typography): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'fontFamily': value.fontFamily,
        'fontSize': value.fontSize,
    };
}


