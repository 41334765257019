/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ConvoMessage
 */
export interface ConvoMessage {
    /**
     * 
     * @type {string}
     * @memberof ConvoMessage
     */
    messageId: string;
    /**
     * 
     * @type {Date}
     * @memberof ConvoMessage
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ConvoMessage
     */
    authorType?: ConvoMessageAuthorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConvoMessage
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof ConvoMessage
     */
    inputTokenCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvoMessage
     */
    outputTokenCount?: number;
}

export function ConvoMessageFromJSON(json: any): ConvoMessage {
    return {
        'messageId': json['messageId'],
        'created': new Date(json['created']),
        'authorType': !exists(json, 'authorType') ? undefined : json['authorType'],
        'content': json['content'],
        'inputTokenCount': !exists(json, 'inputTokenCount') ? undefined : json['inputTokenCount'],
        'outputTokenCount': !exists(json, 'outputTokenCount') ? undefined : json['outputTokenCount'],
    };
}

export function ConvoMessageToJSON(value?: ConvoMessage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'messageId': value.messageId,
        'created': value.created.toISOString(),
        'authorType': value.authorType,
        'content': value.content,
        'inputTokenCount': value.inputTokenCount,
        'outputTokenCount': value.outputTokenCount,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConvoMessageAuthorTypeEnum {
    USER = 'USER',
    AI = 'AI',
    ALERT = 'ALERT'
}


