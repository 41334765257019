/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ContentUploadAsAdminResponse
 */
export interface ContentUploadAsAdminResponse {
    /**
     * 
     * @type {string}
     * @memberof ContentUploadAsAdminResponse
     */
    url: string;
}

export function ContentUploadAsAdminResponseFromJSON(json: any): ContentUploadAsAdminResponse {
    return {
        'url': json['url'],
    };
}

export function ContentUploadAsAdminResponseToJSON(value?: ContentUploadAsAdminResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
    };
}


