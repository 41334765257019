/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    InvitationAdmin,
    InvitationAdminFromJSON,
    InvitationAdminToJSON,
    ProjectAdmin,
    ProjectAdminFromJSON,
    ProjectAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectAdminsListResult
 */
export interface ProjectAdminsListResult {
    /**
     * 
     * @type {Array<ProjectAdmin>}
     * @memberof ProjectAdminsListResult
     */
    admins: Array<ProjectAdmin>;
    /**
     * 
     * @type {Array<InvitationAdmin>}
     * @memberof ProjectAdminsListResult
     */
    invitations: Array<InvitationAdmin>;
}

export function ProjectAdminsListResultFromJSON(json: any): ProjectAdminsListResult {
    return {
        
        'admins': (json['admins'] as Array<any>).map(ProjectAdminFromJSON),
        
        
        'invitations': (json['invitations'] as Array<any>).map(InvitationAdminFromJSON),
        
    };
}

export function ProjectAdminsListResultToJSON(value?: ProjectAdminsListResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'admins': (value.admins as Array<any>).map(ProjectAdminToJSON),
        
        
        'invitations': (value.invitations as Array<any>).map(InvitationAdminToJSON),
        
    };
}


