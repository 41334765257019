/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    HistogramInterval,
    HistogramIntervalFromJSON,
    HistogramIntervalToJSON,
} from './';

/**
 * 
 * @export
 * @interface HistogramSearchAdmin
 */
export interface HistogramSearchAdmin {
    /**
     * 
     * @type {Date}
     * @memberof HistogramSearchAdmin
     */
    filterCreatedStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HistogramSearchAdmin
     */
    filterCreatedEnd?: Date;
    /**
     * 
     * @type {HistogramInterval}
     * @memberof HistogramSearchAdmin
     */
    interval?: HistogramInterval;
}

export function HistogramSearchAdminFromJSON(json: any): HistogramSearchAdmin {
    return {
        'filterCreatedStart': !exists(json, 'filterCreatedStart') ? undefined : new Date(json['filterCreatedStart']),
        'filterCreatedEnd': !exists(json, 'filterCreatedEnd') ? undefined : new Date(json['filterCreatedEnd']),
        
        'interval': !exists(json, 'interval') ? undefined : HistogramIntervalFromJSON(json['interval']),
    };
}

export function HistogramSearchAdminToJSON(value?: HistogramSearchAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filterCreatedStart': value.filterCreatedStart === undefined ? undefined : value.filterCreatedStart.toISOString().substr(0,10),
        'filterCreatedEnd': value.filterCreatedEnd === undefined ? undefined : value.filterCreatedEnd.toISOString().substr(0,10),
        
        'interval': HistogramIntervalToJSON(value.interval),
    };
}


