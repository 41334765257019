/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Plan,
    PlanFromJSON,
    PlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface AllPlansGetResponse
 */
export interface AllPlansGetResponse {
    /**
     * 
     * @type {Array<Plan>}
     * @memberof AllPlansGetResponse
     */
    plans: Array<Plan>;
}

export function AllPlansGetResponseFromJSON(json: any): AllPlansGetResponse {
    return {
        
        'plans': (json['plans'] as Array<any>).map(PlanFromJSON),
        
    };
}

export function AllPlansGetResponseToJSON(value?: AllPlansGetResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'plans': (value.plans as Array<any>).map(PlanToJSON),
        
    };
}


