/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserBindOauthToken
 */
export interface UserBindOauthToken {
    /**
     * 
     * @type {string}
     * @memberof UserBindOauthToken
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserBindOauthToken
     */
    code: string;
}

export function UserBindOauthTokenFromJSON(json: any): UserBindOauthToken {
    return {
        'id': json['id'],
        'code': json['code'],
    };
}

export function UserBindOauthTokenToJSON(value?: UserBindOauthToken): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'code': value.code,
    };
}


