/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Config,
    ConfigFromJSON,
    ConfigToJSON,
    ConfigAdminAllOf,
    ConfigAdminAllOfFromJSON,
    ConfigAdminAllOfToJSON,
    Content,
    ContentFromJSON,
    ContentToJSON,
    CookieConsent,
    CookieConsentFromJSON,
    CookieConsentToJSON,
    GitHub,
    GitHubFromJSON,
    GitHubToJSON,
    Integrations,
    IntegrationsFromJSON,
    IntegrationsToJSON,
    LangWhitelist,
    LangWhitelistFromJSON,
    LangWhitelistToJSON,
    Layout,
    LayoutFromJSON,
    LayoutToJSON,
    Style,
    StyleFromJSON,
    StyleToJSON,
    Users,
    UsersFromJSON,
    UsersToJSON,
} from './';

/**
 * All Config properties available for admins. Used for both reading and writing.
 * @export
 * @interface ConfigAdmin
 */
export interface ConfigAdmin {
    /**
     * 
     * @type {number}
     * @memberof ConfigAdmin
     */
    schemaVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    name: string;
    /**
     * Location of the logo to display.
     * @type {string}
     * @memberof ConfigAdmin
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    langDefault?: string;
    /**
     * 
     * @type {LangWhitelist}
     * @memberof ConfigAdmin
     */
    langWhitelist?: LangWhitelist;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigAdmin
     */
    noIndex?: boolean;
    /**
     * 
     * @type {CookieConsent}
     * @memberof ConfigAdmin
     */
    cookieConsent: CookieConsent;
    /**
     * 
     * @type {Layout}
     * @memberof ConfigAdmin
     */
    layout: Layout;
    /**
     * 
     * @type {Content}
     * @memberof ConfigAdmin
     */
    content: Content;
    /**
     * 
     * @type {Style}
     * @memberof ConfigAdmin
     */
    style: Style;
    /**
     * 
     * @type {Users}
     * @memberof ConfigAdmin
     */
    users: Users;
    /**
     * 
     * @type {Integrations}
     * @memberof ConfigAdmin
     */
    integrations: Integrations;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    ssoSecretKey?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfigAdmin
     */
    oauthClientSecrets?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    intercomIdentityVerificationSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigAdmin
     */
    usedAdvancedSettings?: boolean;
    /**
     * 
     * @type {GitHub}
     * @memberof ConfigAdmin
     */
    github?: GitHub;
    /**
     * 
     * @type {string}
     * @memberof ConfigAdmin
     */
    forceSearchEngine?: ConfigAdminForceSearchEngineEnum;
}

export function ConfigAdminFromJSON(json: any): ConfigAdmin {
    return {
        'schemaVersion': !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
        'projectId': json['projectId'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'name': json['name'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'slug': json['slug'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'langDefault': !exists(json, 'langDefault') ? undefined : json['langDefault'],
        
        'langWhitelist': !exists(json, 'langWhitelist') ? undefined : LangWhitelistFromJSON(json['langWhitelist']),
        'noIndex': !exists(json, 'noIndex') ? undefined : json['noIndex'],
        
        'cookieConsent': CookieConsentFromJSON(json['cookieConsent']),
        
        'layout': LayoutFromJSON(json['layout']),
        
        'content': ContentFromJSON(json['content']),
        
        'style': StyleFromJSON(json['style']),
        
        'users': UsersFromJSON(json['users']),
        
        'integrations': IntegrationsFromJSON(json['integrations']),
        'ssoSecretKey': !exists(json, 'ssoSecretKey') ? undefined : json['ssoSecretKey'],
        'oauthClientSecrets': !exists(json, 'oauthClientSecrets') ? undefined : json['oauthClientSecrets'],
        'intercomIdentityVerificationSecret': !exists(json, 'intercomIdentityVerificationSecret') ? undefined : json['intercomIdentityVerificationSecret'],
        'usedAdvancedSettings': !exists(json, 'usedAdvancedSettings') ? undefined : json['usedAdvancedSettings'],
        
        'github': !exists(json, 'github') ? undefined : GitHubFromJSON(json['github']),
        'forceSearchEngine': !exists(json, 'forceSearchEngine') ? undefined : json['forceSearchEngine'],
    };
}

export function ConfigAdminToJSON(value?: ConfigAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'schemaVersion': value.schemaVersion,
        'projectId': value.projectId,
        'website': value.website,
        'name': value.name,
        'logoUrl': value.logoUrl,
        'slug': value.slug,
        'domain': value.domain,
        'langDefault': value.langDefault,
        
        'langWhitelist': LangWhitelistToJSON(value.langWhitelist),
        'noIndex': value.noIndex,
        
        'cookieConsent': CookieConsentToJSON(value.cookieConsent),
        
        'layout': LayoutToJSON(value.layout),
        
        'content': ContentToJSON(value.content),
        
        'style': StyleToJSON(value.style),
        
        'users': UsersToJSON(value.users),
        
        'integrations': IntegrationsToJSON(value.integrations),
        'ssoSecretKey': value.ssoSecretKey,
        'oauthClientSecrets': value.oauthClientSecrets,
        'intercomIdentityVerificationSecret': value.intercomIdentityVerificationSecret,
        'usedAdvancedSettings': value.usedAdvancedSettings,
        
        'github': GitHubToJSON(value.github),
        'forceSearchEngine': value.forceSearchEngine,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConfigAdminForceSearchEngineEnum {
    ElasticSearch = 'ElasticSearch',
    Mysql = 'Mysql',
    ElasticSearchWriteBoth = 'ElasticSearchWriteBoth',
    MysqlWriteBoth = 'MysqlWriteBoth'
}


