/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Config,
    ConfigFromJSON,
    ConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface VersionedConfig
 */
export interface VersionedConfig {
    /**
     * 
     * @type {Config}
     * @memberof VersionedConfig
     */
    config: Config;
    /**
     * 
     * @type {string}
     * @memberof VersionedConfig
     */
    version: string;
}

export function VersionedConfigFromJSON(json: any): VersionedConfig {
    return {
        
        'config': ConfigFromJSON(json['config']),
        'version': json['version'],
    };
}

export function VersionedConfigToJSON(value?: VersionedConfig): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'config': ConfigToJSON(value.config),
        'version': value.version,
    };
}


