/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaUpdate,
    IdeaUpdateFromJSON,
    IdeaUpdateToJSON,
    IdeaUpdateAdminAllOf,
    IdeaUpdateAdminAllOfFromJSON,
    IdeaUpdateAdminAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaUpdateAdmin
 */
export interface IdeaUpdateAdmin {
    /**
     * 
     * @type {string}
     * @memberof IdeaUpdateAdmin
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaUpdateAdmin
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaUpdateAdmin
     */
    response?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaUpdateAdmin
     */
    responseAuthorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaUpdateAdmin
     */
    statusId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaUpdateAdmin
     */
    tagIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IdeaUpdateAdmin
     */
    fundGoal?: number;
    /**
     * Suppresses notifications if status or response was changed
     * @type {boolean}
     * @memberof IdeaUpdateAdmin
     */
    suppressNotifications?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IdeaUpdateAdmin
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaUpdateAdmin
     */
    coverImg?: string;
}

export function IdeaUpdateAdminFromJSON(json: any): IdeaUpdateAdmin {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'responseAuthorUserId': !exists(json, 'responseAuthorUserId') ? undefined : json['responseAuthorUserId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'fundGoal': !exists(json, 'fundGoal') ? undefined : json['fundGoal'],
        'suppressNotifications': !exists(json, 'suppressNotifications') ? undefined : json['suppressNotifications'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'coverImg': !exists(json, 'coverImg') ? undefined : json['coverImg'],
    };
}

export function IdeaUpdateAdminToJSON(value?: IdeaUpdateAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'response': value.response,
        'responseAuthorUserId': value.responseAuthorUserId,
        'statusId': value.statusId,
        'tagIds': value.tagIds,
        'fundGoal': value.fundGoal,
        'suppressNotifications': value.suppressNotifications,
        'order': value.order,
        'coverImg': value.coverImg,
    };
}


