/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    TransactionType,
    TransactionTypeFromJSON,
    TransactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionSearch
 */
export interface TransactionSearch {
    /**
     * 
     * @type {Array<TransactionType>}
     * @memberof TransactionSearch
     */
    filterTransactionTypes?: Array<TransactionType>;
    /**
     * 
     * @type {number}
     * @memberof TransactionSearch
     */
    filterAmountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSearch
     */
    filterAmountMax?: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSearch
     */
    filterCreatedStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSearch
     */
    filterCreatedEnd?: Date;
}

export function TransactionSearchFromJSON(json: any): TransactionSearch {
    return {
        
        'filterTransactionTypes': !exists(json, 'filterTransactionTypes') ? undefined : (json['filterTransactionTypes'] as Array<any>).map(TransactionTypeFromJSON),
        
        'filterAmountMin': !exists(json, 'filterAmountMin') ? undefined : json['filterAmountMin'],
        'filterAmountMax': !exists(json, 'filterAmountMax') ? undefined : json['filterAmountMax'],
        'filterCreatedStart': !exists(json, 'filterCreatedStart') ? undefined : new Date(json['filterCreatedStart']),
        'filterCreatedEnd': !exists(json, 'filterCreatedEnd') ? undefined : new Date(json['filterCreatedEnd']),
    };
}

export function TransactionSearchToJSON(value?: TransactionSearch): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'filterTransactionTypes': value.filterTransactionTypes === undefined ? undefined : (value.filterTransactionTypes as Array<any>).map(TransactionTypeToJSON),
        
        'filterAmountMin': value.filterAmountMin,
        'filterAmountMax': value.filterAmountMax,
        'filterCreatedStart': value.filterCreatedStart === undefined ? undefined : value.filterCreatedStart.toISOString(),
        'filterCreatedEnd': value.filterCreatedEnd === undefined ? undefined : value.filterCreatedEnd.toISOString(),
    };
}


