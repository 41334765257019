/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CreditFormatterEntry
 */
export interface CreditFormatterEntry {
    /**
     * 
     * @type {number}
     * @memberof CreditFormatterEntry
     */
    greaterOrEqual?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditFormatterEntry
     */
    lessOrEqual?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditFormatterEntry
     */
    multiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditFormatterEntry
     */
    minimumFractionDigits?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditFormatterEntry
     */
    maximumFractionDigits?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditFormatterEntry
     */
    prefix?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditFormatterEntry
     */
    suffix?: string;
}

export function CreditFormatterEntryFromJSON(json: any): CreditFormatterEntry {
    return {
        'greaterOrEqual': !exists(json, 'greaterOrEqual') ? undefined : json['greaterOrEqual'],
        'lessOrEqual': !exists(json, 'lessOrEqual') ? undefined : json['lessOrEqual'],
        'multiplier': !exists(json, 'multiplier') ? undefined : json['multiplier'],
        'minimumFractionDigits': !exists(json, 'minimumFractionDigits') ? undefined : json['minimumFractionDigits'],
        'maximumFractionDigits': !exists(json, 'maximumFractionDigits') ? undefined : json['maximumFractionDigits'],
        'prefix': !exists(json, 'prefix') ? undefined : json['prefix'],
        'suffix': !exists(json, 'suffix') ? undefined : json['suffix'],
    };
}

export function CreditFormatterEntryToJSON(value?: CreditFormatterEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'greaterOrEqual': value.greaterOrEqual,
        'lessOrEqual': value.lessOrEqual,
        'multiplier': value.multiplier,
        'minimumFractionDigits': value.minimumFractionDigits,
        'maximumFractionDigits': value.maximumFractionDigits,
        'prefix': value.prefix,
        'suffix': value.suffix,
    };
}


