/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Balance,
    BalanceFromJSON,
    BalanceToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionSearchAdminResponse
 */
export interface TransactionSearchAdminResponse {
    /**
     * Cursor to fetch next set of results.
     * @type {string}
     * @memberof TransactionSearchAdminResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof TransactionSearchAdminResponse
     */
    results: Array<Transaction>;
    /**
     * 
     * @type {Balance}
     * @memberof TransactionSearchAdminResponse
     */
    balance?: Balance;
}

export function TransactionSearchAdminResponseFromJSON(json: any): TransactionSearchAdminResponse {
    return {
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        
        'results': (json['results'] as Array<any>).map(TransactionFromJSON),
        
        
        'balance': !exists(json, 'balance') ? undefined : BalanceFromJSON(json['balance']),
    };
}

export function TransactionSearchAdminResponseToJSON(value?: TransactionSearchAdminResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cursor': value.cursor,
        
        'results': (value.results as Array<any>).map(TransactionToJSON),
        
        
        'balance': BalanceToJSON(value.balance),
    };
}


