/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Flow,
    FlowFromJSON,
    FlowToJSON,
    Palette,
    PaletteFromJSON,
    PaletteToJSON,
    Template,
    TemplateFromJSON,
    TemplateToJSON,
    Typography,
    TypographyFromJSON,
    TypographyToJSON,
    Whitelabel,
    WhitelabelFromJSON,
    WhitelabelToJSON,
} from './';

/**
 * 
 * @export
 * @interface Style
 */
export interface Style {
    /**
     * 
     * @type {Flow}
     * @memberof Style
     */
    animation: Flow;
    /**
     * 
     * @type {Palette}
     * @memberof Style
     */
    palette: Palette;
    /**
     * 
     * @type {Typography}
     * @memberof Style
     */
    typography: Typography;
    /**
     * 
     * @type {Template}
     * @memberof Style
     */
    templates?: Template;
    /**
     * 
     * @type {Whitelabel}
     * @memberof Style
     */
    whitelabel: Whitelabel;
}

export function StyleFromJSON(json: any): Style {
    return {
        
        'animation': FlowFromJSON(json['animation']),
        
        'palette': PaletteFromJSON(json['palette']),
        
        'typography': TypographyFromJSON(json['typography']),
        
        'templates': !exists(json, 'templates') ? undefined : TemplateFromJSON(json['templates']),
        
        'whitelabel': WhitelabelFromJSON(json['whitelabel']),
    };
}

export function StyleToJSON(value?: Style): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'animation': FlowToJSON(value.animation),
        
        'palette': PaletteToJSON(value.palette),
        
        'typography': TypographyToJSON(value.typography),
        
        'templates': TemplateToJSON(value.templates),
        
        'whitelabel': WhitelabelToJSON(value.whitelabel),
    };
}


