/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaCommentSearch
 */
export interface IdeaCommentSearch {
    /**
     * 
     * @type {string}
     * @memberof IdeaCommentSearch
     */
    parentCommentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaCommentSearch
     */
    excludeChildrenCommentIds?: Array<string>;
}

export function IdeaCommentSearchFromJSON(json: any): IdeaCommentSearch {
    return {
        'parentCommentId': !exists(json, 'parentCommentId') ? undefined : json['parentCommentId'],
        'excludeChildrenCommentIds': !exists(json, 'excludeChildrenCommentIds') ? undefined : json['excludeChildrenCommentIds'],
    };
}

export function IdeaCommentSearchToJSON(value?: IdeaCommentSearch): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'parentCommentId': value.parentCommentId,
        'excludeChildrenCommentIds': value.excludeChildrenCommentIds,
    };
}


