/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isMod?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    pic?: string;
}

export function UserFromJSON(json: any): User {
    return {
        'userId': json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isMod': !exists(json, 'isMod') ? undefined : json['isMod'],
        'created': new Date(json['created']),
        'pic': !exists(json, 'pic') ? undefined : json['pic'],
    };
}

export function UserToJSON(value?: User): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'userId': value.userId,
        'name': value.name,
        'isMod': value.isMod,
        'created': value.created.toISOString(),
        'pic': value.pic,
    };
}


