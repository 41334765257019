/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaSearch,
    IdeaSearchFromJSON,
    IdeaSearchToJSON,
    PagePanel,
    PagePanelFromJSON,
    PagePanelToJSON,
    PagePanelWithHideIfEmptyAllOf,
    PagePanelWithHideIfEmptyAllOfFromJSON,
    PagePanelWithHideIfEmptyAllOfToJSON,
    PostDisplay,
    PostDisplayFromJSON,
    PostDisplayToJSON,
} from './';

/**
 * 
 * @export
 * @interface PagePanelWithHideIfEmpty
 */
export interface PagePanelWithHideIfEmpty {
    /**
     * 
     * @type {IdeaSearch}
     * @memberof PagePanelWithHideIfEmpty
     */
    search: IdeaSearch;
    /**
     * 
     * @type {PostDisplay}
     * @memberof PagePanelWithHideIfEmpty
     */
    display: PostDisplay;
    /**
     * 
     * @type {string}
     * @memberof PagePanelWithHideIfEmpty
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PagePanelWithHideIfEmpty
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PagePanelWithHideIfEmpty
     */
    hideIfEmpty: boolean;
}

export function PagePanelWithHideIfEmptyFromJSON(json: any): PagePanelWithHideIfEmpty {
    return {
        
        'search': IdeaSearchFromJSON(json['search']),
        
        'display': PostDisplayFromJSON(json['display']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'hideIfEmpty': json['hideIfEmpty'],
    };
}

export function PagePanelWithHideIfEmptyToJSON(value?: PagePanelWithHideIfEmpty): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'search': IdeaSearchToJSON(value.search),
        
        'display': PostDisplayToJSON(value.display),
        'title': value.title,
        'color': value.color,
        'hideIfEmpty': value.hideIfEmpty,
    };
}


