/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    NotificationSearchResponse,
    NotificationSearchResponseFromJSON,
    NotificationSearchResponseToJSON,
} from '../models';

export interface NotificationClearRequest {
    projectId: string;
    notificationId: string;
}

export interface NotificationClearAllRequest {
    projectId: string;
}

export interface NotificationSearchRequest {
    projectId: string;
    cursor?: string;
}

/**
 * no description
 */
export interface NotificationApiInterface {

    /**
     */
    notificationClear(requestParameters: NotificationClearRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    notificationClearAll(requestParameters: NotificationClearAllRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    notificationSearch(requestParameters: NotificationSearchRequest, headerExtra?:{[key:string]:string}): Promise<NotificationSearchResponse>;
}

/**
 * no description
 */
export class NotificationApi extends runtime.BaseAPI implements NotificationApiInterface {

    /**
     */
    async notificationClearRaw(requestParameters: NotificationClearRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling notificationClear.');
        }

        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling notificationClear.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/notificationclear/{notificationId}/clear`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationClear(requestParameters: NotificationClearRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.notificationClearRaw(requestParameters, headerExtra);
    }

    /**
     */
    async notificationClearAllRaw(requestParameters: NotificationClearAllRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling notificationClearAll.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/notificationclear`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationClearAll(requestParameters: NotificationClearAllRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.notificationClearAllRaw(requestParameters, headerExtra);
    }

    /**
     */
    async notificationSearchRaw(requestParameters: NotificationSearchRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<NotificationSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling notificationSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/notificationsearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async notificationSearch(requestParameters: NotificationSearchRequest, headerExtra?:{[key:string]:string}): Promise<NotificationSearchResponse> {
        const response = await this.notificationSearchRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
