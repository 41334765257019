/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaCreate
 */
export interface IdeaCreate {
    /**
     * 
     * @type {string}
     * @memberof IdeaCreate
     */
    authorUserId: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaCreate
     */
    categoryId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaCreate
     */
    tagIds: Array<string>;
}

export function IdeaCreateFromJSON(json: any): IdeaCreate {
    return {
        'authorUserId': json['authorUserId'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'categoryId': json['categoryId'],
        'tagIds': json['tagIds'],
    };
}

export function IdeaCreateToJSON(value?: IdeaCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'authorUserId': value.authorUserId,
        'title': value.title,
        'description': value.description,
        'categoryId': value.categoryId,
        'tagIds': value.tagIds,
    };
}


