/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserMeWithBalance,
    UserMeWithBalanceFromJSON,
    UserMeWithBalanceToJSON,
    VersionedConfigAdmin,
    VersionedConfigAdminFromJSON,
    VersionedConfigAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewProjectResult
 */
export interface NewProjectResult {
    /**
     * 
     * @type {string}
     * @memberof NewProjectResult
     */
    projectId: string;
    /**
     * 
     * @type {VersionedConfigAdmin}
     * @memberof NewProjectResult
     */
    config: VersionedConfigAdmin;
    /**
     * 
     * @type {UserMeWithBalance}
     * @memberof NewProjectResult
     */
    user: UserMeWithBalance;
}

export function NewProjectResultFromJSON(json: any): NewProjectResult {
    return {
        'projectId': json['projectId'],
        
        'config': VersionedConfigAdminFromJSON(json['config']),
        
        'user': UserMeWithBalanceFromJSON(json['user']),
    };
}

export function NewProjectResultToJSON(value?: NewProjectResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'projectId': value.projectId,
        
        'config': VersionedConfigAdminToJSON(value.config),
        
        'user': UserMeWithBalanceToJSON(value.user),
    };
}


