/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    FeaturesTableFeatures,
    FeaturesTableFeaturesFromJSON,
    FeaturesTableFeaturesToJSON,
} from './';

/**
 * 
 * @export
 * @interface FeaturesTable
 */
export interface FeaturesTable {
    /**
     * 
     * @type {Array<string>}
     * @memberof FeaturesTable
     */
    plans: Array<string>;
    /**
     * 
     * @type {Array<FeaturesTableFeatures>}
     * @memberof FeaturesTable
     */
    features: Array<FeaturesTableFeatures>;
    /**
     * 
     * @type {string}
     * @memberof FeaturesTable
     */
    extraTerms?: string;
}

export function FeaturesTableFromJSON(json: any): FeaturesTable {
    return {
        'plans': json['plans'],
        
        'features': (json['features'] as Array<any>).map(FeaturesTableFeaturesFromJSON),
        
        'extraTerms': !exists(json, 'extraTerms') ? undefined : json['extraTerms'],
    };
}

export function FeaturesTableToJSON(value?: FeaturesTable): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plans': value.plans,
        
        'features': (value.features as Array<any>).map(FeaturesTableFeaturesToJSON),
        
        'extraTerms': value.extraTerms,
    };
}


