/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface HistogramResponsePoints
 */
export interface HistogramResponsePoints {
    /**
     * 
     * @type {Date}
     * @memberof HistogramResponsePoints
     */
    ts: Date;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponsePoints
     */
    cnt: number;
}

export function HistogramResponsePointsFromJSON(json: any): HistogramResponsePoints {
    return {
        'ts': new Date(json['ts']),
        'cnt': json['cnt'],
    };
}

export function HistogramResponsePointsToJSON(value?: HistogramResponsePoints): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ts': value.ts.toISOString().substr(0,10),
        'cnt': value.cnt,
    };
}


