/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaWithVote,
    IdeaWithVoteFromJSON,
    IdeaWithVoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaGetAllResponse
 */
export interface IdeaGetAllResponse {
    /**
     * 
     * @type {Array<IdeaWithVote>}
     * @memberof IdeaGetAllResponse
     */
    results: Array<IdeaWithVote>;
}

export function IdeaGetAllResponseFromJSON(json: any): IdeaGetAllResponse {
    return {
        
        'results': (json['results'] as Array<any>).map(IdeaWithVoteFromJSON),
        
    };
}

export function IdeaGetAllResponseToJSON(value?: IdeaGetAllResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'results': (value.results as Array<any>).map(IdeaWithVoteToJSON),
        
    };
}


