/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CouponGenerateSuperAdmin
 */
export interface CouponGenerateSuperAdmin {
    /**
     * 
     * @type {number}
     * @memberof CouponGenerateSuperAdmin
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CouponGenerateSuperAdmin
     */
    basePlanId: string;
    /**
     * 
     * @type {Date}
     * @memberof CouponGenerateSuperAdmin
     */
    expiry?: Date;
}

export function CouponGenerateSuperAdminFromJSON(json: any): CouponGenerateSuperAdmin {
    return {
        'amount': json['amount'],
        'basePlanId': json['basePlanId'],
        'expiry': !exists(json, 'expiry') ? undefined : new Date(json['expiry']),
    };
}

export function CouponGenerateSuperAdminToJSON(value?: CouponGenerateSuperAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'basePlanId': value.basePlanId,
        'expiry': value.expiry === undefined ? undefined : value.expiry.toISOString(),
    };
}


