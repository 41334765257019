/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    TransactionCreateAdmin,
    TransactionCreateAdminFromJSON,
    TransactionCreateAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserUpdateAdmin
 */
export interface UserUpdateAdmin {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateAdmin
     */
    emailNotify?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateAdmin
     */
    iosPush?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateAdmin
     */
    androidPush?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateAdmin
     */
    browserPush?: boolean;
    /**
     * 
     * @type {TransactionCreateAdmin}
     * @memberof UserUpdateAdmin
     */
    transactionCreate?: TransactionCreateAdmin;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateAdmin
     */
    isMod?: boolean;
}

export function UserUpdateAdminFromJSON(json: any): UserUpdateAdmin {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'emailNotify': !exists(json, 'emailNotify') ? undefined : json['emailNotify'],
        'iosPush': !exists(json, 'iosPush') ? undefined : json['iosPush'],
        'androidPush': !exists(json, 'androidPush') ? undefined : json['androidPush'],
        'browserPush': !exists(json, 'browserPush') ? undefined : json['browserPush'],
        
        'transactionCreate': !exists(json, 'transactionCreate') ? undefined : TransactionCreateAdminFromJSON(json['transactionCreate']),
        'isMod': !exists(json, 'isMod') ? undefined : json['isMod'],
    };
}

export function UserUpdateAdminToJSON(value?: UserUpdateAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'password': value.password,
        'emailNotify': value.emailNotify,
        'iosPush': value.iosPush,
        'androidPush': value.androidPush,
        'browserPush': value.browserPush,
        
        'transactionCreate': TransactionCreateAdminToJSON(value.transactionCreate),
        'isMod': value.isMod,
    };
}


