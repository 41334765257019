/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AnonymousSignup
 */
export interface AnonymousSignup {
    /**
     * 
     * @type {boolean}
     * @memberof AnonymousSignup
     */
    onlyShowIfPushNotAvailable: boolean;
}

export function AnonymousSignupFromJSON(json: any): AnonymousSignup {
    return {
        'onlyShowIfPushNotAvailable': json['onlyShowIfPushNotAvailable'],
    };
}

export function AnonymousSignupToJSON(value?: AnonymousSignup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'onlyShowIfPushNotAvailable': value.onlyShowIfPushNotAvailable,
    };
}


