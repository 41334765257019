/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AllPlansGetResponse,
    AllPlansGetResponseFromJSON,
    AllPlansGetResponseToJSON,
} from '../models';

/**
 * no description
 */
export interface PlanSuperAdminApiInterface {

    /**
     */
    plansGetSuperAdmin(headerExtra?:{[key:string]:string}): Promise<AllPlansGetResponse>;
}

/**
 * no description
 */
export class PlanSuperAdminApi extends runtime.BaseAPI implements PlanSuperAdminApiInterface {

    /**
     */
    async plansGetSuperAdminRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AllPlansGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/superadmin/plan`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AllPlansGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async plansGetSuperAdmin(headerExtra?:{[key:string]:string}): Promise<AllPlansGetResponse> {
        const response = await this.plansGetSuperAdminRaw(headerExtra);
        return await response.value();
    }

}
