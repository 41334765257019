/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    LayoutLanding,
    LayoutLandingFromJSON,
    LayoutLandingToJSON,
    PageBoard,
    PageBoardFromJSON,
    PageBoardToJSON,
    PageExplorer,
    PageExplorerFromJSON,
    PageExplorerToJSON,
    PageFeedback,
    PageFeedbackFromJSON,
    PageFeedbackToJSON,
    PagePanelWithHideIfEmpty,
    PagePanelWithHideIfEmptyFromJSON,
    PagePanelWithHideIfEmptyToJSON,
} from './';

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    pageId: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    pageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    icon?: string;
    /**
     * 
     * @type {LayoutLanding}
     * @memberof Page
     */
    landing?: LayoutLanding;
    /**
     * 
     * @type {Array<PagePanelWithHideIfEmpty>}
     * @memberof Page
     */
    panels: Array<PagePanelWithHideIfEmpty>;
    /**
     * 
     * @type {PageBoard}
     * @memberof Page
     */
    board?: PageBoard;
    /**
     * 
     * @type {PageExplorer}
     * @memberof Page
     */
    explorer?: PageExplorer;
    /**
     * 
     * @type {PageFeedback}
     * @memberof Page
     */
    feedback?: PageFeedback;
}

export function PageFromJSON(json: any): Page {
    return {
        'pageId': json['pageId'],
        'name': json['name'],
        'pageTitle': !exists(json, 'pageTitle') ? undefined : json['pageTitle'],
        'slug': json['slug'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        
        'landing': !exists(json, 'landing') ? undefined : LayoutLandingFromJSON(json['landing']),
        
        'panels': (json['panels'] as Array<any>).map(PagePanelWithHideIfEmptyFromJSON),
        
        
        'board': !exists(json, 'board') ? undefined : PageBoardFromJSON(json['board']),
        
        'explorer': !exists(json, 'explorer') ? undefined : PageExplorerFromJSON(json['explorer']),
        
        'feedback': !exists(json, 'feedback') ? undefined : PageFeedbackFromJSON(json['feedback']),
    };
}

export function PageToJSON(value?: Page): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pageId': value.pageId,
        'name': value.name,
        'pageTitle': value.pageTitle,
        'slug': value.slug,
        'title': value.title,
        'description': value.description,
        'icon': value.icon,
        
        'landing': LayoutLandingToJSON(value.landing),
        
        'panels': (value.panels as Array<any>).map(PagePanelWithHideIfEmptyToJSON),
        
        
        'board': PageBoardToJSON(value.board),
        
        'explorer': PageExplorerToJSON(value.explorer),
        
        'feedback': PageFeedbackToJSON(value.feedback),
    };
}


