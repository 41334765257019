/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface LandingLink
 */
export interface LandingLink {
    /**
     * 
     * @type {string}
     * @memberof LandingLink
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LandingLink
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingLink
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingLink
     */
    linkToPageId?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingLink
     */
    url?: string;
}

export function LandingLinkFromJSON(json: any): LandingLink {
    return {
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'linkToPageId': !exists(json, 'linkToPageId') ? undefined : json['linkToPageId'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function LandingLinkToJSON(value?: LandingLink): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'icon': value.icon,
        'linkToPageId': value.linkToPageId,
        'url': value.url,
    };
}


