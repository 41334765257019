/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaVoteUpdateExpressions
 */
export interface IdeaVoteUpdateExpressions {
    /**
     * 
     * @type {string}
     * @memberof IdeaVoteUpdateExpressions
     */
    action: IdeaVoteUpdateExpressionsActionEnum;
    /**
     * 
     * @type {string}
     * @memberof IdeaVoteUpdateExpressions
     */
    expression?: string;
}

export function IdeaVoteUpdateExpressionsFromJSON(json: any): IdeaVoteUpdateExpressions {
    return {
        'action': json['action'],
        'expression': !exists(json, 'expression') ? undefined : json['expression'],
    };
}

export function IdeaVoteUpdateExpressionsToJSON(value?: IdeaVoteUpdateExpressions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'action': value.action,
        'expression': value.expression,
    };
}

/**
* @export
* @enum {string}
*/
export enum IdeaVoteUpdateExpressionsActionEnum {
    Set = 'set',
    Unset = 'unset',
    Add = 'add',
    Remove = 'remove'
}


