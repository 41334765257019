/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountBillingPayment
 */
export interface AccountBillingPayment {
    /**
     * 
     * @type {string}
     * @memberof AccountBillingPayment
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBillingPayment
     */
    last4: string;
    /**
     * 
     * @type {number}
     * @memberof AccountBillingPayment
     */
    expiryMonth: number;
    /**
     * 
     * @type {number}
     * @memberof AccountBillingPayment
     */
    expiryYear: number;
}

export function AccountBillingPaymentFromJSON(json: any): AccountBillingPayment {
    return {
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'last4': json['last4'],
        'expiryMonth': json['expiryMonth'],
        'expiryYear': json['expiryYear'],
    };
}

export function AccountBillingPaymentToJSON(value?: AccountBillingPayment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'brand': value.brand,
        'last4': value.last4,
        'expiryMonth': value.expiryMonth,
        'expiryYear': value.expiryYear,
    };
}


