/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Hits,
    HitsFromJSON,
    HitsToJSON,
    IdeaWithVote,
    IdeaWithVoteFromJSON,
    IdeaWithVoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaWithVoteSearchResponse
 */
export interface IdeaWithVoteSearchResponse {
    /**
     * Cursor to fetch next set of results.
     * @type {string}
     * @memberof IdeaWithVoteSearchResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<IdeaWithVote>}
     * @memberof IdeaWithVoteSearchResponse
     */
    results: Array<IdeaWithVote>;
    /**
     * 
     * @type {Hits}
     * @memberof IdeaWithVoteSearchResponse
     */
    hits?: Hits;
}

export function IdeaWithVoteSearchResponseFromJSON(json: any): IdeaWithVoteSearchResponse {
    return {
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        
        'results': (json['results'] as Array<any>).map(IdeaWithVoteFromJSON),
        
        
        'hits': !exists(json, 'hits') ? undefined : HitsFromJSON(json['hits']),
    };
}

export function IdeaWithVoteSearchResponseToJSON(value?: IdeaWithVoteSearchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cursor': value.cursor,
        
        'results': (value.results as Array<any>).map(IdeaWithVoteToJSON),
        
        
        'hits': HitsToJSON(value.hits),
    };
}


