/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountAdmin,
    AccountAdminFromJSON,
    AccountAdminToJSON,
    AccountCreditAdjustment,
    AccountCreditAdjustmentFromJSON,
    AccountCreditAdjustmentToJSON,
    AccountLoginAs,
    AccountLoginAsFromJSON,
    AccountLoginAsToJSON,
    AccountSearchResponse,
    AccountSearchResponseFromJSON,
    AccountSearchResponseToJSON,
    AccountSearchSuperAdmin,
    AccountSearchSuperAdminFromJSON,
    AccountSearchSuperAdminToJSON,
    AccountUpdateSuperAdmin,
    AccountUpdateSuperAdminFromJSON,
    AccountUpdateSuperAdminToJSON,
    CouponGenerateSuperAdmin,
    CouponGenerateSuperAdminFromJSON,
    CouponGenerateSuperAdminToJSON,
    ProjectOwnerSwapSuperAdmin,
    ProjectOwnerSwapSuperAdminFromJSON,
    ProjectOwnerSwapSuperAdminToJSON,
} from '../models';

export interface AccountCreditAdjustmentSuperAdminRequest {
    accountCreditAdjustment: AccountCreditAdjustment;
}

export interface AccountLoginAsSuperAdminRequest {
    accountLoginAs: AccountLoginAs;
}

export interface AccountSearchSuperAdminRequest {
    accountSearchSuperAdmin: AccountSearchSuperAdmin;
    cursor?: string;
}

export interface AccountUpdateSuperAdminRequest {
    accountUpdateSuperAdmin: AccountUpdateSuperAdmin;
}

export interface CouponGenerateSuperAdminRequest {
    couponGenerateSuperAdmin: CouponGenerateSuperAdmin;
}

export interface ProjectOwnerSwapSuperAdminRequest {
    projectOwnerSwapSuperAdmin: ProjectOwnerSwapSuperAdmin;
}

/**
 * no description
 */
export interface AccountSuperAdminApiInterface {

    /**
     */
    accountCreditAdjustmentSuperAdmin(requestParameters: AccountCreditAdjustmentSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    accountLoginAsSuperAdmin(requestParameters: AccountLoginAsSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    accountSearchSuperAdmin(requestParameters: AccountSearchSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountSearchResponse>;

    /**
     */
    accountUpdateSuperAdmin(requestParameters: AccountUpdateSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    couponGenerateSuperAdmin(requestParameters: CouponGenerateSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.FileDownload>;

    /**
     */
    projectOwnerSwapSuperAdmin(requestParameters: ProjectOwnerSwapSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;
}

/**
 * no description
 */
export class AccountSuperAdminApi extends runtime.BaseAPI implements AccountSuperAdminApiInterface {

    /**
     */
    async accountCreditAdjustmentSuperAdminRaw(requestParameters: AccountCreditAdjustmentSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountCreditAdjustment === null || requestParameters.accountCreditAdjustment === undefined) {
            throw new runtime.RequiredError('accountCreditAdjustment','Required parameter requestParameters.accountCreditAdjustment was null or undefined when calling accountCreditAdjustmentSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/superadmin/account/billing/credit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountCreditAdjustmentToJSON(requestParameters.accountCreditAdjustment),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountCreditAdjustmentSuperAdmin(requestParameters: AccountCreditAdjustmentSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.accountCreditAdjustmentSuperAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async accountLoginAsSuperAdminRaw(requestParameters: AccountLoginAsSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.accountLoginAs === null || requestParameters.accountLoginAs === undefined) {
            throw new runtime.RequiredError('accountLoginAs','Required parameter requestParameters.accountLoginAs was null or undefined when calling accountLoginAsSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/superadmin/account/loginas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountLoginAsToJSON(requestParameters.accountLoginAs),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountLoginAsSuperAdmin(requestParameters: AccountLoginAsSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountLoginAsSuperAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountSearchSuperAdminRaw(requestParameters: AccountSearchSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountSearchResponse>> {
        if (requestParameters.accountSearchSuperAdmin === null || requestParameters.accountSearchSuperAdmin === undefined) {
            throw new runtime.RequiredError('accountSearchSuperAdmin','Required parameter requestParameters.accountSearchSuperAdmin was null or undefined when calling accountSearchSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/superadmin/accountsearch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountSearchSuperAdminToJSON(requestParameters.accountSearchSuperAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountSearchSuperAdmin(requestParameters: AccountSearchSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountSearchResponse> {
        const response = await this.accountSearchSuperAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountUpdateSuperAdminRaw(requestParameters: AccountUpdateSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.accountUpdateSuperAdmin === null || requestParameters.accountUpdateSuperAdmin === undefined) {
            throw new runtime.RequiredError('accountUpdateSuperAdmin','Required parameter requestParameters.accountUpdateSuperAdmin was null or undefined when calling accountUpdateSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/account/updateSuper`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountUpdateSuperAdminToJSON(requestParameters.accountUpdateSuperAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountUpdateSuperAdmin(requestParameters: AccountUpdateSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountUpdateSuperAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async couponGenerateSuperAdminRaw(requestParameters: CouponGenerateSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<runtime.FileDownload>> {
        if (requestParameters.couponGenerateSuperAdmin === null || requestParameters.couponGenerateSuperAdmin === undefined) {
            throw new runtime.RequiredError('couponGenerateSuperAdmin','Required parameter requestParameters.couponGenerateSuperAdmin was null or undefined when calling couponGenerateSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/superadmin/coupon/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CouponGenerateSuperAdminToJSON(requestParameters.couponGenerateSuperAdmin),
        });

        return new runtime.FileDownloadApiResponse(response);
    }

    /**
     */
    async couponGenerateSuperAdmin(requestParameters: CouponGenerateSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.FileDownload> {
        const response = await this.couponGenerateSuperAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async projectOwnerSwapSuperAdminRaw(requestParameters: ProjectOwnerSwapSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectOwnerSwapSuperAdmin === null || requestParameters.projectOwnerSwapSuperAdmin === undefined) {
            throw new runtime.RequiredError('projectOwnerSwapSuperAdmin','Required parameter requestParameters.projectOwnerSwapSuperAdmin was null or undefined when calling projectOwnerSwapSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/superadmin/account/projectownerswap`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectOwnerSwapSuperAdminToJSON(requestParameters.projectOwnerSwapSuperAdmin),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectOwnerSwapSuperAdmin(requestParameters: ProjectOwnerSwapSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.projectOwnerSwapSuperAdminRaw(requestParameters, headerExtra);
    }

}
