/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConvoDetailsResponse,
    ConvoDetailsResponseFromJSON,
    ConvoDetailsResponseToJSON,
    ConvoListResponse,
    ConvoListResponseFromJSON,
    ConvoListResponseToJSON,
    ConvoMessageCreate,
    ConvoMessageCreateFromJSON,
    ConvoMessageCreateToJSON,
    CreateMessageResponse,
    CreateMessageResponseFromJSON,
    CreateMessageResponseToJSON,
} from '../models';

export interface ConvoDeleteAdminRequest {
    projectId: string;
    convoId: string;
}

export interface ConvoDetailsAdminRequest {
    projectId: string;
    convoId: string;
}

export interface ConvoListAdminRequest {
    projectId: string;
}

export interface MessageCreateAdminRequest {
    projectId: string;
    convoId: string;
    convoMessageCreate: ConvoMessageCreate;
}

export interface MessageStreamGetAdminRequest {
    projectId: string;
    convoId: string;
    messageId: string;
}

/**
 * no description
 */
export interface LlmAdminApiInterface {

    /**
     */
    convoDeleteAdmin(requestParameters: ConvoDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    convoDetailsAdmin(requestParameters: ConvoDetailsAdminRequest, headerExtra?:{[key:string]:string}): Promise<ConvoDetailsResponse>;

    /**
     */
    convoListAdmin(requestParameters: ConvoListAdminRequest, headerExtra?:{[key:string]:string}): Promise<ConvoListResponse>;

    /**
     */
    messageCreateAdmin(requestParameters: MessageCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<CreateMessageResponse>;

    /**
     */
    messageStreamGetAdmin(requestParameters: MessageStreamGetAdminRequest, ): EventSource;
}

/**
 * no description
 */
export class LlmAdminApi extends runtime.BaseAPI implements LlmAdminApiInterface {

    /**
     */
    async convoDeleteAdminRaw(requestParameters: ConvoDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling convoDeleteAdmin.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling convoDeleteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/{convoId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async convoDeleteAdmin(requestParameters: ConvoDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.convoDeleteAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async convoDetailsAdminRaw(requestParameters: ConvoDetailsAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConvoDetailsResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling convoDetailsAdmin.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling convoDetailsAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/{convoId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConvoDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async convoDetailsAdmin(requestParameters: ConvoDetailsAdminRequest, headerExtra?:{[key:string]:string}): Promise<ConvoDetailsResponse> {
        const response = await this.convoDetailsAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async convoListAdminRaw(requestParameters: ConvoListAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConvoListResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling convoListAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/list`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConvoListResponseFromJSON(jsonValue));
    }

    /**
     */
    async convoListAdmin(requestParameters: ConvoListAdminRequest, headerExtra?:{[key:string]:string}): Promise<ConvoListResponse> {
        const response = await this.convoListAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async messageCreateAdminRaw(requestParameters: MessageCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CreateMessageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling messageCreateAdmin.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling messageCreateAdmin.');
        }

        if (requestParameters.convoMessageCreate === null || requestParameters.convoMessageCreate === undefined) {
            throw new runtime.RequiredError('convoMessageCreate','Required parameter requestParameters.convoMessageCreate was null or undefined when calling messageCreateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/{convoId}/message`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConvoMessageCreateToJSON(requestParameters.convoMessageCreate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async messageCreateAdmin(requestParameters: MessageCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<CreateMessageResponse> {
        const response = await this.messageCreateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    messageStreamGetAdminRaw(requestParameters: MessageStreamGetAdminRequest, ): EventSource {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling messageStreamGetAdmin.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling messageStreamGetAdmin.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling messageStreamGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = new EventSource(this.createFetchParams({
            path: `/project/{projectId}/admin/llm/convo/{convoId}/message/{messageId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }).url);

        return response;
    }

    /**
     */
    messageStreamGetAdmin(requestParameters: MessageStreamGetAdminRequest, ): EventSource {
        return this.messageStreamGetAdminRaw(requestParameters, );
    }

}
