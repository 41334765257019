/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserSearchAdmin
 */
export interface UserSearchAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof UserSearchAdmin
     */
    isMod?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSearchAdmin
     */
    sortBy?: UserSearchAdminSortByEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSearchAdmin
     */
    sortOrder?: UserSearchAdminSortOrderEnum;
    /**
     * Search by name and email.
     * @type {string}
     * @memberof UserSearchAdmin
     */
    searchText?: string;
}

export function UserSearchAdminFromJSON(json: any): UserSearchAdmin {
    return {
        'isMod': !exists(json, 'isMod') ? undefined : json['isMod'],
        'sortBy': !exists(json, 'sortBy') ? undefined : json['sortBy'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
    };
}

export function UserSearchAdminToJSON(value?: UserSearchAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'isMod': value.isMod,
        'sortBy': value.sortBy,
        'sortOrder': value.sortOrder,
        'searchText': value.searchText,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserSearchAdminSortByEnum {
    Created = 'Created',
    LastActive = 'LastActive',
    FundedIdeas = 'FundedIdeas',
    SupportedIdeas = 'SupportedIdeas',
    FundedAmount = 'FundedAmount',
    FundsAvailable = 'FundsAvailable'
}
/**
* @export
* @enum {string}
*/
export enum UserSearchAdminSortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}


