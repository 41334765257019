/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserMeWithBalance,
    UserMeWithBalanceFromJSON,
    UserMeWithBalanceToJSON,
    VersionedConfigAdmin,
    VersionedConfigAdminFromJSON,
    VersionedConfigAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigAndBindAllResultByProjectId
 */
export interface ConfigAndBindAllResultByProjectId {
    /**
     * 
     * @type {VersionedConfigAdmin}
     * @memberof ConfigAndBindAllResultByProjectId
     */
    config: VersionedConfigAdmin;
    /**
     * 
     * @type {UserMeWithBalance}
     * @memberof ConfigAndBindAllResultByProjectId
     */
    user: UserMeWithBalance;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigAndBindAllResultByProjectId
     */
    isExternal: boolean;
}

export function ConfigAndBindAllResultByProjectIdFromJSON(json: any): ConfigAndBindAllResultByProjectId {
    return {
        
        'config': VersionedConfigAdminFromJSON(json['config']),
        
        'user': UserMeWithBalanceFromJSON(json['user']),
        'isExternal': json['isExternal'],
    };
}

export function ConfigAndBindAllResultByProjectIdToJSON(value?: ConfigAndBindAllResultByProjectId): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'config': VersionedConfigAdminToJSON(value.config),
        
        'user': UserMeWithBalanceToJSON(value.user),
        'isExternal': value.isExternal,
    };
}


