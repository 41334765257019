/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Expressing,
    ExpressingFromJSON,
    ExpressingToJSON,
    Voting,
    VotingFromJSON,
    VotingToJSON,
} from './';

/**
 * 
 * @export
 * @interface Support
 */
export interface Support {
    /**
     * 
     * @type {boolean}
     * @memberof Support
     */
    fund: boolean;
    /**
     * 
     * @type {Voting}
     * @memberof Support
     */
    vote?: Voting;
    /**
     * 
     * @type {Expressing}
     * @memberof Support
     */
    express?: Expressing;
    /**
     * 
     * @type {boolean}
     * @memberof Support
     */
    comment: boolean;
}

export function SupportFromJSON(json: any): Support {
    return {
        'fund': json['fund'],
        
        'vote': !exists(json, 'vote') ? undefined : VotingFromJSON(json['vote']),
        
        'express': !exists(json, 'express') ? undefined : ExpressingFromJSON(json['express']),
        'comment': json['comment'],
    };
}

export function SupportToJSON(value?: Support): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'fund': value.fund,
        
        'vote': VotingToJSON(value.vote),
        
        'express': ExpressingToJSON(value.express),
        'comment': value.comment,
    };
}


