/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PlansGetResponse,
    PlansGetResponseFromJSON,
    PlansGetResponseToJSON,
} from '../models';

/**
 * no description
 */
export interface PlanAdminApiInterface {

    /**
     */
    plansGet(headerExtra?:{[key:string]:string}): Promise<PlansGetResponse>;
}

/**
 * no description
 */
export class PlanAdminApi extends runtime.BaseAPI implements PlanAdminApiInterface {

    /**
     */
    async plansGetRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<PlansGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/plan`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlansGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async plansGet(headerExtra?:{[key:string]:string}): Promise<PlansGetResponse> {
        const response = await this.plansGetRaw(headerExtra);
        return await response.value();
    }

}
