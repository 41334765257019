/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CommentSearchAdminAllOf,
    CommentSearchAdminAllOfFromJSON,
    CommentSearchAdminAllOfToJSON,
    CommentSearchBase,
    CommentSearchBaseFromJSON,
    CommentSearchBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommentSearchAdmin
 */
export interface CommentSearchAdmin {
    /**
     * 
     * @type {number}
     * @memberof CommentSearchAdmin
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentSearchAdmin
     */
    filterAuthorId?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentSearchAdmin
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentSearchAdmin
     */
    sortBy?: CommentSearchAdminSortByEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentSearchAdmin
     */
    sortOrder?: CommentSearchAdminSortOrderEnum;
}

export function CommentSearchAdminFromJSON(json: any): CommentSearchAdmin {
    return {
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'filterAuthorId': !exists(json, 'filterAuthorId') ? undefined : json['filterAuthorId'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'sortBy': !exists(json, 'sortBy') ? undefined : json['sortBy'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
    };
}

export function CommentSearchAdminToJSON(value?: CommentSearchAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'limit': value.limit,
        'filterAuthorId': value.filterAuthorId,
        'searchText': value.searchText,
        'sortBy': value.sortBy,
        'sortOrder': value.sortOrder,
    };
}

/**
* @export
* @enum {string}
*/
export enum CommentSearchAdminSortByEnum {
    Created = 'Created',
    Edited = 'Edited',
    Top = 'Top'
}
/**
* @export
* @enum {string}
*/
export enum CommentSearchAdminSortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}


