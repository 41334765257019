/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface PageExplorerAllOfAllowSearch
 */
export interface PageExplorerAllOfAllowSearch {
    /**
     * 
     * @type {boolean}
     * @memberof PageExplorerAllOfAllowSearch
     */
    enableSort: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExplorerAllOfAllowSearch
     */
    enableSearchText: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExplorerAllOfAllowSearch
     */
    enableSearchByCategory: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExplorerAllOfAllowSearch
     */
    enableSearchByStatus: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExplorerAllOfAllowSearch
     */
    enableSearchByTag: boolean;
}

export function PageExplorerAllOfAllowSearchFromJSON(json: any): PageExplorerAllOfAllowSearch {
    return {
        'enableSort': json['enableSort'],
        'enableSearchText': json['enableSearchText'],
        'enableSearchByCategory': json['enableSearchByCategory'],
        'enableSearchByStatus': json['enableSearchByStatus'],
        'enableSearchByTag': json['enableSearchByTag'],
    };
}

export function PageExplorerAllOfAllowSearchToJSON(value?: PageExplorerAllOfAllowSearch): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enableSort': value.enableSort,
        'enableSearchText': value.enableSearchText,
        'enableSearchByCategory': value.enableSearchByCategory,
        'enableSearchByStatus': value.enableSearchByStatus,
        'enableSearchByTag': value.enableSearchByTag,
    };
}


