/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SupportMessage
 */
export interface SupportMessage {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SupportMessage
     */
    content: { [key: string]: string; };
}

export function SupportMessageFromJSON(json: any): SupportMessage {
    return {
        'content': json['content'],
    };
}

export function SupportMessageToJSON(value?: SupportMessage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
    };
}


