/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    TemplatePageFooters,
    TemplatePageFootersFromJSON,
    TemplatePageFootersToJSON,
    TemplatePages,
    TemplatePagesFromJSON,
    TemplatePagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    menu?: string;
    /**
     * 
     * @type {Array<TemplatePages>}
     * @memberof Template
     */
    pages: Array<TemplatePages>;
    /**
     * 
     * @type {Array<TemplatePageFooters>}
     * @memberof Template
     */
    pageFooters: Array<TemplatePageFooters>;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    footer?: string;
}

export function TemplateFromJSON(json: any): Template {
    return {
        'header': !exists(json, 'header') ? undefined : json['header'],
        'menu': !exists(json, 'menu') ? undefined : json['menu'],
        
        'pages': (json['pages'] as Array<any>).map(TemplatePagesFromJSON),
        
        
        'pageFooters': (json['pageFooters'] as Array<any>).map(TemplatePageFootersFromJSON),
        
        'footer': !exists(json, 'footer') ? undefined : json['footer'],
    };
}

export function TemplateToJSON(value?: Template): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'header': value.header,
        'menu': value.menu,
        
        'pages': (value.pages as Array<any>).map(TemplatePagesToJSON),
        
        
        'pageFooters': (value.pageFooters as Array<any>).map(TemplatePageFootersToJSON),
        
        'footer': value.footer,
    };
}


