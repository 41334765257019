/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionStatus {
    Active = 'Active',
    ActiveTrial = 'ActiveTrial',
    ActivePaymentRetry = 'ActivePaymentRetry',
    ActiveNoRenewal = 'ActiveNoRenewal',
    Limited = 'Limited',
    NoPaymentMethod = 'NoPaymentMethod',
    Blocked = 'Blocked',
    Cancelled = 'Cancelled'
}

export function SubscriptionStatusFromJSON(json: any): SubscriptionStatus {
    return json as SubscriptionStatus;
}

export function SubscriptionStatusToJSON(value?: SubscriptionStatus): any {
    return value as any;
}

