/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface BuiltIn
 */
export interface BuiltIn {
    /**
     * 
     * @type {string}
     * @memberof BuiltIn
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BuiltIn
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BuiltIn
     */
    accept?: string;
    /**
     * 
     * @type {string}
     * @memberof BuiltIn
     */
    reject?: string;
}

export function BuiltInFromJSON(json: any): BuiltIn {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'accept': !exists(json, 'accept') ? undefined : json['accept'],
        'reject': !exists(json, 'reject') ? undefined : json['reject'],
    };
}

export function BuiltInToJSON(value?: BuiltIn): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'accept': value.accept,
        'reject': value.reject,
    };
}


