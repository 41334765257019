/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassword
     */
    email: string;
}

export function ForgotPasswordFromJSON(json: any): ForgotPassword {
    return {
        'email': json['email'],
    };
}

export function ForgotPasswordToJSON(value?: ForgotPassword): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
    };
}


