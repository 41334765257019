/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    VoteOption,
    VoteOptionFromJSON,
    VoteOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommentVoteGetOwnResponse
 */
export interface CommentVoteGetOwnResponse {
    /**
     * Mapping commentId to vote
     * @type {{ [key: string]: VoteOption; }}
     * @memberof CommentVoteGetOwnResponse
     */
    votesByCommentId: { [key: string]: VoteOption; };
}

export function CommentVoteGetOwnResponseFromJSON(json: any): CommentVoteGetOwnResponse {
    return {
        
        'votesByCommentId': Object.fromEntries(Object.entries(json['votesByCommentId']).map(([k, v]) => [k, VoteOptionFromJSON(v)])),
        
    };
}

export function CommentVoteGetOwnResponseToJSON(value?: CommentVoteGetOwnResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'votesByCommentId': Object.fromEntries(Object.entries(value.votesByCommentId).map(([k, v]) => [k, VoteOptionToJSON(v)])),
        
    };
}


