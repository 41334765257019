/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountBindAdminOauthToken,
    AccountBindAdminOauthTokenFromJSON,
    AccountBindAdminOauthTokenToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountBindAdmin
 */
export interface AccountBindAdmin {
    /**
     * 
     * @type {AccountBindAdminOauthToken}
     * @memberof AccountBindAdmin
     */
    oauthToken?: AccountBindAdminOauthToken;
}

export function AccountBindAdminFromJSON(json: any): AccountBindAdmin {
    return {
        
        'oauthToken': !exists(json, 'oauthToken') ? undefined : AccountBindAdminOauthTokenFromJSON(json['oauthToken']),
    };
}

export function AccountBindAdminToJSON(value?: AccountBindAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'oauthToken': AccountBindAdminOauthTokenToJSON(value.oauthToken),
    };
}


