/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CreditFormatterEntry,
    CreditFormatterEntryFromJSON,
    CreditFormatterEntryToJSON,
    CreditsCreditOnSignup,
    CreditsCreditOnSignupFromJSON,
    CreditsCreditOnSignupToJSON,
    CreditsCreditPurchase,
    CreditsCreditPurchaseFromJSON,
    CreditsCreditPurchaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Credits
 */
export interface Credits {
    /**
     * 
     * @type {CreditsCreditOnSignup}
     * @memberof Credits
     */
    creditOnSignup?: CreditsCreditOnSignup;
    /**
     * 
     * @type {CreditsCreditPurchase}
     * @memberof Credits
     */
    creditPurchase?: CreditsCreditPurchase;
    /**
     * 
     * @type {Array<CreditFormatterEntry>}
     * @memberof Credits
     */
    formats: Array<CreditFormatterEntry>;
}

export function CreditsFromJSON(json: any): Credits {
    return {
        
        'creditOnSignup': !exists(json, 'creditOnSignup') ? undefined : CreditsCreditOnSignupFromJSON(json['creditOnSignup']),
        
        'creditPurchase': !exists(json, 'creditPurchase') ? undefined : CreditsCreditPurchaseFromJSON(json['creditPurchase']),
        
        'formats': (json['formats'] as Array<any>).map(CreditFormatterEntryFromJSON),
        
    };
}

export function CreditsToJSON(value?: Credits): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'creditOnSignup': CreditsCreditOnSignupToJSON(value.creditOnSignup),
        
        'creditPurchase': CreditsCreditPurchaseToJSON(value.creditPurchase),
        
        'formats': (value.formats as Array<any>).map(CreditFormatterEntryToJSON),
        
    };
}


