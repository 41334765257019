/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CreditsCreditPurchase
 */
export interface CreditsCreditPurchase {
    /**
     * 
     * @type {string}
     * @memberof CreditsCreditPurchase
     */
    redirectUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreditsCreditPurchase
     */
    buttonTitle?: string;
}

export function CreditsCreditPurchaseFromJSON(json: any): CreditsCreditPurchase {
    return {
        'redirectUrl': json['redirectUrl'],
        'buttonTitle': !exists(json, 'buttonTitle') ? undefined : json['buttonTitle'],
    };
}

export function CreditsCreditPurchaseToJSON(value?: CreditsCreditPurchase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'redirectUrl': value.redirectUrl,
        'buttonTitle': value.buttonTitle,
    };
}


