/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    HelloBar,
    HelloBarFromJSON,
    HelloBarToJSON,
} from './';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {HelloBar}
     * @memberof Subscription
     */
    hellobar?: HelloBar;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return {
        
        'hellobar': !exists(json, 'hellobar') ? undefined : HelloBarFromJSON(json['hellobar']),
    };
}

export function SubscriptionToJSON(value?: Subscription): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'hellobar': HelloBarToJSON(value.hellobar),
    };
}


