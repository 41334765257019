/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    emailVerification?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    iosPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    androidPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    browserPushToken?: string;
}

export function UserCreateFromJSON(json: any): UserCreate {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailVerification': !exists(json, 'emailVerification') ? undefined : json['emailVerification'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'iosPushToken': !exists(json, 'iosPushToken') ? undefined : json['iosPushToken'],
        'androidPushToken': !exists(json, 'androidPushToken') ? undefined : json['androidPushToken'],
        'browserPushToken': !exists(json, 'browserPushToken') ? undefined : json['browserPushToken'],
    };
}

export function UserCreateToJSON(value?: UserCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'emailVerification': value.emailVerification,
        'password': value.password,
        'iosPushToken': value.iosPushToken,
        'androidPushToken': value.androidPushToken,
        'browserPushToken': value.browserPushToken,
    };
}


