/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountSignupAdmin
 */
export interface AccountSignupAdmin {
    /**
     * 
     * @type {string}
     * @memberof AccountSignupAdmin
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSignupAdmin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSignupAdmin
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSignupAdmin
     */
    basePlanId: string;
    /**
     * 
     * @type {number}
     * @memberof AccountSignupAdmin
     */
    requestedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountSignupAdmin
     */
    invitationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSignupAdmin
     */
    couponId?: string;
}

export function AccountSignupAdminFromJSON(json: any): AccountSignupAdmin {
    return {
        'name': json['name'],
        'email': json['email'],
        'password': json['password'],
        'basePlanId': json['basePlanId'],
        'requestedPrice': !exists(json, 'requestedPrice') ? undefined : json['requestedPrice'],
        'invitationId': !exists(json, 'invitationId') ? undefined : json['invitationId'],
        'couponId': !exists(json, 'couponId') ? undefined : json['couponId'],
    };
}

export function AccountSignupAdminToJSON(value?: AccountSignupAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'password': value.password,
        'basePlanId': value.basePlanId,
        'requestedPrice': value.requestedPrice,
        'invitationId': value.invitationId,
        'couponId': value.couponId,
    };
}


