/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PagePanelWithHideIfEmpty,
    PagePanelWithHideIfEmptyFromJSON,
    PagePanelWithHideIfEmptyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageBoard
 */
export interface PageBoard {
    /**
     * 
     * @type {string}
     * @memberof PageBoard
     */
    title?: string;
    /**
     * 
     * @type {Array<PagePanelWithHideIfEmpty>}
     * @memberof PageBoard
     */
    panels: Array<PagePanelWithHideIfEmpty>;
}

export function PageBoardFromJSON(json: any): PageBoard {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        
        'panels': (json['panels'] as Array<any>).map(PagePanelWithHideIfEmptyFromJSON),
        
    };
}

export function PageBoardToJSON(value?: PageBoard): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        
        'panels': (value.panels as Array<any>).map(PagePanelWithHideIfEmptyToJSON),
        
    };
}


