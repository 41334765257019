/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaStatus,
    IdeaStatusFromJSON,
    IdeaStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    entryStatus?: string;
    /**
     * 
     * @type {Array<IdeaStatus>}
     * @memberof Workflow
     */
    statuses: Array<IdeaStatus>;
}

export function WorkflowFromJSON(json: any): Workflow {
    return {
        'entryStatus': !exists(json, 'entryStatus') ? undefined : json['entryStatus'],
        
        'statuses': (json['statuses'] as Array<any>).map(IdeaStatusFromJSON),
        
    };
}

export function WorkflowToJSON(value?: Workflow): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'entryStatus': value.entryStatus,
        
        'statuses': (value.statuses as Array<any>).map(IdeaStatusToJSON),
        
    };
}


