/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface NotifySubscribers
 */
export interface NotifySubscribers {
    /**
     * 
     * @type {string}
     * @memberof NotifySubscribers
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotifySubscribers
     */
    body: string;
}

export function NotifySubscribersFromJSON(json: any): NotifySubscribers {
    return {
        'title': json['title'],
        'body': json['body'],
    };
}

export function NotifySubscribersToJSON(value?: NotifySubscribers): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'body': value.body,
    };
}


