/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TemplatePageFooters
 */
export interface TemplatePageFooters {
    /**
     * 
     * @type {string}
     * @memberof TemplatePageFooters
     */
    pageId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatePageFooters
     */
    template?: string;
}

export function TemplatePageFootersFromJSON(json: any): TemplatePageFooters {
    return {
        'pageId': !exists(json, 'pageId') ? undefined : json['pageId'],
        'template': !exists(json, 'template') ? undefined : json['template'],
    };
}

export function TemplatePageFootersToJSON(value?: TemplatePageFooters): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pageId': value.pageId,
        'template': value.template,
    };
}


