/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PromptGetResponse,
    PromptGetResponseFromJSON,
    PromptGetResponseToJSON,
} from '../models';

export interface PromptGetSuperAdminRequest {
    projectId: string;
}

/**
 * no description
 */
export interface LlmSuperAdminApiInterface {

    /**
     */
    promptGetSuperAdmin(requestParameters: PromptGetSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<PromptGetResponse>;
}

/**
 * no description
 */
export class LlmSuperAdminApi extends runtime.BaseAPI implements LlmSuperAdminApiInterface {

    /**
     */
    async promptGetSuperAdminRaw(requestParameters: PromptGetSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<PromptGetResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling promptGetSuperAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/superadmin/llm/prompt`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PromptGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async promptGetSuperAdmin(requestParameters: PromptGetSuperAdminRequest, headerExtra?:{[key:string]:string}): Promise<PromptGetResponse> {
        const response = await this.promptGetSuperAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
