/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    TransactionSearch,
    TransactionSearchFromJSON,
    TransactionSearchToJSON,
    TransactionSearchAdminAllOf,
    TransactionSearchAdminAllOfFromJSON,
    TransactionSearchAdminAllOfToJSON,
    TransactionType,
    TransactionTypeFromJSON,
    TransactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionSearchAdmin
 */
export interface TransactionSearchAdmin {
    /**
     * 
     * @type {Array<TransactionType>}
     * @memberof TransactionSearchAdmin
     */
    filterTransactionTypes?: Array<TransactionType>;
    /**
     * 
     * @type {number}
     * @memberof TransactionSearchAdmin
     */
    filterAmountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSearchAdmin
     */
    filterAmountMax?: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSearchAdmin
     */
    filterCreatedStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSearchAdmin
     */
    filterCreatedEnd?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionSearchAdmin
     */
    filterUserIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransactionSearchAdmin
     */
    filterTargetId?: string;
}

export function TransactionSearchAdminFromJSON(json: any): TransactionSearchAdmin {
    return {
        
        'filterTransactionTypes': !exists(json, 'filterTransactionTypes') ? undefined : (json['filterTransactionTypes'] as Array<any>).map(TransactionTypeFromJSON),
        
        'filterAmountMin': !exists(json, 'filterAmountMin') ? undefined : json['filterAmountMin'],
        'filterAmountMax': !exists(json, 'filterAmountMax') ? undefined : json['filterAmountMax'],
        'filterCreatedStart': !exists(json, 'filterCreatedStart') ? undefined : new Date(json['filterCreatedStart']),
        'filterCreatedEnd': !exists(json, 'filterCreatedEnd') ? undefined : new Date(json['filterCreatedEnd']),
        'filterUserIds': !exists(json, 'filterUserIds') ? undefined : json['filterUserIds'],
        'filterTargetId': !exists(json, 'filterTargetId') ? undefined : json['filterTargetId'],
    };
}

export function TransactionSearchAdminToJSON(value?: TransactionSearchAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'filterTransactionTypes': value.filterTransactionTypes === undefined ? undefined : (value.filterTransactionTypes as Array<any>).map(TransactionTypeToJSON),
        
        'filterAmountMin': value.filterAmountMin,
        'filterAmountMax': value.filterAmountMax,
        'filterCreatedStart': value.filterCreatedStart === undefined ? undefined : value.filterCreatedStart.toISOString(),
        'filterCreatedEnd': value.filterCreatedEnd === undefined ? undefined : value.filterCreatedEnd.toISOString(),
        'filterUserIds': value.filterUserIds,
        'filterTargetId': value.filterTargetId,
    };
}


