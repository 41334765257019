/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    BuiltIn,
    BuiltInFromJSON,
    BuiltInToJSON,
    CookieYes,
    CookieYesFromJSON,
    CookieYesToJSON,
} from './';

/**
 * 
 * @export
 * @interface CookieConsent
 */
export interface CookieConsent {
    /**
     * 
     * @type {BuiltIn}
     * @memberof CookieConsent
     */
    builtIn?: BuiltIn;
    /**
     * 
     * @type {CookieYes}
     * @memberof CookieConsent
     */
    cookieYes?: CookieYes;
}

export function CookieConsentFromJSON(json: any): CookieConsent {
    return {
        
        'builtIn': !exists(json, 'builtIn') ? undefined : BuiltInFromJSON(json['builtIn']),
        
        'cookieYes': !exists(json, 'cookieYes') ? undefined : CookieYesFromJSON(json['cookieYes']),
    };
}

export function CookieConsentToJSON(value?: CookieConsent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'builtIn': BuiltInToJSON(value.builtIn),
        
        'cookieYes': CookieYesToJSON(value.cookieYes),
    };
}


