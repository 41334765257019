/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Hits,
    HitsFromJSON,
    HitsToJSON,
    Idea,
    IdeaFromJSON,
    IdeaToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaSearchResponse
 */
export interface IdeaSearchResponse {
    /**
     * Cursor to fetch next set of results.
     * @type {string}
     * @memberof IdeaSearchResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<Idea>}
     * @memberof IdeaSearchResponse
     */
    results: Array<Idea>;
    /**
     * 
     * @type {Hits}
     * @memberof IdeaSearchResponse
     */
    hits?: Hits;
}

export function IdeaSearchResponseFromJSON(json: any): IdeaSearchResponse {
    return {
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        
        'results': (json['results'] as Array<any>).map(IdeaFromJSON),
        
        
        'hits': !exists(json, 'hits') ? undefined : HitsFromJSON(json['hits']),
    };
}

export function IdeaSearchResponseToJSON(value?: IdeaSearchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cursor': value.cursor,
        
        'results': (value.results as Array<any>).map(IdeaToJSON),
        
        
        'hits': HitsToJSON(value.hits),
    };
}


