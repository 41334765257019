/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    SubscriptionEventTypeIdea,
    SubscriptionEventTypeIdeaFromJSON,
    SubscriptionEventTypeIdeaToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubscriptionListenerIdea
 */
export interface SubscriptionListenerIdea {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionListenerIdea
     */
    listenerUrl: string;
    /**
     * 
     * @type {SubscriptionEventTypeIdea}
     * @memberof SubscriptionListenerIdea
     */
    eventType: SubscriptionEventTypeIdea;
}

export function SubscriptionListenerIdeaFromJSON(json: any): SubscriptionListenerIdea {
    return {
        'listenerUrl': json['listenerUrl'],
        
        'eventType': SubscriptionEventTypeIdeaFromJSON(json['eventType']),
    };
}

export function SubscriptionListenerIdeaToJSON(value?: SubscriptionListenerIdea): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'listenerUrl': value.listenerUrl,
        
        'eventType': SubscriptionEventTypeIdeaToJSON(value.eventType),
    };
}


