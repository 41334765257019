/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Comment,
    CommentFromJSON,
    CommentToJSON,
    CommentSearchAdmin,
    CommentSearchAdminFromJSON,
    CommentSearchAdminToJSON,
    CommentSearchAdminResponse,
    CommentSearchAdminResponseFromJSON,
    CommentSearchAdminResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HistogramResponse,
    HistogramResponseFromJSON,
    HistogramResponseToJSON,
    HistogramSearchAdmin,
    HistogramSearchAdminFromJSON,
    HistogramSearchAdminToJSON,
    SubscriptionListenerComment,
    SubscriptionListenerCommentFromJSON,
    SubscriptionListenerCommentToJSON,
} from '../models';

export interface CommentDeleteAdminRequest {
    projectId: string;
    ideaId: string;
    commentId: string;
}

export interface CommentHistogramAdminRequest {
    projectId: string;
    histogramSearchAdmin: HistogramSearchAdmin;
}

export interface CommentSearchAdminRequest {
    projectId: string;
    commentSearchAdmin: CommentSearchAdmin;
    cursor?: string;
}

export interface CommentSubscribeAdminRequest {
    projectId: string;
    subscriptionListenerComment: SubscriptionListenerComment;
}

export interface CommentUnsubscribeAdminRequest {
    projectId: string;
    subscriptionListenerComment: SubscriptionListenerComment;
}

/**
 * no description
 */
export interface CommentAdminApiInterface {

    /**
     */
    commentDeleteAdmin(requestParameters: CommentDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<Comment>;

    /**
     */
    commentHistogramAdmin(requestParameters: CommentHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<HistogramResponse>;

    /**
     */
    commentSearchAdmin(requestParameters: CommentSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<CommentSearchAdminResponse>;

    /**
     */
    commentSubscribeAdmin(requestParameters: CommentSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    commentUnsubscribeAdmin(requestParameters: CommentUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;
}

/**
 * no description
 */
export class CommentAdminApi extends runtime.BaseAPI implements CommentAdminApiInterface {

    /**
     */
    async commentDeleteAdminRaw(requestParameters: CommentDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentDeleteAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling commentDeleteAdmin.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling commentDeleteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}/comment/{commentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     */
    async commentDeleteAdmin(requestParameters: CommentDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<Comment> {
        const response = await this.commentDeleteAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentHistogramAdminRaw(requestParameters: CommentHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<HistogramResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentHistogramAdmin.');
        }

        if (requestParameters.histogramSearchAdmin === null || requestParameters.histogramSearchAdmin === undefined) {
            throw new runtime.RequiredError('histogramSearchAdmin','Required parameter requestParameters.histogramSearchAdmin was null or undefined when calling commentHistogramAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/comment/histogram`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistogramSearchAdminToJSON(requestParameters.histogramSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistogramResponseFromJSON(jsonValue));
    }

    /**
     */
    async commentHistogramAdmin(requestParameters: CommentHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<HistogramResponse> {
        const response = await this.commentHistogramAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentSearchAdminRaw(requestParameters: CommentSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CommentSearchAdminResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentSearchAdmin.');
        }

        if (requestParameters.commentSearchAdmin === null || requestParameters.commentSearchAdmin === undefined) {
            throw new runtime.RequiredError('commentSearchAdmin','Required parameter requestParameters.commentSearchAdmin was null or undefined when calling commentSearchAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/commentsearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentSearchAdminToJSON(requestParameters.commentSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentSearchAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async commentSearchAdmin(requestParameters: CommentSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<CommentSearchAdminResponse> {
        const response = await this.commentSearchAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async commentSubscribeAdminRaw(requestParameters: CommentSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentSubscribeAdmin.');
        }

        if (requestParameters.subscriptionListenerComment === null || requestParameters.subscriptionListenerComment === undefined) {
            throw new runtime.RequiredError('subscriptionListenerComment','Required parameter requestParameters.subscriptionListenerComment was null or undefined when calling commentSubscribeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/subscription/comment`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionListenerCommentToJSON(requestParameters.subscriptionListenerComment),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async commentSubscribeAdmin(requestParameters: CommentSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.commentSubscribeAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async commentUnsubscribeAdminRaw(requestParameters: CommentUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling commentUnsubscribeAdmin.');
        }

        if (requestParameters.subscriptionListenerComment === null || requestParameters.subscriptionListenerComment === undefined) {
            throw new runtime.RequiredError('subscriptionListenerComment','Required parameter requestParameters.subscriptionListenerComment was null or undefined when calling commentUnsubscribeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/subscription/comment`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionListenerCommentToJSON(requestParameters.subscriptionListenerComment),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async commentUnsubscribeAdmin(requestParameters: CommentUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.commentUnsubscribeAdminRaw(requestParameters, headerExtra);
    }

}
