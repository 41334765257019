/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CreditsCreditOnSignup
 */
export interface CreditsCreditOnSignup {
    /**
     * 
     * @type {number}
     * @memberof CreditsCreditOnSignup
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreditsCreditOnSignup
     */
    summary?: string;
}

export function CreditsCreditOnSignupFromJSON(json: any): CreditsCreditOnSignup {
    return {
        'amount': json['amount'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function CreditsCreditOnSignupToJSON(value?: CreditsCreditOnSignup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'summary': value.summary,
    };
}


