/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    VoteOption,
    VoteOptionFromJSON,
    VoteOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaVoteGetOwnResponse
 */
export interface IdeaVoteGetOwnResponse {
    /**
     * Mapping ideaId to vote
     * @type {{ [key: string]: VoteOption; }}
     * @memberof IdeaVoteGetOwnResponse
     */
    votesByIdeaId: { [key: string]: VoteOption; };
    /**
     * Mapping ideaId to expression
     * @type {{ [key: string]: Array<string>; }}
     * @memberof IdeaVoteGetOwnResponse
     */
    expressionByIdeaId: { [key: string]: Array<string>; };
    /**
     * Mapping ideaId to fundAmount
     * @type {{ [key: string]: number; }}
     * @memberof IdeaVoteGetOwnResponse
     */
    fundAmountByIdeaId: { [key: string]: number; };
}

export function IdeaVoteGetOwnResponseFromJSON(json: any): IdeaVoteGetOwnResponse {
    return {
        
        'votesByIdeaId': Object.fromEntries(Object.entries(json['votesByIdeaId']).map(([k, v]) => [k, VoteOptionFromJSON(v)])),
        
        'expressionByIdeaId': json['expressionByIdeaId'],
        'fundAmountByIdeaId': json['fundAmountByIdeaId'],
    };
}

export function IdeaVoteGetOwnResponseToJSON(value?: IdeaVoteGetOwnResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'votesByIdeaId': Object.fromEntries(Object.entries(value.votesByIdeaId).map(([k, v]) => [k, VoteOptionToJSON(v)])),
        
        'expressionByIdeaId': value.expressionByIdeaId,
        'fundAmountByIdeaId': value.fundAmountByIdeaId,
    };
}


