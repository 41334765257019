/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HistogramResponse,
    HistogramResponseFromJSON,
    HistogramResponseToJSON,
    Idea,
    IdeaFromJSON,
    IdeaToJSON,
    IdeaAggregateResponse,
    IdeaAggregateResponseFromJSON,
    IdeaAggregateResponseToJSON,
    IdeaConnectResponse,
    IdeaConnectResponseFromJSON,
    IdeaConnectResponseToJSON,
    IdeaCreateAdmin,
    IdeaCreateAdminFromJSON,
    IdeaCreateAdminToJSON,
    IdeaDraftAdmin,
    IdeaDraftAdminFromJSON,
    IdeaDraftAdminToJSON,
    IdeaDraftSearch,
    IdeaDraftSearchFromJSON,
    IdeaDraftSearchToJSON,
    IdeaDraftSearchResponse,
    IdeaDraftSearchResponseFromJSON,
    IdeaDraftSearchResponseToJSON,
    IdeaHistogramSearchAdmin,
    IdeaHistogramSearchAdminFromJSON,
    IdeaHistogramSearchAdminToJSON,
    IdeaSearchAdmin,
    IdeaSearchAdminFromJSON,
    IdeaSearchAdminToJSON,
    IdeaSearchResponse,
    IdeaSearchResponseFromJSON,
    IdeaSearchResponseToJSON,
    IdeaUpdateAdmin,
    IdeaUpdateAdminFromJSON,
    IdeaUpdateAdminToJSON,
    IdeaVotersAdminResponse,
    IdeaVotersAdminResponseFromJSON,
    IdeaVotersAdminResponseToJSON,
    IdeaWithVote,
    IdeaWithVoteFromJSON,
    IdeaWithVoteToJSON,
    SubscriptionListenerIdea,
    SubscriptionListenerIdeaFromJSON,
    SubscriptionListenerIdeaToJSON,
} from '../models';

export interface IdeaCategoryAggregateAdminRequest {
    projectId: string;
    categoryId: string;
}

export interface IdeaCreateAdminRequest {
    projectId: string;
    ideaCreateAdmin: IdeaCreateAdmin;
    deleteDraftId?: string;
}

export interface IdeaDeleteAdminRequest {
    projectId: string;
    ideaId: string;
}

export interface IdeaDeleteBulkAdminRequest {
    projectId: string;
    ideaSearchAdmin: IdeaSearchAdmin;
}

export interface IdeaDraftCreateAdminRequest {
    projectId: string;
    ideaCreateAdmin: IdeaCreateAdmin;
}

export interface IdeaDraftDeleteAdminRequest {
    projectId: string;
    draftId: string;
}

export interface IdeaDraftGetAdminRequest {
    projectId: string;
    draftId: string;
}

export interface IdeaDraftSearchAdminRequest {
    projectId: string;
    ideaDraftSearch: IdeaDraftSearch;
    cursor?: string;
}

export interface IdeaDraftUpdateAdminRequest {
    projectId: string;
    draftId: string;
    ideaCreateAdmin: IdeaCreateAdmin;
}

export interface IdeaGetAdminRequest {
    projectId: string;
    ideaId: string;
}

export interface IdeaHistogramAdminRequest {
    projectId: string;
    ideaHistogramSearchAdmin: IdeaHistogramSearchAdmin;
}

export interface IdeaLinkAdminRequest {
    projectId: string;
    ideaId: string;
    parentIdeaId: string;
}

export interface IdeaMergeAdminRequest {
    projectId: string;
    ideaId: string;
    parentIdeaId: string;
}

export interface IdeaSearchAdminRequest {
    projectId: string;
    ideaSearchAdmin: IdeaSearchAdmin;
    cursor?: string;
}

export interface IdeaSubscribeAdminRequest {
    projectId: string;
    subscriptionListenerIdea: SubscriptionListenerIdea;
}

export interface IdeaUnLinkAdminRequest {
    projectId: string;
    ideaId: string;
    parentIdeaId: string;
}

export interface IdeaUnMergeAdminRequest {
    projectId: string;
    ideaId: string;
    parentIdeaId: string;
}

export interface IdeaUnsubscribeAdminRequest {
    projectId: string;
    subscriptionListenerIdea: SubscriptionListenerIdea;
}

export interface IdeaUpdateAdminRequest {
    projectId: string;
    ideaId: string;
    ideaUpdateAdmin: IdeaUpdateAdmin;
}

export interface IdeaVotersGetAdminRequest {
    projectId: string;
    ideaId: string;
    cursor?: string;
}

/**
 * no description
 */
export interface IdeaAdminApiInterface {

    /**
     */
    ideaCategoryAggregateAdmin(requestParameters: IdeaCategoryAggregateAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaAggregateResponse>;

    /**
     */
    ideaCreateAdmin(requestParameters: IdeaCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVote>;

    /**
     */
    ideaDeleteAdmin(requestParameters: IdeaDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaDeleteBulkAdmin(requestParameters: IdeaDeleteBulkAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaDraftCreateAdmin(requestParameters: IdeaDraftCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaDraftAdmin>;

    /**
     */
    ideaDraftDeleteAdmin(requestParameters: IdeaDraftDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaDraftGetAdmin(requestParameters: IdeaDraftGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaDraftAdmin>;

    /**
     */
    ideaDraftSearchAdmin(requestParameters: IdeaDraftSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaDraftSearchResponse>;

    /**
     */
    ideaDraftUpdateAdmin(requestParameters: IdeaDraftUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaGetAdmin(requestParameters: IdeaGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<Idea>;

    /**
     */
    ideaHistogramAdmin(requestParameters: IdeaHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<HistogramResponse>;

    /**
     */
    ideaLinkAdmin(requestParameters: IdeaLinkAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse>;

    /**
     */
    ideaMergeAdmin(requestParameters: IdeaMergeAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse>;

    /**
     */
    ideaSearchAdmin(requestParameters: IdeaSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaSearchResponse>;

    /**
     */
    ideaSubscribeAdmin(requestParameters: IdeaSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaUnLinkAdmin(requestParameters: IdeaUnLinkAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse>;

    /**
     */
    ideaUnMergeAdmin(requestParameters: IdeaUnMergeAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse>;

    /**
     */
    ideaUnsubscribeAdmin(requestParameters: IdeaUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaUpdateAdmin(requestParameters: IdeaUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<Idea>;

    /**
     */
    ideaVotersGetAdmin(requestParameters: IdeaVotersGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaVotersAdminResponse>;
}

/**
 * no description
 */
export class IdeaAdminApi extends runtime.BaseAPI implements IdeaAdminApiInterface {

    /**
     */
    async ideaCategoryAggregateAdminRaw(requestParameters: IdeaCategoryAggregateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaAggregateResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaCategoryAggregateAdmin.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling ideaCategoryAggregateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/category/{categoryId}/aggregate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaAggregateResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaCategoryAggregateAdmin(requestParameters: IdeaCategoryAggregateAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaAggregateResponse> {
        const response = await this.ideaCategoryAggregateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaCreateAdminRaw(requestParameters: IdeaCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaWithVote>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaCreateAdmin.');
        }

        if (requestParameters.ideaCreateAdmin === null || requestParameters.ideaCreateAdmin === undefined) {
            throw new runtime.RequiredError('ideaCreateAdmin','Required parameter requestParameters.ideaCreateAdmin was null or undefined when calling ideaCreateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleteDraftId !== undefined) {
            queryParameters['deleteDraftId'] = requestParameters.deleteDraftId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaCreateAdminToJSON(requestParameters.ideaCreateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaWithVoteFromJSON(jsonValue));
    }

    /**
     */
    async ideaCreateAdmin(requestParameters: IdeaCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVote> {
        const response = await this.ideaCreateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaDeleteAdminRaw(requestParameters: IdeaDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDeleteAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaDeleteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaDeleteAdmin(requestParameters: IdeaDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaDeleteAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaDeleteBulkAdminRaw(requestParameters: IdeaDeleteBulkAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDeleteBulkAdmin.');
        }

        if (requestParameters.ideaSearchAdmin === null || requestParameters.ideaSearchAdmin === undefined) {
            throw new runtime.RequiredError('ideaSearchAdmin','Required parameter requestParameters.ideaSearchAdmin was null or undefined when calling ideaDeleteBulkAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaSearchAdminToJSON(requestParameters.ideaSearchAdmin),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaDeleteBulkAdmin(requestParameters: IdeaDeleteBulkAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaDeleteBulkAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaDraftCreateAdminRaw(requestParameters: IdeaDraftCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaDraftAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDraftCreateAdmin.');
        }

        if (requestParameters.ideaCreateAdmin === null || requestParameters.ideaCreateAdmin === undefined) {
            throw new runtime.RequiredError('ideaCreateAdmin','Required parameter requestParameters.ideaCreateAdmin was null or undefined when calling ideaDraftCreateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/draft`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaCreateAdminToJSON(requestParameters.ideaCreateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaDraftAdminFromJSON(jsonValue));
    }

    /**
     */
    async ideaDraftCreateAdmin(requestParameters: IdeaDraftCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaDraftAdmin> {
        const response = await this.ideaDraftCreateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaDraftDeleteAdminRaw(requestParameters: IdeaDraftDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDraftDeleteAdmin.');
        }

        if (requestParameters.draftId === null || requestParameters.draftId === undefined) {
            throw new runtime.RequiredError('draftId','Required parameter requestParameters.draftId was null or undefined when calling ideaDraftDeleteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/draft/{draftId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"draftId"}}`, encodeURIComponent(String(requestParameters.draftId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaDraftDeleteAdmin(requestParameters: IdeaDraftDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaDraftDeleteAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaDraftGetAdminRaw(requestParameters: IdeaDraftGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaDraftAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDraftGetAdmin.');
        }

        if (requestParameters.draftId === null || requestParameters.draftId === undefined) {
            throw new runtime.RequiredError('draftId','Required parameter requestParameters.draftId was null or undefined when calling ideaDraftGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/draft/{draftId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"draftId"}}`, encodeURIComponent(String(requestParameters.draftId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaDraftAdminFromJSON(jsonValue));
    }

    /**
     */
    async ideaDraftGetAdmin(requestParameters: IdeaDraftGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaDraftAdmin> {
        const response = await this.ideaDraftGetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaDraftSearchAdminRaw(requestParameters: IdeaDraftSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaDraftSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDraftSearchAdmin.');
        }

        if (requestParameters.ideaDraftSearch === null || requestParameters.ideaDraftSearch === undefined) {
            throw new runtime.RequiredError('ideaDraftSearch','Required parameter requestParameters.ideaDraftSearch was null or undefined when calling ideaDraftSearchAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/draft/search`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaDraftSearchToJSON(requestParameters.ideaDraftSearch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaDraftSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaDraftSearchAdmin(requestParameters: IdeaDraftSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaDraftSearchResponse> {
        const response = await this.ideaDraftSearchAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaDraftUpdateAdminRaw(requestParameters: IdeaDraftUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDraftUpdateAdmin.');
        }

        if (requestParameters.draftId === null || requestParameters.draftId === undefined) {
            throw new runtime.RequiredError('draftId','Required parameter requestParameters.draftId was null or undefined when calling ideaDraftUpdateAdmin.');
        }

        if (requestParameters.ideaCreateAdmin === null || requestParameters.ideaCreateAdmin === undefined) {
            throw new runtime.RequiredError('ideaCreateAdmin','Required parameter requestParameters.ideaCreateAdmin was null or undefined when calling ideaDraftUpdateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/draft/{draftId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"draftId"}}`, encodeURIComponent(String(requestParameters.draftId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaCreateAdminToJSON(requestParameters.ideaCreateAdmin),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaDraftUpdateAdmin(requestParameters: IdeaDraftUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaDraftUpdateAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaGetAdminRaw(requestParameters: IdeaGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Idea>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaGetAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaFromJSON(jsonValue));
    }

    /**
     */
    async ideaGetAdmin(requestParameters: IdeaGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<Idea> {
        const response = await this.ideaGetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaHistogramAdminRaw(requestParameters: IdeaHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<HistogramResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaHistogramAdmin.');
        }

        if (requestParameters.ideaHistogramSearchAdmin === null || requestParameters.ideaHistogramSearchAdmin === undefined) {
            throw new runtime.RequiredError('ideaHistogramSearchAdmin','Required parameter requestParameters.ideaHistogramSearchAdmin was null or undefined when calling ideaHistogramAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/histogram`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaHistogramSearchAdminToJSON(requestParameters.ideaHistogramSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistogramResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaHistogramAdmin(requestParameters: IdeaHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<HistogramResponse> {
        const response = await this.ideaHistogramAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaLinkAdminRaw(requestParameters: IdeaLinkAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaConnectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaLinkAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaLinkAdmin.');
        }

        if (requestParameters.parentIdeaId === null || requestParameters.parentIdeaId === undefined) {
            throw new runtime.RequiredError('parentIdeaId','Required parameter requestParameters.parentIdeaId was null or undefined when calling ideaLinkAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}/link/{parentIdeaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"parentIdeaId"}}`, encodeURIComponent(String(requestParameters.parentIdeaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaConnectResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaLinkAdmin(requestParameters: IdeaLinkAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse> {
        const response = await this.ideaLinkAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaMergeAdminRaw(requestParameters: IdeaMergeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaConnectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaMergeAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaMergeAdmin.');
        }

        if (requestParameters.parentIdeaId === null || requestParameters.parentIdeaId === undefined) {
            throw new runtime.RequiredError('parentIdeaId','Required parameter requestParameters.parentIdeaId was null or undefined when calling ideaMergeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}/merge/{parentIdeaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"parentIdeaId"}}`, encodeURIComponent(String(requestParameters.parentIdeaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaConnectResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaMergeAdmin(requestParameters: IdeaMergeAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse> {
        const response = await this.ideaMergeAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaSearchAdminRaw(requestParameters: IdeaSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaSearchAdmin.');
        }

        if (requestParameters.ideaSearchAdmin === null || requestParameters.ideaSearchAdmin === undefined) {
            throw new runtime.RequiredError('ideaSearchAdmin','Required parameter requestParameters.ideaSearchAdmin was null or undefined when calling ideaSearchAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/search`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaSearchAdminToJSON(requestParameters.ideaSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaSearchAdmin(requestParameters: IdeaSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaSearchResponse> {
        const response = await this.ideaSearchAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaSubscribeAdminRaw(requestParameters: IdeaSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaSubscribeAdmin.');
        }

        if (requestParameters.subscriptionListenerIdea === null || requestParameters.subscriptionListenerIdea === undefined) {
            throw new runtime.RequiredError('subscriptionListenerIdea','Required parameter requestParameters.subscriptionListenerIdea was null or undefined when calling ideaSubscribeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/subscription/idea`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionListenerIdeaToJSON(requestParameters.subscriptionListenerIdea),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaSubscribeAdmin(requestParameters: IdeaSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaSubscribeAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaUnLinkAdminRaw(requestParameters: IdeaUnLinkAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaConnectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaUnLinkAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaUnLinkAdmin.');
        }

        if (requestParameters.parentIdeaId === null || requestParameters.parentIdeaId === undefined) {
            throw new runtime.RequiredError('parentIdeaId','Required parameter requestParameters.parentIdeaId was null or undefined when calling ideaUnLinkAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}/unlink/{parentIdeaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"parentIdeaId"}}`, encodeURIComponent(String(requestParameters.parentIdeaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaConnectResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaUnLinkAdmin(requestParameters: IdeaUnLinkAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse> {
        const response = await this.ideaUnLinkAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaUnMergeAdminRaw(requestParameters: IdeaUnMergeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaConnectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaUnMergeAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaUnMergeAdmin.');
        }

        if (requestParameters.parentIdeaId === null || requestParameters.parentIdeaId === undefined) {
            throw new runtime.RequiredError('parentIdeaId','Required parameter requestParameters.parentIdeaId was null or undefined when calling ideaUnMergeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}/unmerge/{parentIdeaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"parentIdeaId"}}`, encodeURIComponent(String(requestParameters.parentIdeaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaConnectResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaUnMergeAdmin(requestParameters: IdeaUnMergeAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse> {
        const response = await this.ideaUnMergeAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaUnsubscribeAdminRaw(requestParameters: IdeaUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaUnsubscribeAdmin.');
        }

        if (requestParameters.subscriptionListenerIdea === null || requestParameters.subscriptionListenerIdea === undefined) {
            throw new runtime.RequiredError('subscriptionListenerIdea','Required parameter requestParameters.subscriptionListenerIdea was null or undefined when calling ideaUnsubscribeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/subscription/idea`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionListenerIdeaToJSON(requestParameters.subscriptionListenerIdea),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaUnsubscribeAdmin(requestParameters: IdeaUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaUnsubscribeAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaUpdateAdminRaw(requestParameters: IdeaUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Idea>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaUpdateAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaUpdateAdmin.');
        }

        if (requestParameters.ideaUpdateAdmin === null || requestParameters.ideaUpdateAdmin === undefined) {
            throw new runtime.RequiredError('ideaUpdateAdmin','Required parameter requestParameters.ideaUpdateAdmin was null or undefined when calling ideaUpdateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaUpdateAdminToJSON(requestParameters.ideaUpdateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaFromJSON(jsonValue));
    }

    /**
     */
    async ideaUpdateAdmin(requestParameters: IdeaUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<Idea> {
        const response = await this.ideaUpdateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaVotersGetAdminRaw(requestParameters: IdeaVotersGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaVotersAdminResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaVotersGetAdmin.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaVotersGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/idea/{ideaId}/voters`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaVotersAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaVotersGetAdmin(requestParameters: IdeaVotersGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<IdeaVotersAdminResponse> {
        const response = await this.ideaVotersGetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
