/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ContentUploadResponse,
    ContentUploadResponseFromJSON,
    ContentUploadResponseToJSON,
} from '../models';

export interface ContentUploadRequest {
    projectId: string;
    body: Blob;
}

/**
 * no description
 */
export interface ContentApiInterface {

    /**
     */
    contentUpload(requestParameters: ContentUploadRequest, headerExtra?:{[key:string]:string}): Promise<ContentUploadResponse>;
}

/**
 * no description
 */
export class ContentApi extends runtime.BaseAPI implements ContentApiInterface {

    /**
     */
    async contentUploadRaw(requestParameters: ContentUploadRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ContentUploadResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling contentUpload.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling contentUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'image/_*';

        const response = await this.request({
            path: `/project/{projectId}/content/upload`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentUploadResponseFromJSON(jsonValue));
    }

    /**
     */
    async contentUpload(requestParameters: ContentUploadRequest, headerExtra?:{[key:string]:string}): Promise<ContentUploadResponse> {
        const response = await this.contentUploadRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
