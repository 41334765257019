/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface FeaturesTableFeatures
 */
export interface FeaturesTableFeatures {
    /**
     * 
     * @type {string}
     * @memberof FeaturesTableFeatures
     */
    feature: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeaturesTableFeatures
     */
    values: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FeaturesTableFeatures
     */
    terms?: string;
}

export function FeaturesTableFeaturesFromJSON(json: any): FeaturesTableFeatures {
    return {
        'feature': json['feature'],
        'values': json['values'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
    };
}

export function FeaturesTableFeaturesToJSON(value?: FeaturesTableFeatures): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'feature': value.feature,
        'values': value.values,
        'terms': value.terms,
    };
}


