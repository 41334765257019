/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    emailNotify?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    iosPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    androidPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    browserPushToken?: string;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'emailNotify': !exists(json, 'emailNotify') ? undefined : json['emailNotify'],
        'iosPushToken': !exists(json, 'iosPushToken') ? undefined : json['iosPushToken'],
        'androidPushToken': !exists(json, 'androidPushToken') ? undefined : json['androidPushToken'],
        'browserPushToken': !exists(json, 'browserPushToken') ? undefined : json['browserPushToken'],
    };
}

export function UserUpdateToJSON(value?: UserUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'password': value.password,
        'emailNotify': value.emailNotify,
        'iosPushToken': value.iosPushToken,
        'androidPushToken': value.androidPushToken,
        'browserPushToken': value.browserPushToken,
    };
}


