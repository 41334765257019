/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountSearchSuperAdmin
 */
export interface AccountSearchSuperAdmin {
    /**
     * Search by name and email.
     * @type {string}
     * @memberof AccountSearchSuperAdmin
     */
    searchText?: string;
    /**
     * Filter by statuses.
     * @type {Array<SubscriptionStatus>}
     * @memberof AccountSearchSuperAdmin
     */
    filterStatus?: Array<SubscriptionStatus>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSearchSuperAdmin
     */
    invertStatus?: boolean;
    /**
     * Filter by plan IDs.
     * @type {Array<string>}
     * @memberof AccountSearchSuperAdmin
     */
    filterPlanid?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSearchSuperAdmin
     */
    invertPlanid?: boolean;
}

export function AccountSearchSuperAdminFromJSON(json: any): AccountSearchSuperAdmin {
    return {
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        
        'filterStatus': !exists(json, 'filterStatus') ? undefined : (json['filterStatus'] as Array<any>).map(SubscriptionStatusFromJSON),
        
        'invertStatus': !exists(json, 'invertStatus') ? undefined : json['invertStatus'],
        'filterPlanid': !exists(json, 'filterPlanid') ? undefined : json['filterPlanid'],
        'invertPlanid': !exists(json, 'invertPlanid') ? undefined : json['invertPlanid'],
    };
}

export function AccountSearchSuperAdminToJSON(value?: AccountSearchSuperAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'searchText': value.searchText,
        
        'filterStatus': value.filterStatus === undefined ? undefined : (value.filterStatus as Array<any>).map(SubscriptionStatusToJSON),
        
        'invertStatus': value.invertStatus,
        'filterPlanid': value.filterPlanid,
        'invertPlanid': value.invertPlanid,
    };
}


