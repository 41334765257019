/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserMeWithBalance,
    UserMeWithBalanceFromJSON,
    UserMeWithBalanceToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserBindResponse
 */
export interface UserBindResponse {
    /**
     * 
     * @type {UserMeWithBalance}
     * @memberof UserBindResponse
     */
    user?: UserMeWithBalance;
}

export function UserBindResponseFromJSON(json: any): UserBindResponse {
    return {
        
        'user': !exists(json, 'user') ? undefined : UserMeWithBalanceFromJSON(json['user']),
    };
}

export function UserBindResponseToJSON(value?: UserBindResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'user': UserMeWithBalanceToJSON(value.user),
    };
}


