/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface EmailSignup
 */
export interface EmailSignup {
    /**
     * 
     * @type {string}
     * @memberof EmailSignup
     */
    mode: EmailSignupModeEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailSignup
     */
    password?: EmailSignupPasswordEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailSignup
     */
    verification: EmailSignupVerificationEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailSignup
     */
    allowedDomains?: Array<string>;
}

export function EmailSignupFromJSON(json: any): EmailSignup {
    return {
        'mode': json['mode'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'verification': json['verification'],
        'allowedDomains': !exists(json, 'allowedDomains') ? undefined : json['allowedDomains'],
    };
}

export function EmailSignupToJSON(value?: EmailSignup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'mode': value.mode,
        'password': value.password,
        'verification': value.verification,
        'allowedDomains': value.allowedDomains,
    };
}

/**
* @export
* @enum {string}
*/
export enum EmailSignupModeEnum {
    LoginOnly = 'LoginOnly',
    SignupAndLogin = 'SignupAndLogin'
}
/**
* @export
* @enum {string}
*/
export enum EmailSignupPasswordEnum {
    None = 'None',
    Optional = 'Optional',
    Required = 'Required'
}
/**
* @export
* @enum {string}
*/
export enum EmailSignupVerificationEnum {
    None = 'None',
    Required = 'Required'
}


