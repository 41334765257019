/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserAdmin,
    UserAdminFromJSON,
    UserAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaVotersAdminResponse
 */
export interface IdeaVotersAdminResponse {
    /**
     * Cursor to fetch next set of results.
     * @type {string}
     * @memberof IdeaVotersAdminResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<UserAdmin>}
     * @memberof IdeaVotersAdminResponse
     */
    results: Array<UserAdmin>;
}

export function IdeaVotersAdminResponseFromJSON(json: any): IdeaVotersAdminResponse {
    return {
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        
        'results': (json['results'] as Array<any>).map(UserAdminFromJSON),
        
    };
}

export function IdeaVotersAdminResponseToJSON(value?: IdeaVotersAdminResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cursor': value.cursor,
        
        'results': (value.results as Array<any>).map(UserAdminToJSON),
        
    };
}


