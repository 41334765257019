/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Balance,
    BalanceFromJSON,
    BalanceToJSON,
    Idea,
    IdeaFromJSON,
    IdeaToJSON,
    IdeaVote,
    IdeaVoteFromJSON,
    IdeaVoteToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaVoteUpdateResponse
 */
export interface IdeaVoteUpdateResponse {
    /**
     * 
     * @type {IdeaVote}
     * @memberof IdeaVoteUpdateResponse
     */
    vote: IdeaVote;
    /**
     * 
     * @type {Idea}
     * @memberof IdeaVoteUpdateResponse
     */
    idea: Idea;
    /**
     * 
     * @type {Balance}
     * @memberof IdeaVoteUpdateResponse
     */
    balance?: Balance;
    /**
     * 
     * @type {Transaction}
     * @memberof IdeaVoteUpdateResponse
     */
    transaction?: Transaction;
}

export function IdeaVoteUpdateResponseFromJSON(json: any): IdeaVoteUpdateResponse {
    return {
        
        'vote': IdeaVoteFromJSON(json['vote']),
        
        'idea': IdeaFromJSON(json['idea']),
        
        'balance': !exists(json, 'balance') ? undefined : BalanceFromJSON(json['balance']),
        
        'transaction': !exists(json, 'transaction') ? undefined : TransactionFromJSON(json['transaction']),
    };
}

export function IdeaVoteUpdateResponseToJSON(value?: IdeaVoteUpdateResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'vote': IdeaVoteToJSON(value.vote),
        
        'idea': IdeaToJSON(value.idea),
        
        'balance': BalanceToJSON(value.balance),
        
        'transaction': TransactionToJSON(value.transaction),
    };
}


