/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ContentUploadAsAdminResponse,
    ContentUploadAsAdminResponseFromJSON,
    ContentUploadAsAdminResponseToJSON,
} from '../models';

export interface ContentUploadAsAdminRequest {
    projectId: string;
    authorId: string;
    body: Blob;
}

/**
 * no description
 */
export interface ContentAdminApiInterface {

    /**
     */
    contentUploadAsAdmin(requestParameters: ContentUploadAsAdminRequest, headerExtra?:{[key:string]:string}): Promise<ContentUploadAsAdminResponse>;
}

/**
 * no description
 */
export class ContentAdminApi extends runtime.BaseAPI implements ContentAdminApiInterface {

    /**
     */
    async contentUploadAsAdminRaw(requestParameters: ContentUploadAsAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ContentUploadAsAdminResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling contentUploadAsAdmin.');
        }

        if (requestParameters.authorId === null || requestParameters.authorId === undefined) {
            throw new runtime.RequiredError('authorId','Required parameter requestParameters.authorId was null or undefined when calling contentUploadAsAdmin.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling contentUploadAsAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.authorId !== undefined) {
            queryParameters['authorId'] = requestParameters.authorId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'image/_*';

        const response = await this.request({
            path: `/project/{projectId}/admin/content/uploadAs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentUploadAsAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async contentUploadAsAdmin(requestParameters: ContentUploadAsAdminRequest, headerExtra?:{[key:string]:string}): Promise<ContentUploadAsAdminResponse> {
        const response = await this.contentUploadAsAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
