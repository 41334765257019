/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountUpdateAdminPaymentToken,
    AccountUpdateAdminPaymentTokenFromJSON,
    AccountUpdateAdminPaymentTokenToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountUpdateAdmin
 */
export interface AccountUpdateAdmin {
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdmin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdmin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdmin
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdmin
     */
    apiKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUpdateAdmin
     */
    renewAutomatically?: boolean;
    /**
     * 
     * @type {AccountUpdateAdminPaymentToken}
     * @memberof AccountUpdateAdmin
     */
    paymentToken?: AccountUpdateAdminPaymentToken;
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdmin
     */
    basePlanId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUpdateAdmin
     */
    cancelEndOfTerm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUpdateAdmin
     */
    resume?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountUpdateAdmin
     */
    addExtraTeammates?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountUpdateAdmin
     */
    attrs?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AccountUpdateAdmin
     */
    applyLicenseKey?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountUpdateAdmin
     */
    digestOptOutForProjectIds?: Array<string>;
}

export function AccountUpdateAdminFromJSON(json: any): AccountUpdateAdmin {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'renewAutomatically': !exists(json, 'renewAutomatically') ? undefined : json['renewAutomatically'],
        
        'paymentToken': !exists(json, 'paymentToken') ? undefined : AccountUpdateAdminPaymentTokenFromJSON(json['paymentToken']),
        'basePlanId': !exists(json, 'basePlanId') ? undefined : json['basePlanId'],
        'cancelEndOfTerm': !exists(json, 'cancelEndOfTerm') ? undefined : json['cancelEndOfTerm'],
        'resume': !exists(json, 'resume') ? undefined : json['resume'],
        'addExtraTeammates': !exists(json, 'addExtraTeammates') ? undefined : json['addExtraTeammates'],
        'attrs': !exists(json, 'attrs') ? undefined : json['attrs'],
        'applyLicenseKey': !exists(json, 'applyLicenseKey') ? undefined : json['applyLicenseKey'],
        'digestOptOutForProjectIds': !exists(json, 'digestOptOutForProjectIds') ? undefined : json['digestOptOutForProjectIds'],
    };
}

export function AccountUpdateAdminToJSON(value?: AccountUpdateAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'password': value.password,
        'apiKey': value.apiKey,
        'renewAutomatically': value.renewAutomatically,
        
        'paymentToken': AccountUpdateAdminPaymentTokenToJSON(value.paymentToken),
        'basePlanId': value.basePlanId,
        'cancelEndOfTerm': value.cancelEndOfTerm,
        'resume': value.resume,
        'addExtraTeammates': value.addExtraTeammates,
        'attrs': value.attrs,
        'applyLicenseKey': value.applyLicenseKey,
        'digestOptOutForProjectIds': value.digestOptOutForProjectIds,
    };
}


