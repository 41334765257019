/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HistogramResponse,
    HistogramResponseFromJSON,
    HistogramResponseToJSON,
    HistogramSearchAdmin,
    HistogramSearchAdminFromJSON,
    HistogramSearchAdminToJSON,
    SubscriptionListenerUser,
    SubscriptionListenerUserFromJSON,
    SubscriptionListenerUserToJSON,
    UserAdmin,
    UserAdminFromJSON,
    UserAdminToJSON,
    UserCreateAdmin,
    UserCreateAdminFromJSON,
    UserCreateAdminToJSON,
    UserSearchAdmin,
    UserSearchAdminFromJSON,
    UserSearchAdminToJSON,
    UserSearchResponse,
    UserSearchResponseFromJSON,
    UserSearchResponseToJSON,
    UserUpdateAdmin,
    UserUpdateAdminFromJSON,
    UserUpdateAdminToJSON,
} from '../models';

export interface UserCreateAdminRequest {
    projectId: string;
    userCreateAdmin: UserCreateAdmin;
}

export interface UserDeleteAdminRequest {
    projectId: string;
    userId: string;
}

export interface UserDeleteBulkAdminRequest {
    projectId: string;
    userSearchAdmin: UserSearchAdmin;
}

export interface UserGetAdminRequest {
    projectId: string;
    userId: string;
}

export interface UserHistogramAdminRequest {
    projectId: string;
    histogramSearchAdmin: HistogramSearchAdmin;
}

export interface UserSearchAdminRequest {
    projectId: string;
    userSearchAdmin: UserSearchAdmin;
    cursor?: string;
}

export interface UserSubscribeAdminRequest {
    projectId: string;
    subscriptionListenerUser: SubscriptionListenerUser;
}

export interface UserUnsubscribeAdminRequest {
    projectId: string;
    subscriptionListenerUser: SubscriptionListenerUser;
}

export interface UserUpdateAdminRequest {
    projectId: string;
    userId: string;
    userUpdateAdmin: UserUpdateAdmin;
}

/**
 * no description
 */
export interface UserAdminApiInterface {

    /**
     */
    userCreateAdmin(requestParameters: UserCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserAdmin>;

    /**
     */
    userDeleteAdmin(requestParameters: UserDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userDeleteBulkAdmin(requestParameters: UserDeleteBulkAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userGetAdmin(requestParameters: UserGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserAdmin>;

    /**
     */
    userHistogramAdmin(requestParameters: UserHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<HistogramResponse>;

    /**
     */
    userSearchAdmin(requestParameters: UserSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserSearchResponse>;

    /**
     */
    userSubscribeAdmin(requestParameters: UserSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userUnsubscribeAdmin(requestParameters: UserUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userUpdateAdmin(requestParameters: UserUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserAdmin>;
}

/**
 * no description
 */
export class UserAdminApi extends runtime.BaseAPI implements UserAdminApiInterface {

    /**
     */
    async userCreateAdminRaw(requestParameters: UserCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userCreateAdmin.');
        }

        if (requestParameters.userCreateAdmin === null || requestParameters.userCreateAdmin === undefined) {
            throw new runtime.RequiredError('userCreateAdmin','Required parameter requestParameters.userCreateAdmin was null or undefined when calling userCreateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/user`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateAdminToJSON(requestParameters.userCreateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAdminFromJSON(jsonValue));
    }

    /**
     */
    async userCreateAdmin(requestParameters: UserCreateAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserAdmin> {
        const response = await this.userCreateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userDeleteAdminRaw(requestParameters: UserDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userDeleteAdmin.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userDeleteAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/user/{userId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userDeleteAdmin(requestParameters: UserDeleteAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.userDeleteAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userDeleteBulkAdminRaw(requestParameters: UserDeleteBulkAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userDeleteBulkAdmin.');
        }

        if (requestParameters.userSearchAdmin === null || requestParameters.userSearchAdmin === undefined) {
            throw new runtime.RequiredError('userSearchAdmin','Required parameter requestParameters.userSearchAdmin was null or undefined when calling userDeleteBulkAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/user`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UserSearchAdminToJSON(requestParameters.userSearchAdmin),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userDeleteBulkAdmin(requestParameters: UserDeleteBulkAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.userDeleteBulkAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userGetAdminRaw(requestParameters: UserGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userGetAdmin.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/user/{userId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAdminFromJSON(jsonValue));
    }

    /**
     */
    async userGetAdmin(requestParameters: UserGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserAdmin> {
        const response = await this.userGetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userHistogramAdminRaw(requestParameters: UserHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<HistogramResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userHistogramAdmin.');
        }

        if (requestParameters.histogramSearchAdmin === null || requestParameters.histogramSearchAdmin === undefined) {
            throw new runtime.RequiredError('histogramSearchAdmin','Required parameter requestParameters.histogramSearchAdmin was null or undefined when calling userHistogramAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/user/histogram`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistogramSearchAdminToJSON(requestParameters.histogramSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistogramResponseFromJSON(jsonValue));
    }

    /**
     */
    async userHistogramAdmin(requestParameters: UserHistogramAdminRequest, headerExtra?:{[key:string]:string}): Promise<HistogramResponse> {
        const response = await this.userHistogramAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userSearchAdminRaw(requestParameters: UserSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userSearchAdmin.');
        }

        if (requestParameters.userSearchAdmin === null || requestParameters.userSearchAdmin === undefined) {
            throw new runtime.RequiredError('userSearchAdmin','Required parameter requestParameters.userSearchAdmin was null or undefined when calling userSearchAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/usersearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSearchAdminToJSON(requestParameters.userSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async userSearchAdmin(requestParameters: UserSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserSearchResponse> {
        const response = await this.userSearchAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userSubscribeAdminRaw(requestParameters: UserSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userSubscribeAdmin.');
        }

        if (requestParameters.subscriptionListenerUser === null || requestParameters.subscriptionListenerUser === undefined) {
            throw new runtime.RequiredError('subscriptionListenerUser','Required parameter requestParameters.subscriptionListenerUser was null or undefined when calling userSubscribeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/subscription/user`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionListenerUserToJSON(requestParameters.subscriptionListenerUser),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userSubscribeAdmin(requestParameters: UserSubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.userSubscribeAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userUnsubscribeAdminRaw(requestParameters: UserUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userUnsubscribeAdmin.');
        }

        if (requestParameters.subscriptionListenerUser === null || requestParameters.subscriptionListenerUser === undefined) {
            throw new runtime.RequiredError('subscriptionListenerUser','Required parameter requestParameters.subscriptionListenerUser was null or undefined when calling userUnsubscribeAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/subscription/user`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionListenerUserToJSON(requestParameters.subscriptionListenerUser),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userUnsubscribeAdmin(requestParameters: UserUnsubscribeAdminRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.userUnsubscribeAdminRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userUpdateAdminRaw(requestParameters: UserUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserAdmin>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userUpdateAdmin.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userUpdateAdmin.');
        }

        if (requestParameters.userUpdateAdmin === null || requestParameters.userUpdateAdmin === undefined) {
            throw new runtime.RequiredError('userUpdateAdmin','Required parameter requestParameters.userUpdateAdmin was null or undefined when calling userUpdateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/user/{userId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateAdminToJSON(requestParameters.userUpdateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAdminFromJSON(jsonValue));
    }

    /**
     */
    async userUpdateAdmin(requestParameters: UserUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<UserAdmin> {
        const response = await this.userUpdateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
