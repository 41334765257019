/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ConvoMessageCreate
 */
export interface ConvoMessageCreate {
    /**
     * 
     * @type {string}
     * @memberof ConvoMessageCreate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ConvoMessageCreate
     */
    overridePrompt?: string;
}

export function ConvoMessageCreateFromJSON(json: any): ConvoMessageCreate {
    return {
        'content': json['content'],
        'overridePrompt': !exists(json, 'overridePrompt') ? undefined : json['overridePrompt'],
    };
}

export function ConvoMessageCreateToJSON(value?: ConvoMessageCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
        'overridePrompt': value.overridePrompt,
    };
}


