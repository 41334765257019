/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
/**
 * 
 * @export
 * @enum {string}
 */
export enum VoteOption {
    Upvote = 'upvote',
    Downvote = 'downvote',
    None = 'none'
}

export function VoteOptionFromJSON(json: any): VoteOption {
    return json as VoteOption;
}

export function VoteOptionToJSON(value?: VoteOption): any {
    return value as any;
}

