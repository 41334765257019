/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountAcceptInvitationResponse,
    AccountAcceptInvitationResponseFromJSON,
    AccountAcceptInvitationResponseToJSON,
    AccountAdmin,
    AccountAdminFromJSON,
    AccountAdminToJSON,
    AccountAttrsUpdateAdmin,
    AccountAttrsUpdateAdminFromJSON,
    AccountAttrsUpdateAdminToJSON,
    AccountBilling,
    AccountBillingFromJSON,
    AccountBillingToJSON,
    AccountBindAdmin,
    AccountBindAdminFromJSON,
    AccountBindAdminToJSON,
    AccountBindAdminResponse,
    AccountBindAdminResponseFromJSON,
    AccountBindAdminResponseToJSON,
    AccountLogin,
    AccountLoginFromJSON,
    AccountLoginToJSON,
    AccountSignupAdmin,
    AccountSignupAdminFromJSON,
    AccountSignupAdminToJSON,
    AccountUpdateAdmin,
    AccountUpdateAdminFromJSON,
    AccountUpdateAdminToJSON,
    AvailableRepos,
    AvailableReposFromJSON,
    AvailableReposToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InvitationResult,
    InvitationResultFromJSON,
    InvitationResultToJSON,
    InvoiceHtmlResponse,
    InvoiceHtmlResponseFromJSON,
    InvoiceHtmlResponseToJSON,
    Invoices,
    InvoicesFromJSON,
    InvoicesToJSON,
    LegalResponse,
    LegalResponseFromJSON,
    LegalResponseToJSON,
    ViewCouponResponse,
    ViewCouponResponseFromJSON,
    ViewCouponResponseToJSON,
} from '../models';

export interface AccountAcceptCouponAdminRequest {
    couponId: string;
}

export interface AccountAcceptInvitationAdminRequest {
    invitationId: string;
}

export interface AccountAttrsUpdateAdminRequest {
    accountAttrsUpdateAdmin: AccountAttrsUpdateAdmin;
}

export interface AccountBillingAdminRequest {
    refreshPayments?: boolean;
}

export interface AccountBindAdminRequest {
    accountBindAdmin: AccountBindAdmin;
}

export interface AccountLoginAdminRequest {
    accountLogin: AccountLogin;
}

export interface AccountSignupAdminRequest {
    accountSignupAdmin: AccountSignupAdmin;
}

export interface AccountUpdateAdminRequest {
    accountUpdateAdmin: AccountUpdateAdmin;
}

export interface AccountViewCouponAdminRequest {
    couponId: string;
}

export interface AccountViewInvitationAdminRequest {
    invitationId: string;
}

export interface GitHubGetReposAdminRequest {
    code: string;
}

export interface InvoiceHtmlGetAdminRequest {
    invoiceId: string;
}

export interface InvoicesSearchAdminRequest {
    cursor?: string;
}

/**
 * no description
 */
export interface AccountAdminApiInterface {

    /**
     */
    accountAcceptCouponAdmin(requestParameters: AccountAcceptCouponAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    accountAcceptInvitationAdmin(requestParameters: AccountAcceptInvitationAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAcceptInvitationResponse>;

    /**
     */
    accountAttrsUpdateAdmin(requestParameters: AccountAttrsUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    accountBillingAdmin(requestParameters: AccountBillingAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountBilling>;

    /**
     */
    accountBindAdmin(requestParameters: AccountBindAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountBindAdminResponse>;

    /**
     */
    accountDeleteAdmin(headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    accountLoginAdmin(requestParameters: AccountLoginAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    accountLogoutAdmin(headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     * Used for checking whether you are signed in
     */
    accountNoopAdmin(headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    accountSignupAdmin(requestParameters: AccountSignupAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    accountUpdateAdmin(requestParameters: AccountUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin>;

    /**
     */
    accountViewCouponAdmin(requestParameters: AccountViewCouponAdminRequest, headerExtra?:{[key:string]:string}): Promise<ViewCouponResponse>;

    /**
     */
    accountViewInvitationAdmin(requestParameters: AccountViewInvitationAdminRequest, headerExtra?:{[key:string]:string}): Promise<InvitationResult>;

    /**
     */
    gitHubGetReposAdmin(requestParameters: GitHubGetReposAdminRequest, headerExtra?:{[key:string]:string}): Promise<AvailableRepos>;

    /**
     */
    invoiceHtmlGetAdmin(requestParameters: InvoiceHtmlGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<InvoiceHtmlResponse>;

    /**
     */
    invoicesSearchAdmin(requestParameters: InvoicesSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<Invoices>;

    /**
     */
    legalGet(headerExtra?:{[key:string]:string}): Promise<LegalResponse>;
}

/**
 * no description
 */
export class AccountAdminApi extends runtime.BaseAPI implements AccountAdminApiInterface {

    /**
     */
    async accountAcceptCouponAdminRaw(requestParameters: AccountAcceptCouponAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling accountAcceptCouponAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.couponId !== undefined) {
            queryParameters['couponId'] = requestParameters.couponId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/coupon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountAcceptCouponAdmin(requestParameters: AccountAcceptCouponAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountAcceptCouponAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountAcceptInvitationAdminRaw(requestParameters: AccountAcceptInvitationAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAcceptInvitationResponse>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling accountAcceptInvitationAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.invitationId !== undefined) {
            queryParameters['invitationId'] = requestParameters.invitationId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAcceptInvitationResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountAcceptInvitationAdmin(requestParameters: AccountAcceptInvitationAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAcceptInvitationResponse> {
        const response = await this.accountAcceptInvitationAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountAttrsUpdateAdminRaw(requestParameters: AccountAttrsUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.accountAttrsUpdateAdmin === null || requestParameters.accountAttrsUpdateAdmin === undefined) {
            throw new runtime.RequiredError('accountAttrsUpdateAdmin','Required parameter requestParameters.accountAttrsUpdateAdmin was null or undefined when calling accountAttrsUpdateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/account/update/attrs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountAttrsUpdateAdminToJSON(requestParameters.accountAttrsUpdateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountAttrsUpdateAdmin(requestParameters: AccountAttrsUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountAttrsUpdateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountBillingAdminRaw(requestParameters: AccountBillingAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountBilling>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.refreshPayments !== undefined) {
            queryParameters['refreshPayments'] = requestParameters.refreshPayments;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/billing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountBillingFromJSON(jsonValue));
    }

    /**
     */
    async accountBillingAdmin(requestParameters: AccountBillingAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountBilling> {
        const response = await this.accountBillingAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountBindAdminRaw(requestParameters: AccountBindAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountBindAdminResponse>> {
        if (requestParameters.accountBindAdmin === null || requestParameters.accountBindAdmin === undefined) {
            throw new runtime.RequiredError('accountBindAdmin','Required parameter requestParameters.accountBindAdmin was null or undefined when calling accountBindAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/account/bind`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountBindAdminToJSON(requestParameters.accountBindAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountBindAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountBindAdmin(requestParameters: AccountBindAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountBindAdminResponse> {
        const response = await this.accountBindAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountDeleteAdminRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountDeleteAdmin(headerExtra?:{[key:string]:string}): Promise<void> {
        await this.accountDeleteAdminRaw(headerExtra);
    }

    /**
     */
    async accountLoginAdminRaw(requestParameters: AccountLoginAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.accountLogin === null || requestParameters.accountLogin === undefined) {
            throw new runtime.RequiredError('accountLogin','Required parameter requestParameters.accountLogin was null or undefined when calling accountLoginAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/account/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountLoginToJSON(requestParameters.accountLogin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountLoginAdmin(requestParameters: AccountLoginAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountLoginAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountLogoutAdminRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountLogoutAdmin(headerExtra?:{[key:string]:string}): Promise<void> {
        await this.accountLogoutAdminRaw(headerExtra);
    }

    /**
     * Used for checking whether you are signed in
     */
    async accountNoopAdminRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/noop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used for checking whether you are signed in
     */
    async accountNoopAdmin(headerExtra?:{[key:string]:string}): Promise<void> {
        await this.accountNoopAdminRaw(headerExtra);
    }

    /**
     */
    async accountSignupAdminRaw(requestParameters: AccountSignupAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.accountSignupAdmin === null || requestParameters.accountSignupAdmin === undefined) {
            throw new runtime.RequiredError('accountSignupAdmin','Required parameter requestParameters.accountSignupAdmin was null or undefined when calling accountSignupAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/account/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountSignupAdminToJSON(requestParameters.accountSignupAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountSignupAdmin(requestParameters: AccountSignupAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountSignupAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountUpdateAdminRaw(requestParameters: AccountUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AccountAdmin>> {
        if (requestParameters.accountUpdateAdmin === null || requestParameters.accountUpdateAdmin === undefined) {
            throw new runtime.RequiredError('accountUpdateAdmin','Required parameter requestParameters.accountUpdateAdmin was null or undefined when calling accountUpdateAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/account/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountUpdateAdminToJSON(requestParameters.accountUpdateAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountAdminFromJSON(jsonValue));
    }

    /**
     */
    async accountUpdateAdmin(requestParameters: AccountUpdateAdminRequest, headerExtra?:{[key:string]:string}): Promise<AccountAdmin> {
        const response = await this.accountUpdateAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountViewCouponAdminRaw(requestParameters: AccountViewCouponAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ViewCouponResponse>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling accountViewCouponAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.couponId !== undefined) {
            queryParameters['couponId'] = requestParameters.couponId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/coupon`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewCouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountViewCouponAdmin(requestParameters: AccountViewCouponAdminRequest, headerExtra?:{[key:string]:string}): Promise<ViewCouponResponse> {
        const response = await this.accountViewCouponAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async accountViewInvitationAdminRaw(requestParameters: AccountViewInvitationAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<InvitationResult>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling accountViewInvitationAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.invitationId !== undefined) {
            queryParameters['invitationId'] = requestParameters.invitationId;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/invitation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationResultFromJSON(jsonValue));
    }

    /**
     */
    async accountViewInvitationAdmin(requestParameters: AccountViewInvitationAdminRequest, headerExtra?:{[key:string]:string}): Promise<InvitationResult> {
        const response = await this.accountViewInvitationAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async gitHubGetReposAdminRaw(requestParameters: GitHubGetReposAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<AvailableRepos>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling gitHubGetReposAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/github/repos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableReposFromJSON(jsonValue));
    }

    /**
     */
    async gitHubGetReposAdmin(requestParameters: GitHubGetReposAdminRequest, headerExtra?:{[key:string]:string}): Promise<AvailableRepos> {
        const response = await this.gitHubGetReposAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async invoiceHtmlGetAdminRaw(requestParameters: InvoiceHtmlGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<InvoiceHtmlResponse>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling invoiceHtmlGetAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/billing/invoice/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceHtmlResponseFromJSON(jsonValue));
    }

    /**
     */
    async invoiceHtmlGetAdmin(requestParameters: InvoiceHtmlGetAdminRequest, headerExtra?:{[key:string]:string}): Promise<InvoiceHtmlResponse> {
        const response = await this.invoiceHtmlGetAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async invoicesSearchAdminRaw(requestParameters: InvoicesSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Invoices>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/account/billing/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesFromJSON(jsonValue));
    }

    /**
     */
    async invoicesSearchAdmin(requestParameters: InvoicesSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<Invoices> {
        const response = await this.invoicesSearchAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async legalGetRaw(headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<LegalResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/admin/legal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LegalResponseFromJSON(jsonValue));
    }

    /**
     */
    async legalGet(headerExtra?:{[key:string]:string}): Promise<LegalResponse> {
        const response = await this.legalGetRaw(headerExtra);
        return await response.value();
    }

}
