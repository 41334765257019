/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ErrorResponse2,
    ErrorResponse2FromJSON,
    ErrorResponse2ToJSON,
    ForgotPassword,
    ForgotPasswordFromJSON,
    ForgotPasswordToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserBind,
    UserBindFromJSON,
    UserBindToJSON,
    UserBindResponse,
    UserBindResponseFromJSON,
    UserBindResponseToJSON,
    UserCreate,
    UserCreateFromJSON,
    UserCreateToJSON,
    UserCreateResponse,
    UserCreateResponseFromJSON,
    UserCreateResponseToJSON,
    UserLogin,
    UserLoginFromJSON,
    UserLoginToJSON,
    UserMe,
    UserMeFromJSON,
    UserMeToJSON,
    UserMeWithBalance,
    UserMeWithBalanceFromJSON,
    UserMeWithBalanceToJSON,
    UserUpdate,
    UserUpdateFromJSON,
    UserUpdateToJSON,
} from '../models';

export interface ForgotPasswordRequest {
    projectId: string;
    forgotPassword: ForgotPassword;
}

export interface UserBindRequest {
    projectId: string;
    userBind: UserBind;
}

export interface UserCreateRequest {
    projectId: string;
    userCreate: UserCreate;
}

export interface UserDeleteRequest {
    projectId: string;
    userId: string;
}

export interface UserGetRequest {
    projectId: string;
    userId: string;
}

export interface UserLoginRequest {
    projectId: string;
    userLogin: UserLogin;
}

export interface UserLogoutRequest {
    projectId: string;
}

export interface UserUpdateRequest {
    projectId: string;
    userId: string;
    userUpdate: UserUpdate;
}

/**
 * no description
 */
export interface UserApiInterface {

    /**
     */
    forgotPassword(requestParameters: ForgotPasswordRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userBind(requestParameters: UserBindRequest, headerExtra?:{[key:string]:string}): Promise<UserBindResponse>;

    /**
     */
    userCreate(requestParameters: UserCreateRequest, headerExtra?:{[key:string]:string}): Promise<UserCreateResponse>;

    /**
     */
    userDelete(requestParameters: UserDeleteRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userGet(requestParameters: UserGetRequest, headerExtra?:{[key:string]:string}): Promise<User>;

    /**
     */
    userLogin(requestParameters: UserLoginRequest, headerExtra?:{[key:string]:string}): Promise<UserMeWithBalance>;

    /**
     */
    userLogout(requestParameters: UserLogoutRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    userUpdate(requestParameters: UserUpdateRequest, headerExtra?:{[key:string]:string}): Promise<UserMe>;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling forgotPassword.');
        }

        if (requestParameters.forgotPassword === null || requestParameters.forgotPassword === undefined) {
            throw new runtime.RequiredError('forgotPassword','Required parameter requestParameters.forgotPassword was null or undefined when calling forgotPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/forgot`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordToJSON(requestParameters.forgotPassword),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forgotPassword(requestParameters: ForgotPasswordRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.forgotPasswordRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userBindRaw(requestParameters: UserBindRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserBindResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userBind.');
        }

        if (requestParameters.userBind === null || requestParameters.userBind === undefined) {
            throw new runtime.RequiredError('userBind','Required parameter requestParameters.userBind was null or undefined when calling userBind.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/bind`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserBindToJSON(requestParameters.userBind),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBindResponseFromJSON(jsonValue));
    }

    /**
     */
    async userBind(requestParameters: UserBindRequest, headerExtra?:{[key:string]:string}): Promise<UserBindResponse> {
        const response = await this.userBindRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userCreateRaw(requestParameters: UserCreateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserCreateResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userCreate.');
        }

        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling userCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/user`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters.userCreate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async userCreate(requestParameters: UserCreateRequest, headerExtra?:{[key:string]:string}): Promise<UserCreateResponse> {
        const response = await this.userCreateRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userDeleteRaw(requestParameters: UserDeleteRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userDelete.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/user/{userId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userDelete(requestParameters: UserDeleteRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.userDeleteRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userGetRaw(requestParameters: UserGetRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userGet.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/user/{userId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async userGet(requestParameters: UserGetRequest, headerExtra?:{[key:string]:string}): Promise<User> {
        const response = await this.userGetRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userLoginRaw(requestParameters: UserLoginRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserMeWithBalance>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userLogin.');
        }

        if (requestParameters.userLogin === null || requestParameters.userLogin === undefined) {
            throw new runtime.RequiredError('userLogin','Required parameter requestParameters.userLogin was null or undefined when calling userLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/login`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginToJSON(requestParameters.userLogin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMeWithBalanceFromJSON(jsonValue));
    }

    /**
     */
    async userLogin(requestParameters: UserLoginRequest, headerExtra?:{[key:string]:string}): Promise<UserMeWithBalance> {
        const response = await this.userLoginRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async userLogoutRaw(requestParameters: UserLogoutRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userLogout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/logout`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userLogout(requestParameters: UserLogoutRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.userLogoutRaw(requestParameters, headerExtra);
    }

    /**
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<UserMe>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling userUpdate.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userUpdate.');
        }

        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling userUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/user/{userId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMeFromJSON(jsonValue));
    }

    /**
     */
    async userUpdate(requestParameters: UserUpdateRequest, headerExtra?:{[key:string]:string}): Promise<UserMe> {
        const response = await this.userUpdateRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
