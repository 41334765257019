/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PlanPricingAdmins,
    PlanPricingAdminsFromJSON,
    PlanPricingAdminsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PlanPricing
 */
export interface PlanPricing {
    /**
     * 
     * @type {number}
     * @memberof PlanPricing
     */
    basePrice: number;
    /**
     * 
     * @type {number}
     * @memberof PlanPricing
     */
    baseMau: number;
    /**
     * 
     * @type {number}
     * @memberof PlanPricing
     */
    unitMau: number;
    /**
     * 
     * @type {number}
     * @memberof PlanPricing
     */
    unitPrice: number;
    /**
     * 
     * @type {PlanPricingAdmins}
     * @memberof PlanPricing
     */
    admins?: PlanPricingAdmins;
    /**
     * 
     * @type {string}
     * @memberof PlanPricing
     */
    period: PlanPricingPeriodEnum;
}

export function PlanPricingFromJSON(json: any): PlanPricing {
    return {
        'basePrice': json['basePrice'],
        'baseMau': json['baseMau'],
        'unitMau': json['unitMau'],
        'unitPrice': json['unitPrice'],
        
        'admins': !exists(json, 'admins') ? undefined : PlanPricingAdminsFromJSON(json['admins']),
        'period': json['period'],
    };
}

export function PlanPricingToJSON(value?: PlanPricing): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'basePrice': value.basePrice,
        'baseMau': value.baseMau,
        'unitMau': value.unitMau,
        'unitPrice': value.unitPrice,
        
        'admins': PlanPricingAdminsToJSON(value.admins),
        'period': value.period,
    };
}

/**
* @export
* @enum {string}
*/
export enum PlanPricingPeriodEnum {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Yearly = 'Yearly',
    Lifetime = 'Lifetime'
}


