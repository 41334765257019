/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    IdeaSearch,
    IdeaSearchFromJSON,
    IdeaSearchToJSON,
    PageExplorerAllOf,
    PageExplorerAllOfFromJSON,
    PageExplorerAllOfToJSON,
    PageExplorerAllOfAllowCreate,
    PageExplorerAllOfAllowCreateFromJSON,
    PageExplorerAllOfAllowCreateToJSON,
    PageExplorerAllOfAllowSearch,
    PageExplorerAllOfAllowSearchFromJSON,
    PageExplorerAllOfAllowSearchToJSON,
    PagePanel,
    PagePanelFromJSON,
    PagePanelToJSON,
    PostDisplay,
    PostDisplayFromJSON,
    PostDisplayToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageExplorer
 */
export interface PageExplorer {
    /**
     * 
     * @type {IdeaSearch}
     * @memberof PageExplorer
     */
    search: IdeaSearch;
    /**
     * 
     * @type {PostDisplay}
     * @memberof PageExplorer
     */
    display: PostDisplay;
    /**
     * 
     * @type {PageExplorerAllOfAllowSearch}
     * @memberof PageExplorer
     */
    allowSearch?: PageExplorerAllOfAllowSearch;
    /**
     * 
     * @type {PageExplorerAllOfAllowCreate}
     * @memberof PageExplorer
     */
    allowCreate?: PageExplorerAllOfAllowCreate;
}

export function PageExplorerFromJSON(json: any): PageExplorer {
    return {
        
        'search': IdeaSearchFromJSON(json['search']),
        
        'display': PostDisplayFromJSON(json['display']),
        
        'allowSearch': !exists(json, 'allowSearch') ? undefined : PageExplorerAllOfAllowSearchFromJSON(json['allowSearch']),
        
        'allowCreate': !exists(json, 'allowCreate') ? undefined : PageExplorerAllOfAllowCreateFromJSON(json['allowCreate']),
    };
}

export function PageExplorerToJSON(value?: PageExplorer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'search': IdeaSearchToJSON(value.search),
        
        'display': PostDisplayToJSON(value.display),
        
        'allowSearch': PageExplorerAllOfAllowSearchToJSON(value.allowSearch),
        
        'allowCreate': PageExplorerAllOfAllowCreateToJSON(value.allowCreate),
    };
}


