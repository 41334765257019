/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionCreateAdmin
 */
export interface TransactionCreateAdmin {
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateAdmin
     */
    amount: number;
    /**
     * Optional user facing explanation of the transaction.
     * @type {string}
     * @memberof TransactionCreateAdmin
     */
    summary?: string;
}

export function TransactionCreateAdminFromJSON(json: any): TransactionCreateAdmin {
    return {
        'amount': json['amount'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function TransactionCreateAdminToJSON(value?: TransactionCreateAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'summary': value.summary,
    };
}


