/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface InvitationAdmin
 */
export interface InvitationAdmin {
    /**
     * 
     * @type {string}
     * @memberof InvitationAdmin
     */
    invitationId: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationAdmin
     */
    email: string;
}

export function InvitationAdminFromJSON(json: any): InvitationAdmin {
    return {
        'invitationId': json['invitationId'],
        'email': json['email'],
    };
}

export function InvitationAdminToJSON(value?: InvitationAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'invitationId': value.invitationId,
        'email': value.email,
    };
}


