/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountFields
 */
export interface AccountFields {
    /**
     * 
     * @type {string}
     * @memberof AccountFields
     */
    displayName: AccountFieldsDisplayNameEnum;
}

export function AccountFieldsFromJSON(json: any): AccountFields {
    return {
        'displayName': json['displayName'],
    };
}

export function AccountFieldsToJSON(value?: AccountFields): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'displayName': value.displayName,
    };
}

/**
* @export
* @enum {string}
*/
export enum AccountFieldsDisplayNameEnum {
    None = 'None',
    Optional = 'Optional',
    Required = 'Required'
}


