/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CommentSearchAllOf,
    CommentSearchAllOfFromJSON,
    CommentSearchAllOfToJSON,
    CommentSearchBase,
    CommentSearchBaseFromJSON,
    CommentSearchBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommentSearch
 */
export interface CommentSearch {
    /**
     * 
     * @type {number}
     * @memberof CommentSearch
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentSearch
     */
    filterAuthorId: string;
}

export function CommentSearchFromJSON(json: any): CommentSearch {
    return {
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'filterAuthorId': json['filterAuthorId'],
    };
}

export function CommentSearchToJSON(value?: CommentSearch): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'limit': value.limit,
        'filterAuthorId': value.filterAuthorId,
    };
}


