/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountUpdateSuperAdmin
 */
export interface AccountUpdateSuperAdmin {
    /**
     * 
     * @type {number}
     * @memberof AccountUpdateSuperAdmin
     */
    changeToFlatPlanWithYearlyPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUpdateSuperAdmin
     */
    changeToSponsorPlanWithMonthlyPrice?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountUpdateSuperAdmin
     */
    addons?: { [key: string]: string; };
}

export function AccountUpdateSuperAdminFromJSON(json: any): AccountUpdateSuperAdmin {
    return {
        'changeToFlatPlanWithYearlyPrice': !exists(json, 'changeToFlatPlanWithYearlyPrice') ? undefined : json['changeToFlatPlanWithYearlyPrice'],
        'changeToSponsorPlanWithMonthlyPrice': !exists(json, 'changeToSponsorPlanWithMonthlyPrice') ? undefined : json['changeToSponsorPlanWithMonthlyPrice'],
        'addons': !exists(json, 'addons') ? undefined : json['addons'],
    };
}

export function AccountUpdateSuperAdminToJSON(value?: AccountUpdateSuperAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'changeToFlatPlanWithYearlyPrice': value.changeToFlatPlanWithYearlyPrice,
        'changeToSponsorPlanWithMonthlyPrice': value.changeToSponsorPlanWithMonthlyPrice,
        'addons': value.addons,
    };
}


