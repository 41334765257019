/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface PostDisplay
 */
export interface PostDisplay {
    /**
     * 
     * @type {number}
     * @memberof PostDisplay
     */
    titleTruncateLines?: number;
    /**
     * 
     * @type {number}
     * @memberof PostDisplay
     */
    descriptionTruncateLines?: number;
    /**
     * 
     * @type {number}
     * @memberof PostDisplay
     */
    responseTruncateLines?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showCommentCount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showCategoryName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showCreated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showAuthor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showTags?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showVoting?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showVotingCount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showFunding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showExpression?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDisplay
     */
    showEdit?: boolean;
}

export function PostDisplayFromJSON(json: any): PostDisplay {
    return {
        'titleTruncateLines': !exists(json, 'titleTruncateLines') ? undefined : json['titleTruncateLines'],
        'descriptionTruncateLines': !exists(json, 'descriptionTruncateLines') ? undefined : json['descriptionTruncateLines'],
        'responseTruncateLines': !exists(json, 'responseTruncateLines') ? undefined : json['responseTruncateLines'],
        'showCommentCount': !exists(json, 'showCommentCount') ? undefined : json['showCommentCount'],
        'showCategoryName': !exists(json, 'showCategoryName') ? undefined : json['showCategoryName'],
        'showCreated': !exists(json, 'showCreated') ? undefined : json['showCreated'],
        'showAuthor': !exists(json, 'showAuthor') ? undefined : json['showAuthor'],
        'showStatus': !exists(json, 'showStatus') ? undefined : json['showStatus'],
        'showTags': !exists(json, 'showTags') ? undefined : json['showTags'],
        'showVoting': !exists(json, 'showVoting') ? undefined : json['showVoting'],
        'showVotingCount': !exists(json, 'showVotingCount') ? undefined : json['showVotingCount'],
        'showFunding': !exists(json, 'showFunding') ? undefined : json['showFunding'],
        'showExpression': !exists(json, 'showExpression') ? undefined : json['showExpression'],
        'showEdit': !exists(json, 'showEdit') ? undefined : json['showEdit'],
    };
}

export function PostDisplayToJSON(value?: PostDisplay): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'titleTruncateLines': value.titleTruncateLines,
        'descriptionTruncateLines': value.descriptionTruncateLines,
        'responseTruncateLines': value.responseTruncateLines,
        'showCommentCount': value.showCommentCount,
        'showCategoryName': value.showCategoryName,
        'showCreated': value.showCreated,
        'showAuthor': value.showAuthor,
        'showStatus': value.showStatus,
        'showTags': value.showTags,
        'showVoting': value.showVoting,
        'showVotingCount': value.showVotingCount,
        'showFunding': value.showFunding,
        'showExpression': value.showExpression,
        'showEdit': value.showEdit,
    };
}


