/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaDraftSearch
 */
export interface IdeaDraftSearch {
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaDraftSearch
     */
    filterCategoryIds?: Array<string>;
}

export function IdeaDraftSearchFromJSON(json: any): IdeaDraftSearch {
    return {
        'filterCategoryIds': !exists(json, 'filterCategoryIds') ? undefined : json['filterCategoryIds'],
    };
}

export function IdeaDraftSearchToJSON(value?: IdeaDraftSearch): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filterCategoryIds': value.filterCategoryIds,
    };
}


