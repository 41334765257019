/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Menu,
    MenuFromJSON,
    MenuToJSON,
    Page,
    PageFromJSON,
    PageToJSON,
} from './';

/**
 * 
 * @export
 * @interface Layout
 */
export interface Layout {
    /**
     * 
     * @type {string}
     * @memberof Layout
     */
    pageTitleSuffix?: string;
    /**
     * 
     * @type {Array<Menu>}
     * @memberof Layout
     */
    menu: Array<Menu>;
    /**
     * 
     * @type {Array<Page>}
     * @memberof Layout
     */
    pages: Array<Page>;
}

export function LayoutFromJSON(json: any): Layout {
    return {
        'pageTitleSuffix': !exists(json, 'pageTitleSuffix') ? undefined : json['pageTitleSuffix'],
        
        'menu': (json['menu'] as Array<any>).map(MenuFromJSON),
        
        
        'pages': (json['pages'] as Array<any>).map(PageFromJSON),
        
    };
}

export function LayoutToJSON(value?: Layout): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pageTitleSuffix': value.pageTitleSuffix,
        
        'menu': (value.menu as Array<any>).map(MenuToJSON),
        
        
        'pages': (value.pages as Array<any>).map(PageToJSON),
        
    };
}


