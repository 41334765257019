/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountLogin
 */
export interface AccountLogin {
    /**
     * 
     * @type {string}
     * @memberof AccountLogin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountLogin
     */
    password: string;
}

export function AccountLoginFromJSON(json: any): AccountLogin {
    return {
        'email': json['email'],
        'password': json['password'],
    };
}

export function AccountLoginToJSON(value?: AccountLogin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'password': value.password,
    };
}


