/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    VoteOption,
    VoteOptionFromJSON,
    VoteOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaVote
 */
export interface IdeaVote {
    /**
     * 
     * @type {VoteOption}
     * @memberof IdeaVote
     */
    vote?: VoteOption;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaVote
     */
    expression?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IdeaVote
     */
    fundAmount?: number;
}

export function IdeaVoteFromJSON(json: any): IdeaVote {
    return {
        
        'vote': !exists(json, 'vote') ? undefined : VoteOptionFromJSON(json['vote']),
        'expression': !exists(json, 'expression') ? undefined : json['expression'],
        'fundAmount': !exists(json, 'fundAmount') ? undefined : json['fundAmount'],
    };
}

export function IdeaVoteToJSON(value?: IdeaVote): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'vote': VoteOptionToJSON(value.vote),
        'expression': value.expression,
        'fundAmount': value.fundAmount,
    };
}


