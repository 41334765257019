/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConvoMessage,
    ConvoMessageFromJSON,
    ConvoMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateMessageResponse
 */
export interface CreateMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageResponse
     */
    convoId: string;
    /**
     * 
     * @type {ConvoMessage}
     * @memberof CreateMessageResponse
     */
    message: ConvoMessage;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageResponse
     */
    responseMessageId: string;
}

export function CreateMessageResponseFromJSON(json: any): CreateMessageResponse {
    return {
        'convoId': json['convoId'],
        
        'message': ConvoMessageFromJSON(json['message']),
        'responseMessageId': json['responseMessageId'],
    };
}

export function CreateMessageResponseToJSON(value?: CreateMessageResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'convoId': value.convoId,
        
        'message': ConvoMessageToJSON(value.message),
        'responseMessageId': value.responseMessageId,
    };
}


