/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountBindAdminOauthToken
 */
export interface AccountBindAdminOauthToken {
    /**
     * 
     * @type {string}
     * @memberof AccountBindAdminOauthToken
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBindAdminOauthToken
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBindAdminOauthToken
     */
    basePlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBindAdminOauthToken
     */
    invitationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBindAdminOauthToken
     */
    couponId?: string;
}

export function AccountBindAdminOauthTokenFromJSON(json: any): AccountBindAdminOauthToken {
    return {
        'id': json['id'],
        'code': json['code'],
        'basePlanId': !exists(json, 'basePlanId') ? undefined : json['basePlanId'],
        'invitationId': !exists(json, 'invitationId') ? undefined : json['invitationId'],
        'couponId': !exists(json, 'couponId') ? undefined : json['couponId'],
    };
}

export function AccountBindAdminOauthTokenToJSON(value?: AccountBindAdminOauthToken): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'code': value.code,
        'basePlanId': value.basePlanId,
        'invitationId': value.invitationId,
        'couponId': value.couponId,
    };
}


