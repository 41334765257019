/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface VotingIWantThis
 */
export interface VotingIWantThis {
    /**
     * 
     * @type {string}
     * @memberof VotingIWantThis
     */
    positiveLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof VotingIWantThis
     */
    negativeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof VotingIWantThis
     */
    encourageLabel?: string;
}

export function VotingIWantThisFromJSON(json: any): VotingIWantThis {
    return {
        'positiveLabel': !exists(json, 'positiveLabel') ? undefined : json['positiveLabel'],
        'negativeLabel': !exists(json, 'negativeLabel') ? undefined : json['negativeLabel'],
        'encourageLabel': !exists(json, 'encourageLabel') ? undefined : json['encourageLabel'],
    };
}

export function VotingIWantThisToJSON(value?: VotingIWantThis): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'positiveLabel': value.positiveLabel,
        'negativeLabel': value.negativeLabel,
        'encourageLabel': value.encourageLabel,
    };
}


