/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Subscription,
    SubscriptionFromJSON,
    SubscriptionToJSON,
    Support,
    SupportFromJSON,
    SupportToJSON,
    Tagging,
    TaggingFromJSON,
    TaggingToJSON,
    Workflow,
    WorkflowFromJSON,
    WorkflowToJSON,
} from './';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    userCreatable: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Category
     */
    userMergeableCategoryIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    useCover?: boolean;
    /**
     * 
     * @type {Subscription}
     * @memberof Category
     */
    subscription?: Subscription;
    /**
     * 
     * @type {Workflow}
     * @memberof Category
     */
    workflow: Workflow;
    /**
     * 
     * @type {Support}
     * @memberof Category
     */
    support: Support;
    /**
     * 
     * @type {Tagging}
     * @memberof Category
     */
    tagging: Tagging;
}

export function CategoryFromJSON(json: any): Category {
    return {
        'categoryId': json['categoryId'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'userCreatable': json['userCreatable'],
        'userMergeableCategoryIds': !exists(json, 'userMergeableCategoryIds') ? undefined : json['userMergeableCategoryIds'],
        'useCover': !exists(json, 'useCover') ? undefined : json['useCover'],
        
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionFromJSON(json['subscription']),
        
        'workflow': WorkflowFromJSON(json['workflow']),
        
        'support': SupportFromJSON(json['support']),
        
        'tagging': TaggingFromJSON(json['tagging']),
    };
}

export function CategoryToJSON(value?: Category): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'categoryId': value.categoryId,
        'name': value.name,
        'color': value.color,
        'userCreatable': value.userCreatable,
        'userMergeableCategoryIds': value.userMergeableCategoryIds,
        'useCover': value.useCover,
        
        'subscription': SubscriptionToJSON(value.subscription),
        
        'workflow': WorkflowToJSON(value.workflow),
        
        'support': SupportToJSON(value.support),
        
        'tagging': TaggingToJSON(value.tagging),
    };
}


