/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    UserCreate,
    UserCreateFromJSON,
    UserCreateToJSON,
    UserCreateAdminAllOf,
    UserCreateAdminAllOfFromJSON,
    UserCreateAdminAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserCreateAdmin
 */
export interface UserCreateAdmin {
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    emailVerification?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    iosPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    androidPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    browserPushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateAdmin
     */
    ssoGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateAdmin
     */
    isMod?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCreateAdmin
     */
    balance?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserCreateAdmin
     */
    created?: Date;
}

export function UserCreateAdminFromJSON(json: any): UserCreateAdmin {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailVerification': !exists(json, 'emailVerification') ? undefined : json['emailVerification'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'iosPushToken': !exists(json, 'iosPushToken') ? undefined : json['iosPushToken'],
        'androidPushToken': !exists(json, 'androidPushToken') ? undefined : json['androidPushToken'],
        'browserPushToken': !exists(json, 'browserPushToken') ? undefined : json['browserPushToken'],
        'ssoGuid': !exists(json, 'ssoGuid') ? undefined : json['ssoGuid'],
        'isMod': !exists(json, 'isMod') ? undefined : json['isMod'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
    };
}

export function UserCreateAdminToJSON(value?: UserCreateAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'email': value.email,
        'emailVerification': value.emailVerification,
        'password': value.password,
        'iosPushToken': value.iosPushToken,
        'androidPushToken': value.androidPushToken,
        'browserPushToken': value.browserPushToken,
        'ssoGuid': value.ssoGuid,
        'isMod': value.isMod,
        'balance': value.balance,
        'created': value.created === undefined ? undefined : value.created.toISOString(),
    };
}


