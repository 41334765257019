/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Onboarding,
    OnboardingFromJSON,
    OnboardingToJSON,
    VersionedConfig,
    VersionedConfigFromJSON,
    VersionedConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigBindSlugResult
 */
export interface ConfigBindSlugResult {
    /**
     * 
     * @type {string}
     * @memberof ConfigBindSlugResult
     */
    projectId: string;
    /**
     * 
     * @type {VersionedConfig}
     * @memberof ConfigBindSlugResult
     */
    config?: VersionedConfig;
    /**
     * 
     * @type {Onboarding}
     * @memberof ConfigBindSlugResult
     */
    onboardBefore?: Onboarding;
}

export function ConfigBindSlugResultFromJSON(json: any): ConfigBindSlugResult {
    return {
        'projectId': json['projectId'],
        
        'config': !exists(json, 'config') ? undefined : VersionedConfigFromJSON(json['config']),
        
        'onboardBefore': !exists(json, 'onboardBefore') ? undefined : OnboardingFromJSON(json['onboardBefore']),
    };
}

export function ConfigBindSlugResultToJSON(value?: ConfigBindSlugResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'projectId': value.projectId,
        
        'config': VersionedConfigToJSON(value.config),
        
        'onboardBefore': OnboardingToJSON(value.onboardBefore),
    };
}


