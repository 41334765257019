/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    balance: number;
}

export function BalanceFromJSON(json: any): Balance {
    return {
        'balance': json['balance'],
    };
}

export function BalanceToJSON(value?: Balance): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'balance': value.balance,
    };
}


