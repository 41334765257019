/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    TransactionType,
    TransactionTypeFromJSON,
    TransactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionId: string;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    created: Date;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {TransactionType}
     * @memberof Transaction
     */
    transactionType: TransactionType;
    /**
     * Optional resource ID involved in this transaction. Resource type depends on the transactionType. Ex, for Vote type, this resource id is of the voted Idea. For Adjustment type, resource id is user id of the admin that initiated the adjustment.
     * @type {string}
     * @memberof Transaction
     */
    targetId?: string;
    /**
     * Optional user facing explanation of the transaction.
     * @type {string}
     * @memberof Transaction
     */
    summary?: string;
}

export function TransactionFromJSON(json: any): Transaction {
    return {
        'userId': json['userId'],
        'transactionId': json['transactionId'],
        'created': new Date(json['created']),
        'amount': json['amount'],
        
        'transactionType': TransactionTypeFromJSON(json['transactionType']),
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function TransactionToJSON(value?: Transaction): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'userId': value.userId,
        'transactionId': value.transactionId,
        'created': value.created.toISOString(),
        'amount': value.amount,
        
        'transactionType': TransactionTypeToJSON(value.transactionType),
        'targetId': value.targetId,
        'summary': value.summary,
    };
}


