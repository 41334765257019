/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    TransactionSearch,
    TransactionSearchFromJSON,
    TransactionSearchToJSON,
    TransactionSearchResponse,
    TransactionSearchResponseFromJSON,
    TransactionSearchResponseToJSON,
} from '../models';

export interface TransactionSearchRequest {
    projectId: string;
    userId: string;
    transactionSearch: TransactionSearch;
    cursor?: string;
}

/**
 * no description
 */
export interface CreditApiInterface {

    /**
     */
    transactionSearch(requestParameters: TransactionSearchRequest, headerExtra?:{[key:string]:string}): Promise<TransactionSearchResponse>;
}

/**
 * no description
 */
export class CreditApi extends runtime.BaseAPI implements CreditApiInterface {

    /**
     */
    async transactionSearchRaw(requestParameters: TransactionSearchRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<TransactionSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling transactionSearch.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling transactionSearch.');
        }

        if (requestParameters.transactionSearch === null || requestParameters.transactionSearch === undefined) {
            throw new runtime.RequiredError('transactionSearch','Required parameter requestParameters.transactionSearch was null or undefined when calling transactionSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/user/{userId}/transactionsearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionSearchToJSON(requestParameters.transactionSearch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async transactionSearch(requestParameters: TransactionSearchRequest, headerExtra?:{[key:string]:string}): Promise<TransactionSearchResponse> {
        const response = await this.transactionSearchRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
