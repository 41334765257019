/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Expression
 */
export interface Expression {
    /**
     * Must be unique, used as an ID
     * @type {string}
     * @memberof Expression
     */
    display: string;
    /**
     * 
     * @type {string}
     * @memberof Expression
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof Expression
     */
    weight: number;
}

export function ExpressionFromJSON(json: any): Expression {
    return {
        'display': json['display'],
        'text': json['text'],
        'weight': json['weight'],
    };
}

export function ExpressionToJSON(value?: Expression): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'display': value.display,
        'text': value.text,
        'weight': value.weight,
    };
}


