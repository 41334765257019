/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Idea,
    IdeaFromJSON,
    IdeaToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdeaConnectResponse
 */
export interface IdeaConnectResponse {
    /**
     * 
     * @type {Idea}
     * @memberof IdeaConnectResponse
     */
    idea: Idea;
    /**
     * 
     * @type {Idea}
     * @memberof IdeaConnectResponse
     */
    parentIdea: Idea;
}

export function IdeaConnectResponseFromJSON(json: any): IdeaConnectResponse {
    return {
        
        'idea': IdeaFromJSON(json['idea']),
        
        'parentIdea': IdeaFromJSON(json['parentIdea']),
    };
}

export function IdeaConnectResponseToJSON(value?: IdeaConnectResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'idea': IdeaToJSON(value.idea),
        
        'parentIdea': IdeaToJSON(value.parentIdea),
    };
}


