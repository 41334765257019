/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PagePanelWithHideIfEmpty,
    PagePanelWithHideIfEmptyFromJSON,
    PagePanelWithHideIfEmptyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageFeedbackHelp
 */
export interface PageFeedbackHelp {
    /**
     * 
     * @type {PagePanelWithHideIfEmpty}
     * @memberof PageFeedbackHelp
     */
    panel: PagePanelWithHideIfEmpty;
}

export function PageFeedbackHelpFromJSON(json: any): PageFeedbackHelp {
    return {
        
        'panel': PagePanelWithHideIfEmptyFromJSON(json['panel']),
    };
}

export function PageFeedbackHelpToJSON(value?: PageFeedbackHelp): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'panel': PagePanelWithHideIfEmptyToJSON(value.panel),
    };
}


