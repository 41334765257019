/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface HelloBar
 */
export interface HelloBar {
    /**
     * 
     * @type {string}
     * @memberof HelloBar
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HelloBar
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof HelloBar
     */
    button?: string;
}

export function HelloBarFromJSON(json: any): HelloBar {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'button': !exists(json, 'button') ? undefined : json['button'],
    };
}

export function HelloBarToJSON(value?: HelloBar): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'message': value.message,
        'button': value.button,
    };
}


