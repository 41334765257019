/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    VotingIWantThis,
    VotingIWantThisFromJSON,
    VotingIWantThisToJSON,
} from './';

/**
 * 
 * @export
 * @interface Voting
 */
export interface Voting {
    /**
     * 
     * @type {boolean}
     * @memberof Voting
     */
    enableDownvotes: boolean;
    /**
     * 
     * @type {VotingIWantThis}
     * @memberof Voting
     */
    iWantThis?: VotingIWantThis;
}

export function VotingFromJSON(json: any): Voting {
    return {
        'enableDownvotes': json['enableDownvotes'],
        
        'iWantThis': !exists(json, 'iWantThis') ? undefined : VotingIWantThisFromJSON(json['iWantThis']),
    };
}

export function VotingToJSON(value?: Voting): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enableDownvotes': value.enableDownvotes,
        
        'iWantThis': VotingIWantThisToJSON(value.iWantThis),
    };
}


