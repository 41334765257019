/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Flow
 */
export interface Flow {
    /**
     * 
     * @type {boolean}
     * @memberof Flow
     */
    enableTransitions: boolean;
}

export function FlowFromJSON(json: any): Flow {
    return {
        'enableTransitions': json['enableTransitions'],
    };
}

export function FlowToJSON(value?: Flow): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enableTransitions': value.enableTransitions,
    };
}


