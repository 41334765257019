/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CustomTermsDocuments,
    CustomTermsDocumentsFromJSON,
    CustomTermsDocumentsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomTerms
 */
export interface CustomTerms {
    /**
     * 
     * @type {Array<CustomTermsDocuments>}
     * @memberof CustomTerms
     */
    documents: Array<CustomTermsDocuments>;
}

export function CustomTermsFromJSON(json: any): CustomTerms {
    return {
        
        'documents': (json['documents'] as Array<any>).map(CustomTermsDocumentsFromJSON),
        
    };
}

export function CustomTermsToJSON(value?: CustomTerms): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'documents': (value.documents as Array<any>).map(CustomTermsDocumentsToJSON),
        
    };
}


