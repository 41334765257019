/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    AccountAdmin,
    AccountAdminFromJSON,
    AccountAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountBindAdminResponse
 */
export interface AccountBindAdminResponse {
    /**
     * 
     * @type {AccountAdmin}
     * @memberof AccountBindAdminResponse
     */
    account?: AccountAdmin;
    /**
     * 
     * @type {boolean}
     * @memberof AccountBindAdminResponse
     */
    isSuperAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountBindAdminResponse
     */
    created?: boolean;
}

export function AccountBindAdminResponseFromJSON(json: any): AccountBindAdminResponse {
    return {
        
        'account': !exists(json, 'account') ? undefined : AccountAdminFromJSON(json['account']),
        'isSuperAdmin': json['isSuperAdmin'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function AccountBindAdminResponseToJSON(value?: AccountBindAdminResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'account': AccountAdminToJSON(value.account),
        'isSuperAdmin': value.isSuperAdmin,
        'created': value.created,
    };
}


