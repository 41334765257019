/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Category,
    CategoryFromJSON,
    CategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * 
     * @type {Array<Category>}
     * @memberof Content
     */
    categories: Array<Category>;
}

export function ContentFromJSON(json: any): Content {
    return {
        
        'categories': (json['categories'] as Array<any>).map(CategoryFromJSON),
        
    };
}

export function ContentToJSON(value?: Content): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'categories': (value.categories as Array<any>).map(CategoryToJSON),
        
    };
}


