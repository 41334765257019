/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    PageFeedbackHelp,
    PageFeedbackHelpFromJSON,
    PageFeedbackHelpToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageFeedback
 */
export interface PageFeedback {
    /**
     * 
     * @type {string}
     * @memberof PageFeedback
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof PageFeedback
     */
    labelTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof PageFeedback
     */
    labelDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PageFeedback
     */
    labelSubmit?: string;
    /**
     * 
     * @type {PageFeedbackHelp}
     * @memberof PageFeedback
     */
    help?: PageFeedbackHelp;
    /**
     * 
     * @type {PageFeedbackHelp}
     * @memberof PageFeedback
     */
    related?: PageFeedbackHelp;
    /**
     * 
     * @type {PageFeedbackHelp}
     * @memberof PageFeedback
     */
    debate?: PageFeedbackHelp;
    /**
     * 
     * @type {PageFeedbackHelp}
     * @memberof PageFeedback
     */
    debate2?: PageFeedbackHelp;
}

export function PageFeedbackFromJSON(json: any): PageFeedback {
    return {
        'categoryId': json['categoryId'],
        'labelTitle': !exists(json, 'labelTitle') ? undefined : json['labelTitle'],
        'labelDescription': !exists(json, 'labelDescription') ? undefined : json['labelDescription'],
        'labelSubmit': !exists(json, 'labelSubmit') ? undefined : json['labelSubmit'],
        
        'help': !exists(json, 'help') ? undefined : PageFeedbackHelpFromJSON(json['help']),
        
        'related': !exists(json, 'related') ? undefined : PageFeedbackHelpFromJSON(json['related']),
        
        'debate': !exists(json, 'debate') ? undefined : PageFeedbackHelpFromJSON(json['debate']),
        
        'debate2': !exists(json, 'debate2') ? undefined : PageFeedbackHelpFromJSON(json['debate2']),
    };
}

export function PageFeedbackToJSON(value?: PageFeedback): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'categoryId': value.categoryId,
        'labelTitle': value.labelTitle,
        'labelDescription': value.labelDescription,
        'labelSubmit': value.labelSubmit,
        
        'help': PageFeedbackHelpToJSON(value.help),
        
        'related': PageFeedbackHelpToJSON(value.related),
        
        'debate': PageFeedbackHelpToJSON(value.debate),
        
        'debate2': PageFeedbackHelpToJSON(value.debate2),
    };
}


