/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceItem
 */
export interface InvoiceItem {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceItem
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItem
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItem
     */
    invoiceId: string;
}

export function InvoiceItemFromJSON(json: any): InvoiceItem {
    return {
        'date': new Date(json['date']),
        'status': json['status'],
        'amount': json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'invoiceId': json['invoiceId'],
    };
}

export function InvoiceItemToJSON(value?: InvoiceItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'date': value.date.toISOString().substr(0,10),
        'status': value.status,
        'amount': value.amount,
        'description': value.description,
        'invoiceId': value.invoiceId,
    };
}


