/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface PlanPricingAdmins
 */
export interface PlanPricingAdmins {
    /**
     * 
     * @type {number}
     * @memberof PlanPricingAdmins
     */
    amountIncluded: number;
    /**
     * 
     * @type {number}
     * @memberof PlanPricingAdmins
     */
    additionalPrice: number;
}

export function PlanPricingAdminsFromJSON(json: any): PlanPricingAdmins {
    return {
        'amountIncluded': json['amountIncluded'],
        'additionalPrice': json['additionalPrice'],
    };
}

export function PlanPricingAdminsToJSON(value?: PlanPricingAdmins): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amountIncluded': value.amountIncluded,
        'additionalPrice': value.additionalPrice,
    };
}


