/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    GoogleAnalytics,
    GoogleAnalyticsFromJSON,
    GoogleAnalyticsToJSON,
    Hotjar,
    HotjarFromJSON,
    HotjarToJSON,
    Intercom,
    IntercomFromJSON,
    IntercomToJSON,
} from './';

/**
 * 
 * @export
 * @interface Integrations
 */
export interface Integrations {
    /**
     * 
     * @type {GoogleAnalytics}
     * @memberof Integrations
     */
    googleAnalytics?: GoogleAnalytics;
    /**
     * 
     * @type {Hotjar}
     * @memberof Integrations
     */
    hotjar?: Hotjar;
    /**
     * 
     * @type {Intercom}
     * @memberof Integrations
     */
    intercom?: Intercom;
}

export function IntegrationsFromJSON(json: any): Integrations {
    return {
        
        'googleAnalytics': !exists(json, 'googleAnalytics') ? undefined : GoogleAnalyticsFromJSON(json['googleAnalytics']),
        
        'hotjar': !exists(json, 'hotjar') ? undefined : HotjarFromJSON(json['hotjar']),
        
        'intercom': !exists(json, 'intercom') ? undefined : IntercomFromJSON(json['intercom']),
    };
}

export function IntegrationsToJSON(value?: Integrations): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'googleAnalytics': GoogleAnalyticsToJSON(value.googleAnalytics),
        
        'hotjar': HotjarToJSON(value.hotjar),
        
        'intercom': IntercomToJSON(value.intercom),
    };
}


