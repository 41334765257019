/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CustomTermsDocuments
 */
export interface CustomTermsDocuments {
    /**
     * 
     * @type {string}
     * @memberof CustomTermsDocuments
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTermsDocuments
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTermsDocuments
     */
    link?: string;
}

export function CustomTermsDocumentsFromJSON(json: any): CustomTermsDocuments {
    return {
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'link': !exists(json, 'link') ? undefined : json['link'],
    };
}

export function CustomTermsDocumentsToJSON(value?: CustomTermsDocuments): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'shortName': value.shortName,
        'name': value.name,
        'link': value.link,
    };
}


