/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CreditIncome,
    CreditIncomeFromJSON,
    CreditIncomeToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    TransactionSearchAdmin,
    TransactionSearchAdminFromJSON,
    TransactionSearchAdminToJSON,
    TransactionSearchAdminResponse,
    TransactionSearchAdminResponseFromJSON,
    TransactionSearchAdminResponseToJSON,
} from '../models';

export interface CreditIncomeRequest {
    projectId: string;
    creditIncome: CreditIncome;
}

export interface TransactionSearchAdminRequest {
    projectId: string;
    transactionSearchAdmin: TransactionSearchAdmin;
    cursor?: string;
}

/**
 * no description
 */
export interface CreditAdminApiInterface {

    /**
     */
    creditIncome(requestParameters: CreditIncomeRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    transactionSearchAdmin(requestParameters: TransactionSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<TransactionSearchAdminResponse>;
}

/**
 * no description
 */
export class CreditAdminApi extends runtime.BaseAPI implements CreditAdminApiInterface {

    /**
     */
    async creditIncomeRaw(requestParameters: CreditIncomeRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling creditIncome.');
        }

        if (requestParameters.creditIncome === null || requestParameters.creditIncome === undefined) {
            throw new runtime.RequiredError('creditIncome','Required parameter requestParameters.creditIncome was null or undefined when calling creditIncome.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/credit/income`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreditIncomeToJSON(requestParameters.creditIncome),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async creditIncome(requestParameters: CreditIncomeRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.creditIncomeRaw(requestParameters, headerExtra);
    }

    /**
     */
    async transactionSearchAdminRaw(requestParameters: TransactionSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<TransactionSearchAdminResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling transactionSearchAdmin.');
        }

        if (requestParameters.transactionSearchAdmin === null || requestParameters.transactionSearchAdmin === undefined) {
            throw new runtime.RequiredError('transactionSearchAdmin','Required parameter requestParameters.transactionSearchAdmin was null or undefined when calling transactionSearchAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/transaction/search`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionSearchAdminToJSON(requestParameters.transactionSearchAdmin),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionSearchAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async transactionSearchAdmin(requestParameters: TransactionSearchAdminRequest, headerExtra?:{[key:string]:string}): Promise<TransactionSearchAdminResponse> {
        const response = await this.transactionSearchAdminRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
