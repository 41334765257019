/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CommentUpdate
 */
export interface CommentUpdate {
    /**
     * 
     * @type {string}
     * @memberof CommentUpdate
     */
    content?: string;
}

export function CommentUpdateFromJSON(json: any): CommentUpdate {
    return {
        'content': !exists(json, 'content') ? undefined : json['content'],
    };
}

export function CommentUpdateToJSON(value?: CommentUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
    };
}


