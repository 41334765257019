/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import * as runtime from './runtime';
// eslint-disable-next-line no-unused-vars
import { Api, ApiInterface } from './apis';
// eslint-disable-next-line no-unused-vars
import * as Model from './models';
// eslint-disable-next-line no-unused-vars
import * as AccountAdminApi from './apis/AccountAdminApi';
// eslint-disable-next-line no-unused-vars
import * as AccountSuperAdminApi from './apis/AccountSuperAdminApi';
// eslint-disable-next-line no-unused-vars
import * as CommentAdminApi from './apis/CommentAdminApi';
// eslint-disable-next-line no-unused-vars
import * as ContentAdminApi from './apis/ContentAdminApi';
// eslint-disable-next-line no-unused-vars
import * as CreditAdminApi from './apis/CreditAdminApi';
// eslint-disable-next-line no-unused-vars
import * as IdeaAdminApi from './apis/IdeaAdminApi';
// eslint-disable-next-line no-unused-vars
import * as LlmAdminApi from './apis/LlmAdminApi';
// eslint-disable-next-line no-unused-vars
import * as LlmSuperAdminApi from './apis/LlmSuperAdminApi';
// eslint-disable-next-line no-unused-vars
import * as PlanAdminApi from './apis/PlanAdminApi';
// eslint-disable-next-line no-unused-vars
import * as PlanSuperAdminApi from './apis/PlanSuperAdminApi';
// eslint-disable-next-line no-unused-vars
import * as ProjectAdminApi from './apis/ProjectAdminApi';
// eslint-disable-next-line no-unused-vars
import * as SupportApi from './apis/SupportApi';
// eslint-disable-next-line no-unused-vars
import * as UserAdminApi from './apis/UserAdminApi';

export * from './runtime';
export * from './apis';
export * from './models';

export enum Action {
  accountAcceptCouponAdmin = 'accountAcceptCouponAdmin',
  accountAcceptInvitationAdmin = 'accountAcceptInvitationAdmin',
  accountAttrsUpdateAdmin = 'accountAttrsUpdateAdmin',
  accountBillingAdmin = 'accountBillingAdmin',
  accountBindAdmin = 'accountBindAdmin',
  accountDeleteAdmin = 'accountDeleteAdmin',
  accountLoginAdmin = 'accountLoginAdmin',
  accountLogoutAdmin = 'accountLogoutAdmin',
  accountNoopAdmin = 'accountNoopAdmin',
  accountSignupAdmin = 'accountSignupAdmin',
  accountUpdateAdmin = 'accountUpdateAdmin',
  accountViewCouponAdmin = 'accountViewCouponAdmin',
  accountViewInvitationAdmin = 'accountViewInvitationAdmin',
  gitHubGetReposAdmin = 'gitHubGetReposAdmin',
  invoiceHtmlGetAdmin = 'invoiceHtmlGetAdmin',
  invoicesSearchAdmin = 'invoicesSearchAdmin',
  legalGet = 'legalGet',
  accountCreditAdjustmentSuperAdmin = 'accountCreditAdjustmentSuperAdmin',
  accountLoginAsSuperAdmin = 'accountLoginAsSuperAdmin',
  accountSearchSuperAdmin = 'accountSearchSuperAdmin',
  accountUpdateSuperAdmin = 'accountUpdateSuperAdmin',
  couponGenerateSuperAdmin = 'couponGenerateSuperAdmin',
  projectOwnerSwapSuperAdmin = 'projectOwnerSwapSuperAdmin',
  commentDeleteAdmin = 'commentDeleteAdmin',
  commentHistogramAdmin = 'commentHistogramAdmin',
  commentSearchAdmin = 'commentSearchAdmin',
  commentSubscribeAdmin = 'commentSubscribeAdmin',
  commentUnsubscribeAdmin = 'commentUnsubscribeAdmin',
  contentUploadAsAdmin = 'contentUploadAsAdmin',
  creditIncome = 'creditIncome',
  transactionSearchAdmin = 'transactionSearchAdmin',
  ideaCategoryAggregateAdmin = 'ideaCategoryAggregateAdmin',
  ideaCreateAdmin = 'ideaCreateAdmin',
  ideaDeleteAdmin = 'ideaDeleteAdmin',
  ideaDeleteBulkAdmin = 'ideaDeleteBulkAdmin',
  ideaDraftCreateAdmin = 'ideaDraftCreateAdmin',
  ideaDraftDeleteAdmin = 'ideaDraftDeleteAdmin',
  ideaDraftGetAdmin = 'ideaDraftGetAdmin',
  ideaDraftSearchAdmin = 'ideaDraftSearchAdmin',
  ideaDraftUpdateAdmin = 'ideaDraftUpdateAdmin',
  ideaGetAdmin = 'ideaGetAdmin',
  ideaHistogramAdmin = 'ideaHistogramAdmin',
  ideaLinkAdmin = 'ideaLinkAdmin',
  ideaMergeAdmin = 'ideaMergeAdmin',
  ideaSearchAdmin = 'ideaSearchAdmin',
  ideaSubscribeAdmin = 'ideaSubscribeAdmin',
  ideaUnLinkAdmin = 'ideaUnLinkAdmin',
  ideaUnMergeAdmin = 'ideaUnMergeAdmin',
  ideaUnsubscribeAdmin = 'ideaUnsubscribeAdmin',
  ideaUpdateAdmin = 'ideaUpdateAdmin',
  ideaVotersGetAdmin = 'ideaVotersGetAdmin',
  convoDeleteAdmin = 'convoDeleteAdmin',
  convoDetailsAdmin = 'convoDetailsAdmin',
  convoListAdmin = 'convoListAdmin',
  messageCreateAdmin = 'messageCreateAdmin',
  messageStreamGetAdmin = 'messageStreamGetAdmin',
  promptGetSuperAdmin = 'promptGetSuperAdmin',
  plansGet = 'plansGet',
  plansGetSuperAdmin = 'plansGetSuperAdmin',
  configGetAdmin = 'configGetAdmin',
  configGetAllAndUserBindAllAdmin = 'configGetAllAndUserBindAllAdmin',
  configSetAdmin = 'configSetAdmin',
  projectAdminsInviteAdmin = 'projectAdminsInviteAdmin',
  projectAdminsListAdmin = 'projectAdminsListAdmin',
  projectAdminsRemoveAdmin = 'projectAdminsRemoveAdmin',
  projectCreateAdmin = 'projectCreateAdmin',
  projectDeleteAdmin = 'projectDeleteAdmin',
  projectExportAdmin = 'projectExportAdmin',
  projectImportPostAdmin = 'projectImportPostAdmin',
  supportMessage = 'supportMessage',
  userCreateAdmin = 'userCreateAdmin',
  userDeleteAdmin = 'userDeleteAdmin',
  userDeleteBulkAdmin = 'userDeleteBulkAdmin',
  userGetAdmin = 'userGetAdmin',
  userHistogramAdmin = 'userHistogramAdmin',
  userSearchAdmin = 'userSearchAdmin',
  userSubscribeAdmin = 'userSubscribeAdmin',
  userUnsubscribeAdmin = 'userUnsubscribeAdmin',
  userUpdateAdmin = 'userUpdateAdmin',
}


export type Actions =
  accountAcceptCouponAdminActionFulfilled
  | accountAcceptCouponAdminActionPending
  | accountAcceptCouponAdminActionRejected
  | accountAcceptInvitationAdminActionFulfilled
  | accountAcceptInvitationAdminActionPending
  | accountAcceptInvitationAdminActionRejected
  | accountAttrsUpdateAdminActionFulfilled
  | accountAttrsUpdateAdminActionPending
  | accountAttrsUpdateAdminActionRejected
  | accountBillingAdminActionFulfilled
  | accountBillingAdminActionPending
  | accountBillingAdminActionRejected
  | accountBindAdminActionFulfilled
  | accountBindAdminActionPending
  | accountBindAdminActionRejected
  | accountDeleteAdminActionFulfilled
  | accountDeleteAdminActionPending
  | accountDeleteAdminActionRejected
  | accountLoginAdminActionFulfilled
  | accountLoginAdminActionPending
  | accountLoginAdminActionRejected
  | accountLogoutAdminActionFulfilled
  | accountLogoutAdminActionPending
  | accountLogoutAdminActionRejected
  | accountNoopAdminActionFulfilled
  | accountNoopAdminActionPending
  | accountNoopAdminActionRejected
  | accountSignupAdminActionFulfilled
  | accountSignupAdminActionPending
  | accountSignupAdminActionRejected
  | accountUpdateAdminActionFulfilled
  | accountUpdateAdminActionPending
  | accountUpdateAdminActionRejected
  | accountViewCouponAdminActionFulfilled
  | accountViewCouponAdminActionPending
  | accountViewCouponAdminActionRejected
  | accountViewInvitationAdminActionFulfilled
  | accountViewInvitationAdminActionPending
  | accountViewInvitationAdminActionRejected
  | gitHubGetReposAdminActionFulfilled
  | gitHubGetReposAdminActionPending
  | gitHubGetReposAdminActionRejected
  | invoiceHtmlGetAdminActionFulfilled
  | invoiceHtmlGetAdminActionPending
  | invoiceHtmlGetAdminActionRejected
  | invoicesSearchAdminActionFulfilled
  | invoicesSearchAdminActionPending
  | invoicesSearchAdminActionRejected
  | legalGetActionFulfilled
  | legalGetActionPending
  | legalGetActionRejected
  | accountCreditAdjustmentSuperAdminActionFulfilled
  | accountCreditAdjustmentSuperAdminActionPending
  | accountCreditAdjustmentSuperAdminActionRejected
  | accountLoginAsSuperAdminActionFulfilled
  | accountLoginAsSuperAdminActionPending
  | accountLoginAsSuperAdminActionRejected
  | accountSearchSuperAdminActionFulfilled
  | accountSearchSuperAdminActionPending
  | accountSearchSuperAdminActionRejected
  | accountUpdateSuperAdminActionFulfilled
  | accountUpdateSuperAdminActionPending
  | accountUpdateSuperAdminActionRejected
  | couponGenerateSuperAdminActionFulfilled
  | couponGenerateSuperAdminActionPending
  | couponGenerateSuperAdminActionRejected
  | projectOwnerSwapSuperAdminActionFulfilled
  | projectOwnerSwapSuperAdminActionPending
  | projectOwnerSwapSuperAdminActionRejected
  | commentDeleteAdminActionFulfilled
  | commentDeleteAdminActionPending
  | commentDeleteAdminActionRejected
  | commentHistogramAdminActionFulfilled
  | commentHistogramAdminActionPending
  | commentHistogramAdminActionRejected
  | commentSearchAdminActionFulfilled
  | commentSearchAdminActionPending
  | commentSearchAdminActionRejected
  | commentSubscribeAdminActionFulfilled
  | commentSubscribeAdminActionPending
  | commentSubscribeAdminActionRejected
  | commentUnsubscribeAdminActionFulfilled
  | commentUnsubscribeAdminActionPending
  | commentUnsubscribeAdminActionRejected
  | contentUploadAsAdminActionFulfilled
  | contentUploadAsAdminActionPending
  | contentUploadAsAdminActionRejected
  | creditIncomeActionFulfilled
  | creditIncomeActionPending
  | creditIncomeActionRejected
  | transactionSearchAdminActionFulfilled
  | transactionSearchAdminActionPending
  | transactionSearchAdminActionRejected
  | ideaCategoryAggregateAdminActionFulfilled
  | ideaCategoryAggregateAdminActionPending
  | ideaCategoryAggregateAdminActionRejected
  | ideaCreateAdminActionFulfilled
  | ideaCreateAdminActionPending
  | ideaCreateAdminActionRejected
  | ideaDeleteAdminActionFulfilled
  | ideaDeleteAdminActionPending
  | ideaDeleteAdminActionRejected
  | ideaDeleteBulkAdminActionFulfilled
  | ideaDeleteBulkAdminActionPending
  | ideaDeleteBulkAdminActionRejected
  | ideaDraftCreateAdminActionFulfilled
  | ideaDraftCreateAdminActionPending
  | ideaDraftCreateAdminActionRejected
  | ideaDraftDeleteAdminActionFulfilled
  | ideaDraftDeleteAdminActionPending
  | ideaDraftDeleteAdminActionRejected
  | ideaDraftGetAdminActionFulfilled
  | ideaDraftGetAdminActionPending
  | ideaDraftGetAdminActionRejected
  | ideaDraftSearchAdminActionFulfilled
  | ideaDraftSearchAdminActionPending
  | ideaDraftSearchAdminActionRejected
  | ideaDraftUpdateAdminActionFulfilled
  | ideaDraftUpdateAdminActionPending
  | ideaDraftUpdateAdminActionRejected
  | ideaGetAdminActionFulfilled
  | ideaGetAdminActionPending
  | ideaGetAdminActionRejected
  | ideaHistogramAdminActionFulfilled
  | ideaHistogramAdminActionPending
  | ideaHistogramAdminActionRejected
  | ideaLinkAdminActionFulfilled
  | ideaLinkAdminActionPending
  | ideaLinkAdminActionRejected
  | ideaMergeAdminActionFulfilled
  | ideaMergeAdminActionPending
  | ideaMergeAdminActionRejected
  | ideaSearchAdminActionFulfilled
  | ideaSearchAdminActionPending
  | ideaSearchAdminActionRejected
  | ideaSubscribeAdminActionFulfilled
  | ideaSubscribeAdminActionPending
  | ideaSubscribeAdminActionRejected
  | ideaUnLinkAdminActionFulfilled
  | ideaUnLinkAdminActionPending
  | ideaUnLinkAdminActionRejected
  | ideaUnMergeAdminActionFulfilled
  | ideaUnMergeAdminActionPending
  | ideaUnMergeAdminActionRejected
  | ideaUnsubscribeAdminActionFulfilled
  | ideaUnsubscribeAdminActionPending
  | ideaUnsubscribeAdminActionRejected
  | ideaUpdateAdminActionFulfilled
  | ideaUpdateAdminActionPending
  | ideaUpdateAdminActionRejected
  | ideaVotersGetAdminActionFulfilled
  | ideaVotersGetAdminActionPending
  | ideaVotersGetAdminActionRejected
  | convoDeleteAdminActionFulfilled
  | convoDeleteAdminActionPending
  | convoDeleteAdminActionRejected
  | convoDetailsAdminActionFulfilled
  | convoDetailsAdminActionPending
  | convoDetailsAdminActionRejected
  | convoListAdminActionFulfilled
  | convoListAdminActionPending
  | convoListAdminActionRejected
  | messageCreateAdminActionFulfilled
  | messageCreateAdminActionPending
  | messageCreateAdminActionRejected
  | messageStreamGetAdminActionFulfilled
  | messageStreamGetAdminActionPending
  | messageStreamGetAdminActionRejected
  | promptGetSuperAdminActionFulfilled
  | promptGetSuperAdminActionPending
  | promptGetSuperAdminActionRejected
  | plansGetActionFulfilled
  | plansGetActionPending
  | plansGetActionRejected
  | plansGetSuperAdminActionFulfilled
  | plansGetSuperAdminActionPending
  | plansGetSuperAdminActionRejected
  | configGetAdminActionFulfilled
  | configGetAdminActionPending
  | configGetAdminActionRejected
  | configGetAllAndUserBindAllAdminActionFulfilled
  | configGetAllAndUserBindAllAdminActionPending
  | configGetAllAndUserBindAllAdminActionRejected
  | configSetAdminActionFulfilled
  | configSetAdminActionPending
  | configSetAdminActionRejected
  | projectAdminsInviteAdminActionFulfilled
  | projectAdminsInviteAdminActionPending
  | projectAdminsInviteAdminActionRejected
  | projectAdminsListAdminActionFulfilled
  | projectAdminsListAdminActionPending
  | projectAdminsListAdminActionRejected
  | projectAdminsRemoveAdminActionFulfilled
  | projectAdminsRemoveAdminActionPending
  | projectAdminsRemoveAdminActionRejected
  | projectCreateAdminActionFulfilled
  | projectCreateAdminActionPending
  | projectCreateAdminActionRejected
  | projectDeleteAdminActionFulfilled
  | projectDeleteAdminActionPending
  | projectDeleteAdminActionRejected
  | projectExportAdminActionFulfilled
  | projectExportAdminActionPending
  | projectExportAdminActionRejected
  | projectImportPostAdminActionFulfilled
  | projectImportPostAdminActionPending
  | projectImportPostAdminActionRejected
  | supportMessageActionFulfilled
  | supportMessageActionPending
  | supportMessageActionRejected
  | userCreateAdminActionFulfilled
  | userCreateAdminActionPending
  | userCreateAdminActionRejected
  | userDeleteAdminActionFulfilled
  | userDeleteAdminActionPending
  | userDeleteAdminActionRejected
  | userDeleteBulkAdminActionFulfilled
  | userDeleteBulkAdminActionPending
  | userDeleteBulkAdminActionRejected
  | userGetAdminActionFulfilled
  | userGetAdminActionPending
  | userGetAdminActionRejected
  | userHistogramAdminActionFulfilled
  | userHistogramAdminActionPending
  | userHistogramAdminActionRejected
  | userSearchAdminActionFulfilled
  | userSearchAdminActionPending
  | userSearchAdminActionRejected
  | userSubscribeAdminActionFulfilled
  | userSubscribeAdminActionPending
  | userSubscribeAdminActionRejected
  | userUnsubscribeAdminActionFulfilled
  | userUnsubscribeAdminActionPending
  | userUnsubscribeAdminActionRejected
  | userUpdateAdminActionFulfilled
  | userUpdateAdminActionPending
  | userUpdateAdminActionRejected
;



export enum accountAcceptCouponAdminActionStatus {
  Pending = 'accountAcceptCouponAdmin_PENDING',
  Fulfilled = 'accountAcceptCouponAdmin_FULFILLED',
  Rejected = 'accountAcceptCouponAdmin_REJECTED',
}
export interface accountAcceptCouponAdminActionFulfilled {
  type: accountAcceptCouponAdminActionStatus.Fulfilled;
  meta: { action: Action.accountAcceptCouponAdmin; request: AccountAdminApi.AccountAcceptCouponAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountAcceptCouponAdminActionPending {
  type: accountAcceptCouponAdminActionStatus.Pending;
  meta: { action: Action.accountAcceptCouponAdmin; request: AccountAdminApi.AccountAcceptCouponAdminRequest; };
}
export interface accountAcceptCouponAdminActionRejected {
  type: accountAcceptCouponAdminActionStatus.Rejected;
  meta: { action: Action.accountAcceptCouponAdmin; request: AccountAdminApi.AccountAcceptCouponAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountAcceptInvitationAdminActionStatus {
  Pending = 'accountAcceptInvitationAdmin_PENDING',
  Fulfilled = 'accountAcceptInvitationAdmin_FULFILLED',
  Rejected = 'accountAcceptInvitationAdmin_REJECTED',
}
export interface accountAcceptInvitationAdminActionFulfilled {
  type: accountAcceptInvitationAdminActionStatus.Fulfilled;
  meta: { action: Action.accountAcceptInvitationAdmin; request: AccountAdminApi.AccountAcceptInvitationAdminRequest; };
  payload: Model.AccountAcceptInvitationResponse;
}
export interface accountAcceptInvitationAdminActionPending {
  type: accountAcceptInvitationAdminActionStatus.Pending;
  meta: { action: Action.accountAcceptInvitationAdmin; request: AccountAdminApi.AccountAcceptInvitationAdminRequest; };
}
export interface accountAcceptInvitationAdminActionRejected {
  type: accountAcceptInvitationAdminActionStatus.Rejected;
  meta: { action: Action.accountAcceptInvitationAdmin; request: AccountAdminApi.AccountAcceptInvitationAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountAttrsUpdateAdminActionStatus {
  Pending = 'accountAttrsUpdateAdmin_PENDING',
  Fulfilled = 'accountAttrsUpdateAdmin_FULFILLED',
  Rejected = 'accountAttrsUpdateAdmin_REJECTED',
}
export interface accountAttrsUpdateAdminActionFulfilled {
  type: accountAttrsUpdateAdminActionStatus.Fulfilled;
  meta: { action: Action.accountAttrsUpdateAdmin; request: AccountAdminApi.AccountAttrsUpdateAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountAttrsUpdateAdminActionPending {
  type: accountAttrsUpdateAdminActionStatus.Pending;
  meta: { action: Action.accountAttrsUpdateAdmin; request: AccountAdminApi.AccountAttrsUpdateAdminRequest; };
}
export interface accountAttrsUpdateAdminActionRejected {
  type: accountAttrsUpdateAdminActionStatus.Rejected;
  meta: { action: Action.accountAttrsUpdateAdmin; request: AccountAdminApi.AccountAttrsUpdateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountBillingAdminActionStatus {
  Pending = 'accountBillingAdmin_PENDING',
  Fulfilled = 'accountBillingAdmin_FULFILLED',
  Rejected = 'accountBillingAdmin_REJECTED',
}
export interface accountBillingAdminActionFulfilled {
  type: accountBillingAdminActionStatus.Fulfilled;
  meta: { action: Action.accountBillingAdmin; request: AccountAdminApi.AccountBillingAdminRequest; };
  payload: Model.AccountBilling;
}
export interface accountBillingAdminActionPending {
  type: accountBillingAdminActionStatus.Pending;
  meta: { action: Action.accountBillingAdmin; request: AccountAdminApi.AccountBillingAdminRequest; };
}
export interface accountBillingAdminActionRejected {
  type: accountBillingAdminActionStatus.Rejected;
  meta: { action: Action.accountBillingAdmin; request: AccountAdminApi.AccountBillingAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountBindAdminActionStatus {
  Pending = 'accountBindAdmin_PENDING',
  Fulfilled = 'accountBindAdmin_FULFILLED',
  Rejected = 'accountBindAdmin_REJECTED',
}
export interface accountBindAdminActionFulfilled {
  type: accountBindAdminActionStatus.Fulfilled;
  meta: { action: Action.accountBindAdmin; request: AccountAdminApi.AccountBindAdminRequest; };
  payload: Model.AccountBindAdminResponse;
}
export interface accountBindAdminActionPending {
  type: accountBindAdminActionStatus.Pending;
  meta: { action: Action.accountBindAdmin; request: AccountAdminApi.AccountBindAdminRequest; };
}
export interface accountBindAdminActionRejected {
  type: accountBindAdminActionStatus.Rejected;
  meta: { action: Action.accountBindAdmin; request: AccountAdminApi.AccountBindAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountDeleteAdminActionStatus {
  Pending = 'accountDeleteAdmin_PENDING',
  Fulfilled = 'accountDeleteAdmin_FULFILLED',
  Rejected = 'accountDeleteAdmin_REJECTED',
}
export interface accountDeleteAdminActionFulfilled {
  type: accountDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.accountDeleteAdmin; request: undefined; };
  payload: void;
}
export interface accountDeleteAdminActionPending {
  type: accountDeleteAdminActionStatus.Pending;
  meta: { action: Action.accountDeleteAdmin; request: undefined; };
}
export interface accountDeleteAdminActionRejected {
  type: accountDeleteAdminActionStatus.Rejected;
  meta: { action: Action.accountDeleteAdmin; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountLoginAdminActionStatus {
  Pending = 'accountLoginAdmin_PENDING',
  Fulfilled = 'accountLoginAdmin_FULFILLED',
  Rejected = 'accountLoginAdmin_REJECTED',
}
export interface accountLoginAdminActionFulfilled {
  type: accountLoginAdminActionStatus.Fulfilled;
  meta: { action: Action.accountLoginAdmin; request: AccountAdminApi.AccountLoginAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountLoginAdminActionPending {
  type: accountLoginAdminActionStatus.Pending;
  meta: { action: Action.accountLoginAdmin; request: AccountAdminApi.AccountLoginAdminRequest; };
}
export interface accountLoginAdminActionRejected {
  type: accountLoginAdminActionStatus.Rejected;
  meta: { action: Action.accountLoginAdmin; request: AccountAdminApi.AccountLoginAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountLogoutAdminActionStatus {
  Pending = 'accountLogoutAdmin_PENDING',
  Fulfilled = 'accountLogoutAdmin_FULFILLED',
  Rejected = 'accountLogoutAdmin_REJECTED',
}
export interface accountLogoutAdminActionFulfilled {
  type: accountLogoutAdminActionStatus.Fulfilled;
  meta: { action: Action.accountLogoutAdmin; request: undefined; };
  payload: void;
}
export interface accountLogoutAdminActionPending {
  type: accountLogoutAdminActionStatus.Pending;
  meta: { action: Action.accountLogoutAdmin; request: undefined; };
}
export interface accountLogoutAdminActionRejected {
  type: accountLogoutAdminActionStatus.Rejected;
  meta: { action: Action.accountLogoutAdmin; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountNoopAdminActionStatus {
  Pending = 'accountNoopAdmin_PENDING',
  Fulfilled = 'accountNoopAdmin_FULFILLED',
  Rejected = 'accountNoopAdmin_REJECTED',
}
export interface accountNoopAdminActionFulfilled {
  type: accountNoopAdminActionStatus.Fulfilled;
  meta: { action: Action.accountNoopAdmin; request: undefined; };
  payload: void;
}
export interface accountNoopAdminActionPending {
  type: accountNoopAdminActionStatus.Pending;
  meta: { action: Action.accountNoopAdmin; request: undefined; };
}
export interface accountNoopAdminActionRejected {
  type: accountNoopAdminActionStatus.Rejected;
  meta: { action: Action.accountNoopAdmin; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountSignupAdminActionStatus {
  Pending = 'accountSignupAdmin_PENDING',
  Fulfilled = 'accountSignupAdmin_FULFILLED',
  Rejected = 'accountSignupAdmin_REJECTED',
}
export interface accountSignupAdminActionFulfilled {
  type: accountSignupAdminActionStatus.Fulfilled;
  meta: { action: Action.accountSignupAdmin; request: AccountAdminApi.AccountSignupAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountSignupAdminActionPending {
  type: accountSignupAdminActionStatus.Pending;
  meta: { action: Action.accountSignupAdmin; request: AccountAdminApi.AccountSignupAdminRequest; };
}
export interface accountSignupAdminActionRejected {
  type: accountSignupAdminActionStatus.Rejected;
  meta: { action: Action.accountSignupAdmin; request: AccountAdminApi.AccountSignupAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountUpdateAdminActionStatus {
  Pending = 'accountUpdateAdmin_PENDING',
  Fulfilled = 'accountUpdateAdmin_FULFILLED',
  Rejected = 'accountUpdateAdmin_REJECTED',
}
export interface accountUpdateAdminActionFulfilled {
  type: accountUpdateAdminActionStatus.Fulfilled;
  meta: { action: Action.accountUpdateAdmin; request: AccountAdminApi.AccountUpdateAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountUpdateAdminActionPending {
  type: accountUpdateAdminActionStatus.Pending;
  meta: { action: Action.accountUpdateAdmin; request: AccountAdminApi.AccountUpdateAdminRequest; };
}
export interface accountUpdateAdminActionRejected {
  type: accountUpdateAdminActionStatus.Rejected;
  meta: { action: Action.accountUpdateAdmin; request: AccountAdminApi.AccountUpdateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountViewCouponAdminActionStatus {
  Pending = 'accountViewCouponAdmin_PENDING',
  Fulfilled = 'accountViewCouponAdmin_FULFILLED',
  Rejected = 'accountViewCouponAdmin_REJECTED',
}
export interface accountViewCouponAdminActionFulfilled {
  type: accountViewCouponAdminActionStatus.Fulfilled;
  meta: { action: Action.accountViewCouponAdmin; request: AccountAdminApi.AccountViewCouponAdminRequest; };
  payload: Model.ViewCouponResponse;
}
export interface accountViewCouponAdminActionPending {
  type: accountViewCouponAdminActionStatus.Pending;
  meta: { action: Action.accountViewCouponAdmin; request: AccountAdminApi.AccountViewCouponAdminRequest; };
}
export interface accountViewCouponAdminActionRejected {
  type: accountViewCouponAdminActionStatus.Rejected;
  meta: { action: Action.accountViewCouponAdmin; request: AccountAdminApi.AccountViewCouponAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountViewInvitationAdminActionStatus {
  Pending = 'accountViewInvitationAdmin_PENDING',
  Fulfilled = 'accountViewInvitationAdmin_FULFILLED',
  Rejected = 'accountViewInvitationAdmin_REJECTED',
}
export interface accountViewInvitationAdminActionFulfilled {
  type: accountViewInvitationAdminActionStatus.Fulfilled;
  meta: { action: Action.accountViewInvitationAdmin; request: AccountAdminApi.AccountViewInvitationAdminRequest; };
  payload: Model.InvitationResult;
}
export interface accountViewInvitationAdminActionPending {
  type: accountViewInvitationAdminActionStatus.Pending;
  meta: { action: Action.accountViewInvitationAdmin; request: AccountAdminApi.AccountViewInvitationAdminRequest; };
}
export interface accountViewInvitationAdminActionRejected {
  type: accountViewInvitationAdminActionStatus.Rejected;
  meta: { action: Action.accountViewInvitationAdmin; request: AccountAdminApi.AccountViewInvitationAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum gitHubGetReposAdminActionStatus {
  Pending = 'gitHubGetReposAdmin_PENDING',
  Fulfilled = 'gitHubGetReposAdmin_FULFILLED',
  Rejected = 'gitHubGetReposAdmin_REJECTED',
}
export interface gitHubGetReposAdminActionFulfilled {
  type: gitHubGetReposAdminActionStatus.Fulfilled;
  meta: { action: Action.gitHubGetReposAdmin; request: AccountAdminApi.GitHubGetReposAdminRequest; };
  payload: Model.AvailableRepos;
}
export interface gitHubGetReposAdminActionPending {
  type: gitHubGetReposAdminActionStatus.Pending;
  meta: { action: Action.gitHubGetReposAdmin; request: AccountAdminApi.GitHubGetReposAdminRequest; };
}
export interface gitHubGetReposAdminActionRejected {
  type: gitHubGetReposAdminActionStatus.Rejected;
  meta: { action: Action.gitHubGetReposAdmin; request: AccountAdminApi.GitHubGetReposAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum invoiceHtmlGetAdminActionStatus {
  Pending = 'invoiceHtmlGetAdmin_PENDING',
  Fulfilled = 'invoiceHtmlGetAdmin_FULFILLED',
  Rejected = 'invoiceHtmlGetAdmin_REJECTED',
}
export interface invoiceHtmlGetAdminActionFulfilled {
  type: invoiceHtmlGetAdminActionStatus.Fulfilled;
  meta: { action: Action.invoiceHtmlGetAdmin; request: AccountAdminApi.InvoiceHtmlGetAdminRequest; };
  payload: Model.InvoiceHtmlResponse;
}
export interface invoiceHtmlGetAdminActionPending {
  type: invoiceHtmlGetAdminActionStatus.Pending;
  meta: { action: Action.invoiceHtmlGetAdmin; request: AccountAdminApi.InvoiceHtmlGetAdminRequest; };
}
export interface invoiceHtmlGetAdminActionRejected {
  type: invoiceHtmlGetAdminActionStatus.Rejected;
  meta: { action: Action.invoiceHtmlGetAdmin; request: AccountAdminApi.InvoiceHtmlGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum invoicesSearchAdminActionStatus {
  Pending = 'invoicesSearchAdmin_PENDING',
  Fulfilled = 'invoicesSearchAdmin_FULFILLED',
  Rejected = 'invoicesSearchAdmin_REJECTED',
}
export interface invoicesSearchAdminActionFulfilled {
  type: invoicesSearchAdminActionStatus.Fulfilled;
  meta: { action: Action.invoicesSearchAdmin; request: AccountAdminApi.InvoicesSearchAdminRequest; };
  payload: Model.Invoices;
}
export interface invoicesSearchAdminActionPending {
  type: invoicesSearchAdminActionStatus.Pending;
  meta: { action: Action.invoicesSearchAdmin; request: AccountAdminApi.InvoicesSearchAdminRequest; };
}
export interface invoicesSearchAdminActionRejected {
  type: invoicesSearchAdminActionStatus.Rejected;
  meta: { action: Action.invoicesSearchAdmin; request: AccountAdminApi.InvoicesSearchAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum legalGetActionStatus {
  Pending = 'legalGet_PENDING',
  Fulfilled = 'legalGet_FULFILLED',
  Rejected = 'legalGet_REJECTED',
}
export interface legalGetActionFulfilled {
  type: legalGetActionStatus.Fulfilled;
  meta: { action: Action.legalGet; request: undefined; };
  payload: Model.LegalResponse;
}
export interface legalGetActionPending {
  type: legalGetActionStatus.Pending;
  meta: { action: Action.legalGet; request: undefined; };
}
export interface legalGetActionRejected {
  type: legalGetActionStatus.Rejected;
  meta: { action: Action.legalGet; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountCreditAdjustmentSuperAdminActionStatus {
  Pending = 'accountCreditAdjustmentSuperAdmin_PENDING',
  Fulfilled = 'accountCreditAdjustmentSuperAdmin_FULFILLED',
  Rejected = 'accountCreditAdjustmentSuperAdmin_REJECTED',
}
export interface accountCreditAdjustmentSuperAdminActionFulfilled {
  type: accountCreditAdjustmentSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.accountCreditAdjustmentSuperAdmin; request: AccountSuperAdminApi.AccountCreditAdjustmentSuperAdminRequest; };
  payload: void;
}
export interface accountCreditAdjustmentSuperAdminActionPending {
  type: accountCreditAdjustmentSuperAdminActionStatus.Pending;
  meta: { action: Action.accountCreditAdjustmentSuperAdmin; request: AccountSuperAdminApi.AccountCreditAdjustmentSuperAdminRequest; };
}
export interface accountCreditAdjustmentSuperAdminActionRejected {
  type: accountCreditAdjustmentSuperAdminActionStatus.Rejected;
  meta: { action: Action.accountCreditAdjustmentSuperAdmin; request: AccountSuperAdminApi.AccountCreditAdjustmentSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountLoginAsSuperAdminActionStatus {
  Pending = 'accountLoginAsSuperAdmin_PENDING',
  Fulfilled = 'accountLoginAsSuperAdmin_FULFILLED',
  Rejected = 'accountLoginAsSuperAdmin_REJECTED',
}
export interface accountLoginAsSuperAdminActionFulfilled {
  type: accountLoginAsSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.accountLoginAsSuperAdmin; request: AccountSuperAdminApi.AccountLoginAsSuperAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountLoginAsSuperAdminActionPending {
  type: accountLoginAsSuperAdminActionStatus.Pending;
  meta: { action: Action.accountLoginAsSuperAdmin; request: AccountSuperAdminApi.AccountLoginAsSuperAdminRequest; };
}
export interface accountLoginAsSuperAdminActionRejected {
  type: accountLoginAsSuperAdminActionStatus.Rejected;
  meta: { action: Action.accountLoginAsSuperAdmin; request: AccountSuperAdminApi.AccountLoginAsSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountSearchSuperAdminActionStatus {
  Pending = 'accountSearchSuperAdmin_PENDING',
  Fulfilled = 'accountSearchSuperAdmin_FULFILLED',
  Rejected = 'accountSearchSuperAdmin_REJECTED',
}
export interface accountSearchSuperAdminActionFulfilled {
  type: accountSearchSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.accountSearchSuperAdmin; request: AccountSuperAdminApi.AccountSearchSuperAdminRequest; };
  payload: Model.AccountSearchResponse;
}
export interface accountSearchSuperAdminActionPending {
  type: accountSearchSuperAdminActionStatus.Pending;
  meta: { action: Action.accountSearchSuperAdmin; request: AccountSuperAdminApi.AccountSearchSuperAdminRequest; };
}
export interface accountSearchSuperAdminActionRejected {
  type: accountSearchSuperAdminActionStatus.Rejected;
  meta: { action: Action.accountSearchSuperAdmin; request: AccountSuperAdminApi.AccountSearchSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum accountUpdateSuperAdminActionStatus {
  Pending = 'accountUpdateSuperAdmin_PENDING',
  Fulfilled = 'accountUpdateSuperAdmin_FULFILLED',
  Rejected = 'accountUpdateSuperAdmin_REJECTED',
}
export interface accountUpdateSuperAdminActionFulfilled {
  type: accountUpdateSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.accountUpdateSuperAdmin; request: AccountSuperAdminApi.AccountUpdateSuperAdminRequest; };
  payload: Model.AccountAdmin;
}
export interface accountUpdateSuperAdminActionPending {
  type: accountUpdateSuperAdminActionStatus.Pending;
  meta: { action: Action.accountUpdateSuperAdmin; request: AccountSuperAdminApi.AccountUpdateSuperAdminRequest; };
}
export interface accountUpdateSuperAdminActionRejected {
  type: accountUpdateSuperAdminActionStatus.Rejected;
  meta: { action: Action.accountUpdateSuperAdmin; request: AccountSuperAdminApi.AccountUpdateSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum couponGenerateSuperAdminActionStatus {
  Pending = 'couponGenerateSuperAdmin_PENDING',
  Fulfilled = 'couponGenerateSuperAdmin_FULFILLED',
  Rejected = 'couponGenerateSuperAdmin_REJECTED',
}
export interface couponGenerateSuperAdminActionFulfilled {
  type: couponGenerateSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.couponGenerateSuperAdmin; request: AccountSuperAdminApi.CouponGenerateSuperAdminRequest; };
  payload: runtime.FileDownload;
}
export interface couponGenerateSuperAdminActionPending {
  type: couponGenerateSuperAdminActionStatus.Pending;
  meta: { action: Action.couponGenerateSuperAdmin; request: AccountSuperAdminApi.CouponGenerateSuperAdminRequest; };
}
export interface couponGenerateSuperAdminActionRejected {
  type: couponGenerateSuperAdminActionStatus.Rejected;
  meta: { action: Action.couponGenerateSuperAdmin; request: AccountSuperAdminApi.CouponGenerateSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectOwnerSwapSuperAdminActionStatus {
  Pending = 'projectOwnerSwapSuperAdmin_PENDING',
  Fulfilled = 'projectOwnerSwapSuperAdmin_FULFILLED',
  Rejected = 'projectOwnerSwapSuperAdmin_REJECTED',
}
export interface projectOwnerSwapSuperAdminActionFulfilled {
  type: projectOwnerSwapSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.projectOwnerSwapSuperAdmin; request: AccountSuperAdminApi.ProjectOwnerSwapSuperAdminRequest; };
  payload: void;
}
export interface projectOwnerSwapSuperAdminActionPending {
  type: projectOwnerSwapSuperAdminActionStatus.Pending;
  meta: { action: Action.projectOwnerSwapSuperAdmin; request: AccountSuperAdminApi.ProjectOwnerSwapSuperAdminRequest; };
}
export interface projectOwnerSwapSuperAdminActionRejected {
  type: projectOwnerSwapSuperAdminActionStatus.Rejected;
  meta: { action: Action.projectOwnerSwapSuperAdmin; request: AccountSuperAdminApi.ProjectOwnerSwapSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentDeleteAdminActionStatus {
  Pending = 'commentDeleteAdmin_PENDING',
  Fulfilled = 'commentDeleteAdmin_FULFILLED',
  Rejected = 'commentDeleteAdmin_REJECTED',
}
export interface commentDeleteAdminActionFulfilled {
  type: commentDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.commentDeleteAdmin; request: CommentAdminApi.CommentDeleteAdminRequest; };
  payload: Model.Comment;
}
export interface commentDeleteAdminActionPending {
  type: commentDeleteAdminActionStatus.Pending;
  meta: { action: Action.commentDeleteAdmin; request: CommentAdminApi.CommentDeleteAdminRequest; };
}
export interface commentDeleteAdminActionRejected {
  type: commentDeleteAdminActionStatus.Rejected;
  meta: { action: Action.commentDeleteAdmin; request: CommentAdminApi.CommentDeleteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentHistogramAdminActionStatus {
  Pending = 'commentHistogramAdmin_PENDING',
  Fulfilled = 'commentHistogramAdmin_FULFILLED',
  Rejected = 'commentHistogramAdmin_REJECTED',
}
export interface commentHistogramAdminActionFulfilled {
  type: commentHistogramAdminActionStatus.Fulfilled;
  meta: { action: Action.commentHistogramAdmin; request: CommentAdminApi.CommentHistogramAdminRequest; };
  payload: Model.HistogramResponse;
}
export interface commentHistogramAdminActionPending {
  type: commentHistogramAdminActionStatus.Pending;
  meta: { action: Action.commentHistogramAdmin; request: CommentAdminApi.CommentHistogramAdminRequest; };
}
export interface commentHistogramAdminActionRejected {
  type: commentHistogramAdminActionStatus.Rejected;
  meta: { action: Action.commentHistogramAdmin; request: CommentAdminApi.CommentHistogramAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentSearchAdminActionStatus {
  Pending = 'commentSearchAdmin_PENDING',
  Fulfilled = 'commentSearchAdmin_FULFILLED',
  Rejected = 'commentSearchAdmin_REJECTED',
}
export interface commentSearchAdminActionFulfilled {
  type: commentSearchAdminActionStatus.Fulfilled;
  meta: { action: Action.commentSearchAdmin; request: CommentAdminApi.CommentSearchAdminRequest; };
  payload: Model.CommentSearchAdminResponse;
}
export interface commentSearchAdminActionPending {
  type: commentSearchAdminActionStatus.Pending;
  meta: { action: Action.commentSearchAdmin; request: CommentAdminApi.CommentSearchAdminRequest; };
}
export interface commentSearchAdminActionRejected {
  type: commentSearchAdminActionStatus.Rejected;
  meta: { action: Action.commentSearchAdmin; request: CommentAdminApi.CommentSearchAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentSubscribeAdminActionStatus {
  Pending = 'commentSubscribeAdmin_PENDING',
  Fulfilled = 'commentSubscribeAdmin_FULFILLED',
  Rejected = 'commentSubscribeAdmin_REJECTED',
}
export interface commentSubscribeAdminActionFulfilled {
  type: commentSubscribeAdminActionStatus.Fulfilled;
  meta: { action: Action.commentSubscribeAdmin; request: CommentAdminApi.CommentSubscribeAdminRequest; };
  payload: void;
}
export interface commentSubscribeAdminActionPending {
  type: commentSubscribeAdminActionStatus.Pending;
  meta: { action: Action.commentSubscribeAdmin; request: CommentAdminApi.CommentSubscribeAdminRequest; };
}
export interface commentSubscribeAdminActionRejected {
  type: commentSubscribeAdminActionStatus.Rejected;
  meta: { action: Action.commentSubscribeAdmin; request: CommentAdminApi.CommentSubscribeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentUnsubscribeAdminActionStatus {
  Pending = 'commentUnsubscribeAdmin_PENDING',
  Fulfilled = 'commentUnsubscribeAdmin_FULFILLED',
  Rejected = 'commentUnsubscribeAdmin_REJECTED',
}
export interface commentUnsubscribeAdminActionFulfilled {
  type: commentUnsubscribeAdminActionStatus.Fulfilled;
  meta: { action: Action.commentUnsubscribeAdmin; request: CommentAdminApi.CommentUnsubscribeAdminRequest; };
  payload: void;
}
export interface commentUnsubscribeAdminActionPending {
  type: commentUnsubscribeAdminActionStatus.Pending;
  meta: { action: Action.commentUnsubscribeAdmin; request: CommentAdminApi.CommentUnsubscribeAdminRequest; };
}
export interface commentUnsubscribeAdminActionRejected {
  type: commentUnsubscribeAdminActionStatus.Rejected;
  meta: { action: Action.commentUnsubscribeAdmin; request: CommentAdminApi.CommentUnsubscribeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum contentUploadAsAdminActionStatus {
  Pending = 'contentUploadAsAdmin_PENDING',
  Fulfilled = 'contentUploadAsAdmin_FULFILLED',
  Rejected = 'contentUploadAsAdmin_REJECTED',
}
export interface contentUploadAsAdminActionFulfilled {
  type: contentUploadAsAdminActionStatus.Fulfilled;
  meta: { action: Action.contentUploadAsAdmin; request: ContentAdminApi.ContentUploadAsAdminRequest; };
  payload: Model.ContentUploadAsAdminResponse;
}
export interface contentUploadAsAdminActionPending {
  type: contentUploadAsAdminActionStatus.Pending;
  meta: { action: Action.contentUploadAsAdmin; request: ContentAdminApi.ContentUploadAsAdminRequest; };
}
export interface contentUploadAsAdminActionRejected {
  type: contentUploadAsAdminActionStatus.Rejected;
  meta: { action: Action.contentUploadAsAdmin; request: ContentAdminApi.ContentUploadAsAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum creditIncomeActionStatus {
  Pending = 'creditIncome_PENDING',
  Fulfilled = 'creditIncome_FULFILLED',
  Rejected = 'creditIncome_REJECTED',
}
export interface creditIncomeActionFulfilled {
  type: creditIncomeActionStatus.Fulfilled;
  meta: { action: Action.creditIncome; request: CreditAdminApi.CreditIncomeRequest; };
  payload: void;
}
export interface creditIncomeActionPending {
  type: creditIncomeActionStatus.Pending;
  meta: { action: Action.creditIncome; request: CreditAdminApi.CreditIncomeRequest; };
}
export interface creditIncomeActionRejected {
  type: creditIncomeActionStatus.Rejected;
  meta: { action: Action.creditIncome; request: CreditAdminApi.CreditIncomeRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum transactionSearchAdminActionStatus {
  Pending = 'transactionSearchAdmin_PENDING',
  Fulfilled = 'transactionSearchAdmin_FULFILLED',
  Rejected = 'transactionSearchAdmin_REJECTED',
}
export interface transactionSearchAdminActionFulfilled {
  type: transactionSearchAdminActionStatus.Fulfilled;
  meta: { action: Action.transactionSearchAdmin; request: CreditAdminApi.TransactionSearchAdminRequest; };
  payload: Model.TransactionSearchAdminResponse;
}
export interface transactionSearchAdminActionPending {
  type: transactionSearchAdminActionStatus.Pending;
  meta: { action: Action.transactionSearchAdmin; request: CreditAdminApi.TransactionSearchAdminRequest; };
}
export interface transactionSearchAdminActionRejected {
  type: transactionSearchAdminActionStatus.Rejected;
  meta: { action: Action.transactionSearchAdmin; request: CreditAdminApi.TransactionSearchAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaCategoryAggregateAdminActionStatus {
  Pending = 'ideaCategoryAggregateAdmin_PENDING',
  Fulfilled = 'ideaCategoryAggregateAdmin_FULFILLED',
  Rejected = 'ideaCategoryAggregateAdmin_REJECTED',
}
export interface ideaCategoryAggregateAdminActionFulfilled {
  type: ideaCategoryAggregateAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaCategoryAggregateAdmin; request: IdeaAdminApi.IdeaCategoryAggregateAdminRequest; };
  payload: Model.IdeaAggregateResponse;
}
export interface ideaCategoryAggregateAdminActionPending {
  type: ideaCategoryAggregateAdminActionStatus.Pending;
  meta: { action: Action.ideaCategoryAggregateAdmin; request: IdeaAdminApi.IdeaCategoryAggregateAdminRequest; };
}
export interface ideaCategoryAggregateAdminActionRejected {
  type: ideaCategoryAggregateAdminActionStatus.Rejected;
  meta: { action: Action.ideaCategoryAggregateAdmin; request: IdeaAdminApi.IdeaCategoryAggregateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaCreateAdminActionStatus {
  Pending = 'ideaCreateAdmin_PENDING',
  Fulfilled = 'ideaCreateAdmin_FULFILLED',
  Rejected = 'ideaCreateAdmin_REJECTED',
}
export interface ideaCreateAdminActionFulfilled {
  type: ideaCreateAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaCreateAdmin; request: IdeaAdminApi.IdeaCreateAdminRequest; };
  payload: Model.IdeaWithVote;
}
export interface ideaCreateAdminActionPending {
  type: ideaCreateAdminActionStatus.Pending;
  meta: { action: Action.ideaCreateAdmin; request: IdeaAdminApi.IdeaCreateAdminRequest; };
}
export interface ideaCreateAdminActionRejected {
  type: ideaCreateAdminActionStatus.Rejected;
  meta: { action: Action.ideaCreateAdmin; request: IdeaAdminApi.IdeaCreateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDeleteAdminActionStatus {
  Pending = 'ideaDeleteAdmin_PENDING',
  Fulfilled = 'ideaDeleteAdmin_FULFILLED',
  Rejected = 'ideaDeleteAdmin_REJECTED',
}
export interface ideaDeleteAdminActionFulfilled {
  type: ideaDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDeleteAdmin; request: IdeaAdminApi.IdeaDeleteAdminRequest; };
  payload: void;
}
export interface ideaDeleteAdminActionPending {
  type: ideaDeleteAdminActionStatus.Pending;
  meta: { action: Action.ideaDeleteAdmin; request: IdeaAdminApi.IdeaDeleteAdminRequest; };
}
export interface ideaDeleteAdminActionRejected {
  type: ideaDeleteAdminActionStatus.Rejected;
  meta: { action: Action.ideaDeleteAdmin; request: IdeaAdminApi.IdeaDeleteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDeleteBulkAdminActionStatus {
  Pending = 'ideaDeleteBulkAdmin_PENDING',
  Fulfilled = 'ideaDeleteBulkAdmin_FULFILLED',
  Rejected = 'ideaDeleteBulkAdmin_REJECTED',
}
export interface ideaDeleteBulkAdminActionFulfilled {
  type: ideaDeleteBulkAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDeleteBulkAdmin; request: IdeaAdminApi.IdeaDeleteBulkAdminRequest; };
  payload: void;
}
export interface ideaDeleteBulkAdminActionPending {
  type: ideaDeleteBulkAdminActionStatus.Pending;
  meta: { action: Action.ideaDeleteBulkAdmin; request: IdeaAdminApi.IdeaDeleteBulkAdminRequest; };
}
export interface ideaDeleteBulkAdminActionRejected {
  type: ideaDeleteBulkAdminActionStatus.Rejected;
  meta: { action: Action.ideaDeleteBulkAdmin; request: IdeaAdminApi.IdeaDeleteBulkAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDraftCreateAdminActionStatus {
  Pending = 'ideaDraftCreateAdmin_PENDING',
  Fulfilled = 'ideaDraftCreateAdmin_FULFILLED',
  Rejected = 'ideaDraftCreateAdmin_REJECTED',
}
export interface ideaDraftCreateAdminActionFulfilled {
  type: ideaDraftCreateAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDraftCreateAdmin; request: IdeaAdminApi.IdeaDraftCreateAdminRequest; };
  payload: Model.IdeaDraftAdmin;
}
export interface ideaDraftCreateAdminActionPending {
  type: ideaDraftCreateAdminActionStatus.Pending;
  meta: { action: Action.ideaDraftCreateAdmin; request: IdeaAdminApi.IdeaDraftCreateAdminRequest; };
}
export interface ideaDraftCreateAdminActionRejected {
  type: ideaDraftCreateAdminActionStatus.Rejected;
  meta: { action: Action.ideaDraftCreateAdmin; request: IdeaAdminApi.IdeaDraftCreateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDraftDeleteAdminActionStatus {
  Pending = 'ideaDraftDeleteAdmin_PENDING',
  Fulfilled = 'ideaDraftDeleteAdmin_FULFILLED',
  Rejected = 'ideaDraftDeleteAdmin_REJECTED',
}
export interface ideaDraftDeleteAdminActionFulfilled {
  type: ideaDraftDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDraftDeleteAdmin; request: IdeaAdminApi.IdeaDraftDeleteAdminRequest; };
  payload: void;
}
export interface ideaDraftDeleteAdminActionPending {
  type: ideaDraftDeleteAdminActionStatus.Pending;
  meta: { action: Action.ideaDraftDeleteAdmin; request: IdeaAdminApi.IdeaDraftDeleteAdminRequest; };
}
export interface ideaDraftDeleteAdminActionRejected {
  type: ideaDraftDeleteAdminActionStatus.Rejected;
  meta: { action: Action.ideaDraftDeleteAdmin; request: IdeaAdminApi.IdeaDraftDeleteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDraftGetAdminActionStatus {
  Pending = 'ideaDraftGetAdmin_PENDING',
  Fulfilled = 'ideaDraftGetAdmin_FULFILLED',
  Rejected = 'ideaDraftGetAdmin_REJECTED',
}
export interface ideaDraftGetAdminActionFulfilled {
  type: ideaDraftGetAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDraftGetAdmin; request: IdeaAdminApi.IdeaDraftGetAdminRequest; };
  payload: Model.IdeaDraftAdmin;
}
export interface ideaDraftGetAdminActionPending {
  type: ideaDraftGetAdminActionStatus.Pending;
  meta: { action: Action.ideaDraftGetAdmin; request: IdeaAdminApi.IdeaDraftGetAdminRequest; };
}
export interface ideaDraftGetAdminActionRejected {
  type: ideaDraftGetAdminActionStatus.Rejected;
  meta: { action: Action.ideaDraftGetAdmin; request: IdeaAdminApi.IdeaDraftGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDraftSearchAdminActionStatus {
  Pending = 'ideaDraftSearchAdmin_PENDING',
  Fulfilled = 'ideaDraftSearchAdmin_FULFILLED',
  Rejected = 'ideaDraftSearchAdmin_REJECTED',
}
export interface ideaDraftSearchAdminActionFulfilled {
  type: ideaDraftSearchAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDraftSearchAdmin; request: IdeaAdminApi.IdeaDraftSearchAdminRequest; };
  payload: Model.IdeaDraftSearchResponse;
}
export interface ideaDraftSearchAdminActionPending {
  type: ideaDraftSearchAdminActionStatus.Pending;
  meta: { action: Action.ideaDraftSearchAdmin; request: IdeaAdminApi.IdeaDraftSearchAdminRequest; };
}
export interface ideaDraftSearchAdminActionRejected {
  type: ideaDraftSearchAdminActionStatus.Rejected;
  meta: { action: Action.ideaDraftSearchAdmin; request: IdeaAdminApi.IdeaDraftSearchAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDraftUpdateAdminActionStatus {
  Pending = 'ideaDraftUpdateAdmin_PENDING',
  Fulfilled = 'ideaDraftUpdateAdmin_FULFILLED',
  Rejected = 'ideaDraftUpdateAdmin_REJECTED',
}
export interface ideaDraftUpdateAdminActionFulfilled {
  type: ideaDraftUpdateAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaDraftUpdateAdmin; request: IdeaAdminApi.IdeaDraftUpdateAdminRequest; };
  payload: void;
}
export interface ideaDraftUpdateAdminActionPending {
  type: ideaDraftUpdateAdminActionStatus.Pending;
  meta: { action: Action.ideaDraftUpdateAdmin; request: IdeaAdminApi.IdeaDraftUpdateAdminRequest; };
}
export interface ideaDraftUpdateAdminActionRejected {
  type: ideaDraftUpdateAdminActionStatus.Rejected;
  meta: { action: Action.ideaDraftUpdateAdmin; request: IdeaAdminApi.IdeaDraftUpdateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaGetAdminActionStatus {
  Pending = 'ideaGetAdmin_PENDING',
  Fulfilled = 'ideaGetAdmin_FULFILLED',
  Rejected = 'ideaGetAdmin_REJECTED',
}
export interface ideaGetAdminActionFulfilled {
  type: ideaGetAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaGetAdmin; request: IdeaAdminApi.IdeaGetAdminRequest; };
  payload: Model.Idea;
}
export interface ideaGetAdminActionPending {
  type: ideaGetAdminActionStatus.Pending;
  meta: { action: Action.ideaGetAdmin; request: IdeaAdminApi.IdeaGetAdminRequest; };
}
export interface ideaGetAdminActionRejected {
  type: ideaGetAdminActionStatus.Rejected;
  meta: { action: Action.ideaGetAdmin; request: IdeaAdminApi.IdeaGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaHistogramAdminActionStatus {
  Pending = 'ideaHistogramAdmin_PENDING',
  Fulfilled = 'ideaHistogramAdmin_FULFILLED',
  Rejected = 'ideaHistogramAdmin_REJECTED',
}
export interface ideaHistogramAdminActionFulfilled {
  type: ideaHistogramAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaHistogramAdmin; request: IdeaAdminApi.IdeaHistogramAdminRequest; };
  payload: Model.HistogramResponse;
}
export interface ideaHistogramAdminActionPending {
  type: ideaHistogramAdminActionStatus.Pending;
  meta: { action: Action.ideaHistogramAdmin; request: IdeaAdminApi.IdeaHistogramAdminRequest; };
}
export interface ideaHistogramAdminActionRejected {
  type: ideaHistogramAdminActionStatus.Rejected;
  meta: { action: Action.ideaHistogramAdmin; request: IdeaAdminApi.IdeaHistogramAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaLinkAdminActionStatus {
  Pending = 'ideaLinkAdmin_PENDING',
  Fulfilled = 'ideaLinkAdmin_FULFILLED',
  Rejected = 'ideaLinkAdmin_REJECTED',
}
export interface ideaLinkAdminActionFulfilled {
  type: ideaLinkAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaLinkAdmin; request: IdeaAdminApi.IdeaLinkAdminRequest; };
  payload: Model.IdeaConnectResponse;
}
export interface ideaLinkAdminActionPending {
  type: ideaLinkAdminActionStatus.Pending;
  meta: { action: Action.ideaLinkAdmin; request: IdeaAdminApi.IdeaLinkAdminRequest; };
}
export interface ideaLinkAdminActionRejected {
  type: ideaLinkAdminActionStatus.Rejected;
  meta: { action: Action.ideaLinkAdmin; request: IdeaAdminApi.IdeaLinkAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaMergeAdminActionStatus {
  Pending = 'ideaMergeAdmin_PENDING',
  Fulfilled = 'ideaMergeAdmin_FULFILLED',
  Rejected = 'ideaMergeAdmin_REJECTED',
}
export interface ideaMergeAdminActionFulfilled {
  type: ideaMergeAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaMergeAdmin; request: IdeaAdminApi.IdeaMergeAdminRequest; };
  payload: Model.IdeaConnectResponse;
}
export interface ideaMergeAdminActionPending {
  type: ideaMergeAdminActionStatus.Pending;
  meta: { action: Action.ideaMergeAdmin; request: IdeaAdminApi.IdeaMergeAdminRequest; };
}
export interface ideaMergeAdminActionRejected {
  type: ideaMergeAdminActionStatus.Rejected;
  meta: { action: Action.ideaMergeAdmin; request: IdeaAdminApi.IdeaMergeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaSearchAdminActionStatus {
  Pending = 'ideaSearchAdmin_PENDING',
  Fulfilled = 'ideaSearchAdmin_FULFILLED',
  Rejected = 'ideaSearchAdmin_REJECTED',
}
export interface ideaSearchAdminActionFulfilled {
  type: ideaSearchAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaSearchAdmin; request: IdeaAdminApi.IdeaSearchAdminRequest; };
  payload: Model.IdeaSearchResponse;
}
export interface ideaSearchAdminActionPending {
  type: ideaSearchAdminActionStatus.Pending;
  meta: { action: Action.ideaSearchAdmin; request: IdeaAdminApi.IdeaSearchAdminRequest; };
}
export interface ideaSearchAdminActionRejected {
  type: ideaSearchAdminActionStatus.Rejected;
  meta: { action: Action.ideaSearchAdmin; request: IdeaAdminApi.IdeaSearchAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaSubscribeAdminActionStatus {
  Pending = 'ideaSubscribeAdmin_PENDING',
  Fulfilled = 'ideaSubscribeAdmin_FULFILLED',
  Rejected = 'ideaSubscribeAdmin_REJECTED',
}
export interface ideaSubscribeAdminActionFulfilled {
  type: ideaSubscribeAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaSubscribeAdmin; request: IdeaAdminApi.IdeaSubscribeAdminRequest; };
  payload: void;
}
export interface ideaSubscribeAdminActionPending {
  type: ideaSubscribeAdminActionStatus.Pending;
  meta: { action: Action.ideaSubscribeAdmin; request: IdeaAdminApi.IdeaSubscribeAdminRequest; };
}
export interface ideaSubscribeAdminActionRejected {
  type: ideaSubscribeAdminActionStatus.Rejected;
  meta: { action: Action.ideaSubscribeAdmin; request: IdeaAdminApi.IdeaSubscribeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaUnLinkAdminActionStatus {
  Pending = 'ideaUnLinkAdmin_PENDING',
  Fulfilled = 'ideaUnLinkAdmin_FULFILLED',
  Rejected = 'ideaUnLinkAdmin_REJECTED',
}
export interface ideaUnLinkAdminActionFulfilled {
  type: ideaUnLinkAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaUnLinkAdmin; request: IdeaAdminApi.IdeaUnLinkAdminRequest; };
  payload: Model.IdeaConnectResponse;
}
export interface ideaUnLinkAdminActionPending {
  type: ideaUnLinkAdminActionStatus.Pending;
  meta: { action: Action.ideaUnLinkAdmin; request: IdeaAdminApi.IdeaUnLinkAdminRequest; };
}
export interface ideaUnLinkAdminActionRejected {
  type: ideaUnLinkAdminActionStatus.Rejected;
  meta: { action: Action.ideaUnLinkAdmin; request: IdeaAdminApi.IdeaUnLinkAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaUnMergeAdminActionStatus {
  Pending = 'ideaUnMergeAdmin_PENDING',
  Fulfilled = 'ideaUnMergeAdmin_FULFILLED',
  Rejected = 'ideaUnMergeAdmin_REJECTED',
}
export interface ideaUnMergeAdminActionFulfilled {
  type: ideaUnMergeAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaUnMergeAdmin; request: IdeaAdminApi.IdeaUnMergeAdminRequest; };
  payload: Model.IdeaConnectResponse;
}
export interface ideaUnMergeAdminActionPending {
  type: ideaUnMergeAdminActionStatus.Pending;
  meta: { action: Action.ideaUnMergeAdmin; request: IdeaAdminApi.IdeaUnMergeAdminRequest; };
}
export interface ideaUnMergeAdminActionRejected {
  type: ideaUnMergeAdminActionStatus.Rejected;
  meta: { action: Action.ideaUnMergeAdmin; request: IdeaAdminApi.IdeaUnMergeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaUnsubscribeAdminActionStatus {
  Pending = 'ideaUnsubscribeAdmin_PENDING',
  Fulfilled = 'ideaUnsubscribeAdmin_FULFILLED',
  Rejected = 'ideaUnsubscribeAdmin_REJECTED',
}
export interface ideaUnsubscribeAdminActionFulfilled {
  type: ideaUnsubscribeAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaUnsubscribeAdmin; request: IdeaAdminApi.IdeaUnsubscribeAdminRequest; };
  payload: void;
}
export interface ideaUnsubscribeAdminActionPending {
  type: ideaUnsubscribeAdminActionStatus.Pending;
  meta: { action: Action.ideaUnsubscribeAdmin; request: IdeaAdminApi.IdeaUnsubscribeAdminRequest; };
}
export interface ideaUnsubscribeAdminActionRejected {
  type: ideaUnsubscribeAdminActionStatus.Rejected;
  meta: { action: Action.ideaUnsubscribeAdmin; request: IdeaAdminApi.IdeaUnsubscribeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaUpdateAdminActionStatus {
  Pending = 'ideaUpdateAdmin_PENDING',
  Fulfilled = 'ideaUpdateAdmin_FULFILLED',
  Rejected = 'ideaUpdateAdmin_REJECTED',
}
export interface ideaUpdateAdminActionFulfilled {
  type: ideaUpdateAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaUpdateAdmin; request: IdeaAdminApi.IdeaUpdateAdminRequest; };
  payload: Model.Idea;
}
export interface ideaUpdateAdminActionPending {
  type: ideaUpdateAdminActionStatus.Pending;
  meta: { action: Action.ideaUpdateAdmin; request: IdeaAdminApi.IdeaUpdateAdminRequest; };
}
export interface ideaUpdateAdminActionRejected {
  type: ideaUpdateAdminActionStatus.Rejected;
  meta: { action: Action.ideaUpdateAdmin; request: IdeaAdminApi.IdeaUpdateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaVotersGetAdminActionStatus {
  Pending = 'ideaVotersGetAdmin_PENDING',
  Fulfilled = 'ideaVotersGetAdmin_FULFILLED',
  Rejected = 'ideaVotersGetAdmin_REJECTED',
}
export interface ideaVotersGetAdminActionFulfilled {
  type: ideaVotersGetAdminActionStatus.Fulfilled;
  meta: { action: Action.ideaVotersGetAdmin; request: IdeaAdminApi.IdeaVotersGetAdminRequest; };
  payload: Model.IdeaVotersAdminResponse;
}
export interface ideaVotersGetAdminActionPending {
  type: ideaVotersGetAdminActionStatus.Pending;
  meta: { action: Action.ideaVotersGetAdmin; request: IdeaAdminApi.IdeaVotersGetAdminRequest; };
}
export interface ideaVotersGetAdminActionRejected {
  type: ideaVotersGetAdminActionStatus.Rejected;
  meta: { action: Action.ideaVotersGetAdmin; request: IdeaAdminApi.IdeaVotersGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum convoDeleteAdminActionStatus {
  Pending = 'convoDeleteAdmin_PENDING',
  Fulfilled = 'convoDeleteAdmin_FULFILLED',
  Rejected = 'convoDeleteAdmin_REJECTED',
}
export interface convoDeleteAdminActionFulfilled {
  type: convoDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.convoDeleteAdmin; request: LlmAdminApi.ConvoDeleteAdminRequest; };
  payload: void;
}
export interface convoDeleteAdminActionPending {
  type: convoDeleteAdminActionStatus.Pending;
  meta: { action: Action.convoDeleteAdmin; request: LlmAdminApi.ConvoDeleteAdminRequest; };
}
export interface convoDeleteAdminActionRejected {
  type: convoDeleteAdminActionStatus.Rejected;
  meta: { action: Action.convoDeleteAdmin; request: LlmAdminApi.ConvoDeleteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum convoDetailsAdminActionStatus {
  Pending = 'convoDetailsAdmin_PENDING',
  Fulfilled = 'convoDetailsAdmin_FULFILLED',
  Rejected = 'convoDetailsAdmin_REJECTED',
}
export interface convoDetailsAdminActionFulfilled {
  type: convoDetailsAdminActionStatus.Fulfilled;
  meta: { action: Action.convoDetailsAdmin; request: LlmAdminApi.ConvoDetailsAdminRequest; };
  payload: Model.ConvoDetailsResponse;
}
export interface convoDetailsAdminActionPending {
  type: convoDetailsAdminActionStatus.Pending;
  meta: { action: Action.convoDetailsAdmin; request: LlmAdminApi.ConvoDetailsAdminRequest; };
}
export interface convoDetailsAdminActionRejected {
  type: convoDetailsAdminActionStatus.Rejected;
  meta: { action: Action.convoDetailsAdmin; request: LlmAdminApi.ConvoDetailsAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum convoListAdminActionStatus {
  Pending = 'convoListAdmin_PENDING',
  Fulfilled = 'convoListAdmin_FULFILLED',
  Rejected = 'convoListAdmin_REJECTED',
}
export interface convoListAdminActionFulfilled {
  type: convoListAdminActionStatus.Fulfilled;
  meta: { action: Action.convoListAdmin; request: LlmAdminApi.ConvoListAdminRequest; };
  payload: Model.ConvoListResponse;
}
export interface convoListAdminActionPending {
  type: convoListAdminActionStatus.Pending;
  meta: { action: Action.convoListAdmin; request: LlmAdminApi.ConvoListAdminRequest; };
}
export interface convoListAdminActionRejected {
  type: convoListAdminActionStatus.Rejected;
  meta: { action: Action.convoListAdmin; request: LlmAdminApi.ConvoListAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum messageCreateAdminActionStatus {
  Pending = 'messageCreateAdmin_PENDING',
  Fulfilled = 'messageCreateAdmin_FULFILLED',
  Rejected = 'messageCreateAdmin_REJECTED',
}
export interface messageCreateAdminActionFulfilled {
  type: messageCreateAdminActionStatus.Fulfilled;
  meta: { action: Action.messageCreateAdmin; request: LlmAdminApi.MessageCreateAdminRequest; };
  payload: Model.CreateMessageResponse;
}
export interface messageCreateAdminActionPending {
  type: messageCreateAdminActionStatus.Pending;
  meta: { action: Action.messageCreateAdmin; request: LlmAdminApi.MessageCreateAdminRequest; };
}
export interface messageCreateAdminActionRejected {
  type: messageCreateAdminActionStatus.Rejected;
  meta: { action: Action.messageCreateAdmin; request: LlmAdminApi.MessageCreateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum messageStreamGetAdminActionStatus {
  Pending = 'messageStreamGetAdmin_PENDING',
  Fulfilled = 'messageStreamGetAdmin_FULFILLED',
  Rejected = 'messageStreamGetAdmin_REJECTED',
}
export interface messageStreamGetAdminActionFulfilled {
  type: messageStreamGetAdminActionStatus.Fulfilled;
  meta: { action: Action.messageStreamGetAdmin; request: LlmAdminApi.MessageStreamGetAdminRequest; };
  payload: void;
}
export interface messageStreamGetAdminActionPending {
  type: messageStreamGetAdminActionStatus.Pending;
  meta: { action: Action.messageStreamGetAdmin; request: LlmAdminApi.MessageStreamGetAdminRequest; };
}
export interface messageStreamGetAdminActionRejected {
  type: messageStreamGetAdminActionStatus.Rejected;
  meta: { action: Action.messageStreamGetAdmin; request: LlmAdminApi.MessageStreamGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum promptGetSuperAdminActionStatus {
  Pending = 'promptGetSuperAdmin_PENDING',
  Fulfilled = 'promptGetSuperAdmin_FULFILLED',
  Rejected = 'promptGetSuperAdmin_REJECTED',
}
export interface promptGetSuperAdminActionFulfilled {
  type: promptGetSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.promptGetSuperAdmin; request: LlmSuperAdminApi.PromptGetSuperAdminRequest; };
  payload: Model.PromptGetResponse;
}
export interface promptGetSuperAdminActionPending {
  type: promptGetSuperAdminActionStatus.Pending;
  meta: { action: Action.promptGetSuperAdmin; request: LlmSuperAdminApi.PromptGetSuperAdminRequest; };
}
export interface promptGetSuperAdminActionRejected {
  type: promptGetSuperAdminActionStatus.Rejected;
  meta: { action: Action.promptGetSuperAdmin; request: LlmSuperAdminApi.PromptGetSuperAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum plansGetActionStatus {
  Pending = 'plansGet_PENDING',
  Fulfilled = 'plansGet_FULFILLED',
  Rejected = 'plansGet_REJECTED',
}
export interface plansGetActionFulfilled {
  type: plansGetActionStatus.Fulfilled;
  meta: { action: Action.plansGet; request: undefined; };
  payload: Model.PlansGetResponse;
}
export interface plansGetActionPending {
  type: plansGetActionStatus.Pending;
  meta: { action: Action.plansGet; request: undefined; };
}
export interface plansGetActionRejected {
  type: plansGetActionStatus.Rejected;
  meta: { action: Action.plansGet; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum plansGetSuperAdminActionStatus {
  Pending = 'plansGetSuperAdmin_PENDING',
  Fulfilled = 'plansGetSuperAdmin_FULFILLED',
  Rejected = 'plansGetSuperAdmin_REJECTED',
}
export interface plansGetSuperAdminActionFulfilled {
  type: plansGetSuperAdminActionStatus.Fulfilled;
  meta: { action: Action.plansGetSuperAdmin; request: undefined; };
  payload: Model.AllPlansGetResponse;
}
export interface plansGetSuperAdminActionPending {
  type: plansGetSuperAdminActionStatus.Pending;
  meta: { action: Action.plansGetSuperAdmin; request: undefined; };
}
export interface plansGetSuperAdminActionRejected {
  type: plansGetSuperAdminActionStatus.Rejected;
  meta: { action: Action.plansGetSuperAdmin; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum configGetAdminActionStatus {
  Pending = 'configGetAdmin_PENDING',
  Fulfilled = 'configGetAdmin_FULFILLED',
  Rejected = 'configGetAdmin_REJECTED',
}
export interface configGetAdminActionFulfilled {
  type: configGetAdminActionStatus.Fulfilled;
  meta: { action: Action.configGetAdmin; request: ProjectAdminApi.ConfigGetAdminRequest; };
  payload: Model.VersionedConfigAdmin;
}
export interface configGetAdminActionPending {
  type: configGetAdminActionStatus.Pending;
  meta: { action: Action.configGetAdmin; request: ProjectAdminApi.ConfigGetAdminRequest; };
}
export interface configGetAdminActionRejected {
  type: configGetAdminActionStatus.Rejected;
  meta: { action: Action.configGetAdmin; request: ProjectAdminApi.ConfigGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum configGetAllAndUserBindAllAdminActionStatus {
  Pending = 'configGetAllAndUserBindAllAdmin_PENDING',
  Fulfilled = 'configGetAllAndUserBindAllAdmin_FULFILLED',
  Rejected = 'configGetAllAndUserBindAllAdmin_REJECTED',
}
export interface configGetAllAndUserBindAllAdminActionFulfilled {
  type: configGetAllAndUserBindAllAdminActionStatus.Fulfilled;
  meta: { action: Action.configGetAllAndUserBindAllAdmin; request: undefined; };
  payload: Model.ConfigAndBindAllResult;
}
export interface configGetAllAndUserBindAllAdminActionPending {
  type: configGetAllAndUserBindAllAdminActionStatus.Pending;
  meta: { action: Action.configGetAllAndUserBindAllAdmin; request: undefined; };
}
export interface configGetAllAndUserBindAllAdminActionRejected {
  type: configGetAllAndUserBindAllAdminActionStatus.Rejected;
  meta: { action: Action.configGetAllAndUserBindAllAdmin; request: undefined; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum configSetAdminActionStatus {
  Pending = 'configSetAdmin_PENDING',
  Fulfilled = 'configSetAdmin_FULFILLED',
  Rejected = 'configSetAdmin_REJECTED',
}
export interface configSetAdminActionFulfilled {
  type: configSetAdminActionStatus.Fulfilled;
  meta: { action: Action.configSetAdmin; request: ProjectAdminApi.ConfigSetAdminRequest; };
  payload: Model.VersionedConfigAdmin;
}
export interface configSetAdminActionPending {
  type: configSetAdminActionStatus.Pending;
  meta: { action: Action.configSetAdmin; request: ProjectAdminApi.ConfigSetAdminRequest; };
}
export interface configSetAdminActionRejected {
  type: configSetAdminActionStatus.Rejected;
  meta: { action: Action.configSetAdmin; request: ProjectAdminApi.ConfigSetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectAdminsInviteAdminActionStatus {
  Pending = 'projectAdminsInviteAdmin_PENDING',
  Fulfilled = 'projectAdminsInviteAdmin_FULFILLED',
  Rejected = 'projectAdminsInviteAdmin_REJECTED',
}
export interface projectAdminsInviteAdminActionFulfilled {
  type: projectAdminsInviteAdminActionStatus.Fulfilled;
  meta: { action: Action.projectAdminsInviteAdmin; request: ProjectAdminApi.ProjectAdminsInviteAdminRequest; };
  payload: Model.ProjectAdminsInviteResult;
}
export interface projectAdminsInviteAdminActionPending {
  type: projectAdminsInviteAdminActionStatus.Pending;
  meta: { action: Action.projectAdminsInviteAdmin; request: ProjectAdminApi.ProjectAdminsInviteAdminRequest; };
}
export interface projectAdminsInviteAdminActionRejected {
  type: projectAdminsInviteAdminActionStatus.Rejected;
  meta: { action: Action.projectAdminsInviteAdmin; request: ProjectAdminApi.ProjectAdminsInviteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectAdminsListAdminActionStatus {
  Pending = 'projectAdminsListAdmin_PENDING',
  Fulfilled = 'projectAdminsListAdmin_FULFILLED',
  Rejected = 'projectAdminsListAdmin_REJECTED',
}
export interface projectAdminsListAdminActionFulfilled {
  type: projectAdminsListAdminActionStatus.Fulfilled;
  meta: { action: Action.projectAdminsListAdmin; request: ProjectAdminApi.ProjectAdminsListAdminRequest; };
  payload: Model.ProjectAdminsListResult;
}
export interface projectAdminsListAdminActionPending {
  type: projectAdminsListAdminActionStatus.Pending;
  meta: { action: Action.projectAdminsListAdmin; request: ProjectAdminApi.ProjectAdminsListAdminRequest; };
}
export interface projectAdminsListAdminActionRejected {
  type: projectAdminsListAdminActionStatus.Rejected;
  meta: { action: Action.projectAdminsListAdmin; request: ProjectAdminApi.ProjectAdminsListAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectAdminsRemoveAdminActionStatus {
  Pending = 'projectAdminsRemoveAdmin_PENDING',
  Fulfilled = 'projectAdminsRemoveAdmin_FULFILLED',
  Rejected = 'projectAdminsRemoveAdmin_REJECTED',
}
export interface projectAdminsRemoveAdminActionFulfilled {
  type: projectAdminsRemoveAdminActionStatus.Fulfilled;
  meta: { action: Action.projectAdminsRemoveAdmin; request: ProjectAdminApi.ProjectAdminsRemoveAdminRequest; };
  payload: void;
}
export interface projectAdminsRemoveAdminActionPending {
  type: projectAdminsRemoveAdminActionStatus.Pending;
  meta: { action: Action.projectAdminsRemoveAdmin; request: ProjectAdminApi.ProjectAdminsRemoveAdminRequest; };
}
export interface projectAdminsRemoveAdminActionRejected {
  type: projectAdminsRemoveAdminActionStatus.Rejected;
  meta: { action: Action.projectAdminsRemoveAdmin; request: ProjectAdminApi.ProjectAdminsRemoveAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectCreateAdminActionStatus {
  Pending = 'projectCreateAdmin_PENDING',
  Fulfilled = 'projectCreateAdmin_FULFILLED',
  Rejected = 'projectCreateAdmin_REJECTED',
}
export interface projectCreateAdminActionFulfilled {
  type: projectCreateAdminActionStatus.Fulfilled;
  meta: { action: Action.projectCreateAdmin; request: ProjectAdminApi.ProjectCreateAdminRequest; };
  payload: Model.NewProjectResult;
}
export interface projectCreateAdminActionPending {
  type: projectCreateAdminActionStatus.Pending;
  meta: { action: Action.projectCreateAdmin; request: ProjectAdminApi.ProjectCreateAdminRequest; };
}
export interface projectCreateAdminActionRejected {
  type: projectCreateAdminActionStatus.Rejected;
  meta: { action: Action.projectCreateAdmin; request: ProjectAdminApi.ProjectCreateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectDeleteAdminActionStatus {
  Pending = 'projectDeleteAdmin_PENDING',
  Fulfilled = 'projectDeleteAdmin_FULFILLED',
  Rejected = 'projectDeleteAdmin_REJECTED',
}
export interface projectDeleteAdminActionFulfilled {
  type: projectDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.projectDeleteAdmin; request: ProjectAdminApi.ProjectDeleteAdminRequest; };
  payload: void;
}
export interface projectDeleteAdminActionPending {
  type: projectDeleteAdminActionStatus.Pending;
  meta: { action: Action.projectDeleteAdmin; request: ProjectAdminApi.ProjectDeleteAdminRequest; };
}
export interface projectDeleteAdminActionRejected {
  type: projectDeleteAdminActionStatus.Rejected;
  meta: { action: Action.projectDeleteAdmin; request: ProjectAdminApi.ProjectDeleteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectExportAdminActionStatus {
  Pending = 'projectExportAdmin_PENDING',
  Fulfilled = 'projectExportAdmin_FULFILLED',
  Rejected = 'projectExportAdmin_REJECTED',
}
export interface projectExportAdminActionFulfilled {
  type: projectExportAdminActionStatus.Fulfilled;
  meta: { action: Action.projectExportAdmin; request: ProjectAdminApi.ProjectExportAdminRequest; };
  payload: runtime.FileDownload;
}
export interface projectExportAdminActionPending {
  type: projectExportAdminActionStatus.Pending;
  meta: { action: Action.projectExportAdmin; request: ProjectAdminApi.ProjectExportAdminRequest; };
}
export interface projectExportAdminActionRejected {
  type: projectExportAdminActionStatus.Rejected;
  meta: { action: Action.projectExportAdmin; request: ProjectAdminApi.ProjectExportAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum projectImportPostAdminActionStatus {
  Pending = 'projectImportPostAdmin_PENDING',
  Fulfilled = 'projectImportPostAdmin_FULFILLED',
  Rejected = 'projectImportPostAdmin_REJECTED',
}
export interface projectImportPostAdminActionFulfilled {
  type: projectImportPostAdminActionStatus.Fulfilled;
  meta: { action: Action.projectImportPostAdmin; request: ProjectAdminApi.ProjectImportPostAdminRequest; };
  payload: Model.ImportResponse;
}
export interface projectImportPostAdminActionPending {
  type: projectImportPostAdminActionStatus.Pending;
  meta: { action: Action.projectImportPostAdmin; request: ProjectAdminApi.ProjectImportPostAdminRequest; };
}
export interface projectImportPostAdminActionRejected {
  type: projectImportPostAdminActionStatus.Rejected;
  meta: { action: Action.projectImportPostAdmin; request: ProjectAdminApi.ProjectImportPostAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum supportMessageActionStatus {
  Pending = 'supportMessage_PENDING',
  Fulfilled = 'supportMessage_FULFILLED',
  Rejected = 'supportMessage_REJECTED',
}
export interface supportMessageActionFulfilled {
  type: supportMessageActionStatus.Fulfilled;
  meta: { action: Action.supportMessage; request: SupportApi.SupportMessageRequest; };
  payload: void;
}
export interface supportMessageActionPending {
  type: supportMessageActionStatus.Pending;
  meta: { action: Action.supportMessage; request: SupportApi.SupportMessageRequest; };
}
export interface supportMessageActionRejected {
  type: supportMessageActionStatus.Rejected;
  meta: { action: Action.supportMessage; request: SupportApi.SupportMessageRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userCreateAdminActionStatus {
  Pending = 'userCreateAdmin_PENDING',
  Fulfilled = 'userCreateAdmin_FULFILLED',
  Rejected = 'userCreateAdmin_REJECTED',
}
export interface userCreateAdminActionFulfilled {
  type: userCreateAdminActionStatus.Fulfilled;
  meta: { action: Action.userCreateAdmin; request: UserAdminApi.UserCreateAdminRequest; };
  payload: Model.UserAdmin;
}
export interface userCreateAdminActionPending {
  type: userCreateAdminActionStatus.Pending;
  meta: { action: Action.userCreateAdmin; request: UserAdminApi.UserCreateAdminRequest; };
}
export interface userCreateAdminActionRejected {
  type: userCreateAdminActionStatus.Rejected;
  meta: { action: Action.userCreateAdmin; request: UserAdminApi.UserCreateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userDeleteAdminActionStatus {
  Pending = 'userDeleteAdmin_PENDING',
  Fulfilled = 'userDeleteAdmin_FULFILLED',
  Rejected = 'userDeleteAdmin_REJECTED',
}
export interface userDeleteAdminActionFulfilled {
  type: userDeleteAdminActionStatus.Fulfilled;
  meta: { action: Action.userDeleteAdmin; request: UserAdminApi.UserDeleteAdminRequest; };
  payload: void;
}
export interface userDeleteAdminActionPending {
  type: userDeleteAdminActionStatus.Pending;
  meta: { action: Action.userDeleteAdmin; request: UserAdminApi.UserDeleteAdminRequest; };
}
export interface userDeleteAdminActionRejected {
  type: userDeleteAdminActionStatus.Rejected;
  meta: { action: Action.userDeleteAdmin; request: UserAdminApi.UserDeleteAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userDeleteBulkAdminActionStatus {
  Pending = 'userDeleteBulkAdmin_PENDING',
  Fulfilled = 'userDeleteBulkAdmin_FULFILLED',
  Rejected = 'userDeleteBulkAdmin_REJECTED',
}
export interface userDeleteBulkAdminActionFulfilled {
  type: userDeleteBulkAdminActionStatus.Fulfilled;
  meta: { action: Action.userDeleteBulkAdmin; request: UserAdminApi.UserDeleteBulkAdminRequest; };
  payload: void;
}
export interface userDeleteBulkAdminActionPending {
  type: userDeleteBulkAdminActionStatus.Pending;
  meta: { action: Action.userDeleteBulkAdmin; request: UserAdminApi.UserDeleteBulkAdminRequest; };
}
export interface userDeleteBulkAdminActionRejected {
  type: userDeleteBulkAdminActionStatus.Rejected;
  meta: { action: Action.userDeleteBulkAdmin; request: UserAdminApi.UserDeleteBulkAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userGetAdminActionStatus {
  Pending = 'userGetAdmin_PENDING',
  Fulfilled = 'userGetAdmin_FULFILLED',
  Rejected = 'userGetAdmin_REJECTED',
}
export interface userGetAdminActionFulfilled {
  type: userGetAdminActionStatus.Fulfilled;
  meta: { action: Action.userGetAdmin; request: UserAdminApi.UserGetAdminRequest; };
  payload: Model.UserAdmin;
}
export interface userGetAdminActionPending {
  type: userGetAdminActionStatus.Pending;
  meta: { action: Action.userGetAdmin; request: UserAdminApi.UserGetAdminRequest; };
}
export interface userGetAdminActionRejected {
  type: userGetAdminActionStatus.Rejected;
  meta: { action: Action.userGetAdmin; request: UserAdminApi.UserGetAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userHistogramAdminActionStatus {
  Pending = 'userHistogramAdmin_PENDING',
  Fulfilled = 'userHistogramAdmin_FULFILLED',
  Rejected = 'userHistogramAdmin_REJECTED',
}
export interface userHistogramAdminActionFulfilled {
  type: userHistogramAdminActionStatus.Fulfilled;
  meta: { action: Action.userHistogramAdmin; request: UserAdminApi.UserHistogramAdminRequest; };
  payload: Model.HistogramResponse;
}
export interface userHistogramAdminActionPending {
  type: userHistogramAdminActionStatus.Pending;
  meta: { action: Action.userHistogramAdmin; request: UserAdminApi.UserHistogramAdminRequest; };
}
export interface userHistogramAdminActionRejected {
  type: userHistogramAdminActionStatus.Rejected;
  meta: { action: Action.userHistogramAdmin; request: UserAdminApi.UserHistogramAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userSearchAdminActionStatus {
  Pending = 'userSearchAdmin_PENDING',
  Fulfilled = 'userSearchAdmin_FULFILLED',
  Rejected = 'userSearchAdmin_REJECTED',
}
export interface userSearchAdminActionFulfilled {
  type: userSearchAdminActionStatus.Fulfilled;
  meta: { action: Action.userSearchAdmin; request: UserAdminApi.UserSearchAdminRequest; };
  payload: Model.UserSearchResponse;
}
export interface userSearchAdminActionPending {
  type: userSearchAdminActionStatus.Pending;
  meta: { action: Action.userSearchAdmin; request: UserAdminApi.UserSearchAdminRequest; };
}
export interface userSearchAdminActionRejected {
  type: userSearchAdminActionStatus.Rejected;
  meta: { action: Action.userSearchAdmin; request: UserAdminApi.UserSearchAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userSubscribeAdminActionStatus {
  Pending = 'userSubscribeAdmin_PENDING',
  Fulfilled = 'userSubscribeAdmin_FULFILLED',
  Rejected = 'userSubscribeAdmin_REJECTED',
}
export interface userSubscribeAdminActionFulfilled {
  type: userSubscribeAdminActionStatus.Fulfilled;
  meta: { action: Action.userSubscribeAdmin; request: UserAdminApi.UserSubscribeAdminRequest; };
  payload: void;
}
export interface userSubscribeAdminActionPending {
  type: userSubscribeAdminActionStatus.Pending;
  meta: { action: Action.userSubscribeAdmin; request: UserAdminApi.UserSubscribeAdminRequest; };
}
export interface userSubscribeAdminActionRejected {
  type: userSubscribeAdminActionStatus.Rejected;
  meta: { action: Action.userSubscribeAdmin; request: UserAdminApi.UserSubscribeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userUnsubscribeAdminActionStatus {
  Pending = 'userUnsubscribeAdmin_PENDING',
  Fulfilled = 'userUnsubscribeAdmin_FULFILLED',
  Rejected = 'userUnsubscribeAdmin_REJECTED',
}
export interface userUnsubscribeAdminActionFulfilled {
  type: userUnsubscribeAdminActionStatus.Fulfilled;
  meta: { action: Action.userUnsubscribeAdmin; request: UserAdminApi.UserUnsubscribeAdminRequest; };
  payload: void;
}
export interface userUnsubscribeAdminActionPending {
  type: userUnsubscribeAdminActionStatus.Pending;
  meta: { action: Action.userUnsubscribeAdmin; request: UserAdminApi.UserUnsubscribeAdminRequest; };
}
export interface userUnsubscribeAdminActionRejected {
  type: userUnsubscribeAdminActionStatus.Rejected;
  meta: { action: Action.userUnsubscribeAdmin; request: UserAdminApi.UserUnsubscribeAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userUpdateAdminActionStatus {
  Pending = 'userUpdateAdmin_PENDING',
  Fulfilled = 'userUpdateAdmin_FULFILLED',
  Rejected = 'userUpdateAdmin_REJECTED',
}
export interface userUpdateAdminActionFulfilled {
  type: userUpdateAdminActionStatus.Fulfilled;
  meta: { action: Action.userUpdateAdmin; request: UserAdminApi.UserUpdateAdminRequest; };
  payload: Model.UserAdmin;
}
export interface userUpdateAdminActionPending {
  type: userUpdateAdminActionStatus.Pending;
  meta: { action: Action.userUpdateAdmin; request: UserAdminApi.UserUpdateAdminRequest; };
}
export interface userUpdateAdminActionRejected {
  type: userUpdateAdminActionStatus.Rejected;
  meta: { action: Action.userUpdateAdmin; request: UserAdminApi.UserUpdateAdminRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export class Dispatcher implements ApiInterface {
  readonly apiDelegate:Api;
  readonly dispatcherDelegate:(msg:any)=>Promise<any>;
  constructor(dispatcherDelegate:(msg:any)=>Promise<any>, apiDelegate:Api) {
    this.dispatcherDelegate = dispatcherDelegate;
    this.apiDelegate = apiDelegate || Api;
  }

  accountAcceptCouponAdmin(request: AccountAdminApi.AccountAcceptCouponAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountAcceptCouponAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountAcceptCouponAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountAcceptCouponAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountAcceptCouponAdmin(request, headerExtra),
    });
  }

  accountAcceptInvitationAdmin(request: AccountAdminApi.AccountAcceptInvitationAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAcceptInvitationResponse> {
    return this.dispatcherDelegate({
      type: Action.accountAcceptInvitationAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountAcceptInvitationAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountAcceptInvitationAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountAcceptInvitationAdmin(request, headerExtra),
    });
  }

  accountAttrsUpdateAdmin(request: AccountAdminApi.AccountAttrsUpdateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountAttrsUpdateAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountAttrsUpdateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountAttrsUpdateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountAttrsUpdateAdmin(request, headerExtra),
    });
  }

  accountBillingAdmin(request: AccountAdminApi.AccountBillingAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountBilling> {
    return this.dispatcherDelegate({
      type: Action.accountBillingAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountBillingAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountBillingAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountBillingAdmin(request, headerExtra),
    });
  }

  accountBindAdmin(request: AccountAdminApi.AccountBindAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountBindAdminResponse> {
    return this.dispatcherDelegate({
      type: Action.accountBindAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountBindAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountBindAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountBindAdmin(request, headerExtra),
    });
  }

  accountDeleteAdmin(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.accountDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountDeleteAdmin,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountDeleteAdmin(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountDeleteAdmin(headerExtra),
    });
  }

  accountLoginAdmin(request: AccountAdminApi.AccountLoginAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountLoginAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountLoginAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountLoginAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountLoginAdmin(request, headerExtra),
    });
  }

  accountLogoutAdmin(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.accountLogoutAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountLogoutAdmin,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountLogoutAdmin(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountLogoutAdmin(headerExtra),
    });
  }

  accountNoopAdmin(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.accountNoopAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountNoopAdmin,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountNoopAdmin(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountNoopAdmin(headerExtra),
    });
  }

  accountSignupAdmin(request: AccountAdminApi.AccountSignupAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountSignupAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountSignupAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountSignupAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountSignupAdmin(request, headerExtra),
    });
  }

  accountUpdateAdmin(request: AccountAdminApi.AccountUpdateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountUpdateAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountUpdateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountUpdateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountUpdateAdmin(request, headerExtra),
    });
  }

  accountViewCouponAdmin(request: AccountAdminApi.AccountViewCouponAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ViewCouponResponse> {
    return this.dispatcherDelegate({
      type: Action.accountViewCouponAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountViewCouponAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountViewCouponAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountViewCouponAdmin(request, headerExtra),
    });
  }

  accountViewInvitationAdmin(request: AccountAdminApi.AccountViewInvitationAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.InvitationResult> {
    return this.dispatcherDelegate({
      type: Action.accountViewInvitationAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountViewInvitationAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountViewInvitationAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().accountViewInvitationAdmin(request, headerExtra),
    });
  }

  gitHubGetReposAdmin(request: AccountAdminApi.GitHubGetReposAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AvailableRepos> {
    return this.dispatcherDelegate({
      type: Action.gitHubGetReposAdmin,
      meta: {
        ...metaExtra,
        action: Action.gitHubGetReposAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.gitHubGetReposAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().gitHubGetReposAdmin(request, headerExtra),
    });
  }

  invoiceHtmlGetAdmin(request: AccountAdminApi.InvoiceHtmlGetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.InvoiceHtmlResponse> {
    return this.dispatcherDelegate({
      type: Action.invoiceHtmlGetAdmin,
      meta: {
        ...metaExtra,
        action: Action.invoiceHtmlGetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.invoiceHtmlGetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().invoiceHtmlGetAdmin(request, headerExtra),
    });
  }

  invoicesSearchAdmin(request: AccountAdminApi.InvoicesSearchAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Invoices> {
    return this.dispatcherDelegate({
      type: Action.invoicesSearchAdmin,
      meta: {
        ...metaExtra,
        action: Action.invoicesSearchAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.invoicesSearchAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().invoicesSearchAdmin(request, headerExtra),
    });
  }

  legalGet(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.LegalResponse> {
    return this.dispatcherDelegate({
      type: Action.legalGet,
      meta: {
        ...metaExtra,
        action: Action.legalGet,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.legalGet(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountAdminApi().legalGet(headerExtra),
    });
  }

  accountCreditAdjustmentSuperAdmin(request: AccountSuperAdminApi.AccountCreditAdjustmentSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.accountCreditAdjustmentSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountCreditAdjustmentSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountCreditAdjustmentSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountSuperAdminApi().accountCreditAdjustmentSuperAdmin(request, headerExtra),
    });
  }

  accountLoginAsSuperAdmin(request: AccountSuperAdminApi.AccountLoginAsSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountLoginAsSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountLoginAsSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountLoginAsSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountSuperAdminApi().accountLoginAsSuperAdmin(request, headerExtra),
    });
  }

  accountSearchSuperAdmin(request: AccountSuperAdminApi.AccountSearchSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.accountSearchSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountSearchSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountSearchSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountSuperAdminApi().accountSearchSuperAdmin(request, headerExtra),
    });
  }

  accountUpdateSuperAdmin(request: AccountSuperAdminApi.AccountUpdateSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AccountAdmin> {
    return this.dispatcherDelegate({
      type: Action.accountUpdateSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.accountUpdateSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.accountUpdateSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountSuperAdminApi().accountUpdateSuperAdmin(request, headerExtra),
    });
  }

  couponGenerateSuperAdmin(request: AccountSuperAdminApi.CouponGenerateSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<runtime.FileDownload> {
    return this.dispatcherDelegate({
      type: Action.couponGenerateSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.couponGenerateSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.couponGenerateSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountSuperAdminApi().couponGenerateSuperAdmin(request, headerExtra),
    });
  }

  projectOwnerSwapSuperAdmin(request: AccountSuperAdminApi.ProjectOwnerSwapSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.projectOwnerSwapSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectOwnerSwapSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectOwnerSwapSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getAccountSuperAdminApi().projectOwnerSwapSuperAdmin(request, headerExtra),
    });
  }

  commentDeleteAdmin(request: CommentAdminApi.CommentDeleteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Comment> {
    return this.dispatcherDelegate({
      type: Action.commentDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.commentDeleteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentDeleteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentAdminApi().commentDeleteAdmin(request, headerExtra),
    });
  }

  commentHistogramAdmin(request: CommentAdminApi.CommentHistogramAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.HistogramResponse> {
    return this.dispatcherDelegate({
      type: Action.commentHistogramAdmin,
      meta: {
        ...metaExtra,
        action: Action.commentHistogramAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentHistogramAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentAdminApi().commentHistogramAdmin(request, headerExtra),
    });
  }

  commentSearchAdmin(request: CommentAdminApi.CommentSearchAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.CommentSearchAdminResponse> {
    return this.dispatcherDelegate({
      type: Action.commentSearchAdmin,
      meta: {
        ...metaExtra,
        action: Action.commentSearchAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentSearchAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentAdminApi().commentSearchAdmin(request, headerExtra),
    });
  }

  commentSubscribeAdmin(request: CommentAdminApi.CommentSubscribeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.commentSubscribeAdmin,
      meta: {
        ...metaExtra,
        action: Action.commentSubscribeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentSubscribeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentAdminApi().commentSubscribeAdmin(request, headerExtra),
    });
  }

  commentUnsubscribeAdmin(request: CommentAdminApi.CommentUnsubscribeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.commentUnsubscribeAdmin,
      meta: {
        ...metaExtra,
        action: Action.commentUnsubscribeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentUnsubscribeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentAdminApi().commentUnsubscribeAdmin(request, headerExtra),
    });
  }

  contentUploadAsAdmin(request: ContentAdminApi.ContentUploadAsAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ContentUploadAsAdminResponse> {
    return this.dispatcherDelegate({
      type: Action.contentUploadAsAdmin,
      meta: {
        ...metaExtra,
        action: Action.contentUploadAsAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.contentUploadAsAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getContentAdminApi().contentUploadAsAdmin(request, headerExtra),
    });
  }

  creditIncome(request: CreditAdminApi.CreditIncomeRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.creditIncome,
      meta: {
        ...metaExtra,
        action: Action.creditIncome,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.creditIncome(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCreditAdminApi().creditIncome(request, headerExtra),
    });
  }

  transactionSearchAdmin(request: CreditAdminApi.TransactionSearchAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.TransactionSearchAdminResponse> {
    return this.dispatcherDelegate({
      type: Action.transactionSearchAdmin,
      meta: {
        ...metaExtra,
        action: Action.transactionSearchAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.transactionSearchAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCreditAdminApi().transactionSearchAdmin(request, headerExtra),
    });
  }

  ideaCategoryAggregateAdmin(request: IdeaAdminApi.IdeaCategoryAggregateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaAggregateResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaCategoryAggregateAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaCategoryAggregateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaCategoryAggregateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaCategoryAggregateAdmin(request, headerExtra),
    });
  }

  ideaCreateAdmin(request: IdeaAdminApi.IdeaCreateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaWithVote> {
    return this.dispatcherDelegate({
      type: Action.ideaCreateAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaCreateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaCreateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaCreateAdmin(request, headerExtra),
    });
  }

  ideaDeleteAdmin(request: IdeaAdminApi.IdeaDeleteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDeleteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDeleteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDeleteAdmin(request, headerExtra),
    });
  }

  ideaDeleteBulkAdmin(request: IdeaAdminApi.IdeaDeleteBulkAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaDeleteBulkAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDeleteBulkAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDeleteBulkAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDeleteBulkAdmin(request, headerExtra),
    });
  }

  ideaDraftCreateAdmin(request: IdeaAdminApi.IdeaDraftCreateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaDraftAdmin> {
    return this.dispatcherDelegate({
      type: Action.ideaDraftCreateAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDraftCreateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDraftCreateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDraftCreateAdmin(request, headerExtra),
    });
  }

  ideaDraftDeleteAdmin(request: IdeaAdminApi.IdeaDraftDeleteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaDraftDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDraftDeleteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDraftDeleteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDraftDeleteAdmin(request, headerExtra),
    });
  }

  ideaDraftGetAdmin(request: IdeaAdminApi.IdeaDraftGetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaDraftAdmin> {
    return this.dispatcherDelegate({
      type: Action.ideaDraftGetAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDraftGetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDraftGetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDraftGetAdmin(request, headerExtra),
    });
  }

  ideaDraftSearchAdmin(request: IdeaAdminApi.IdeaDraftSearchAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaDraftSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaDraftSearchAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDraftSearchAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDraftSearchAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDraftSearchAdmin(request, headerExtra),
    });
  }

  ideaDraftUpdateAdmin(request: IdeaAdminApi.IdeaDraftUpdateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaDraftUpdateAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaDraftUpdateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDraftUpdateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaDraftUpdateAdmin(request, headerExtra),
    });
  }

  ideaGetAdmin(request: IdeaAdminApi.IdeaGetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Idea> {
    return this.dispatcherDelegate({
      type: Action.ideaGetAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaGetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaGetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaGetAdmin(request, headerExtra),
    });
  }

  ideaHistogramAdmin(request: IdeaAdminApi.IdeaHistogramAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.HistogramResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaHistogramAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaHistogramAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaHistogramAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaHistogramAdmin(request, headerExtra),
    });
  }

  ideaLinkAdmin(request: IdeaAdminApi.IdeaLinkAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaConnectResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaLinkAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaLinkAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaLinkAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaLinkAdmin(request, headerExtra),
    });
  }

  ideaMergeAdmin(request: IdeaAdminApi.IdeaMergeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaConnectResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaMergeAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaMergeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaMergeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaMergeAdmin(request, headerExtra),
    });
  }

  ideaSearchAdmin(request: IdeaAdminApi.IdeaSearchAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaSearchAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaSearchAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaSearchAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaSearchAdmin(request, headerExtra),
    });
  }

  ideaSubscribeAdmin(request: IdeaAdminApi.IdeaSubscribeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaSubscribeAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaSubscribeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaSubscribeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaSubscribeAdmin(request, headerExtra),
    });
  }

  ideaUnLinkAdmin(request: IdeaAdminApi.IdeaUnLinkAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaConnectResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaUnLinkAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaUnLinkAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaUnLinkAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaUnLinkAdmin(request, headerExtra),
    });
  }

  ideaUnMergeAdmin(request: IdeaAdminApi.IdeaUnMergeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaConnectResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaUnMergeAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaUnMergeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaUnMergeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaUnMergeAdmin(request, headerExtra),
    });
  }

  ideaUnsubscribeAdmin(request: IdeaAdminApi.IdeaUnsubscribeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaUnsubscribeAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaUnsubscribeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaUnsubscribeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaUnsubscribeAdmin(request, headerExtra),
    });
  }

  ideaUpdateAdmin(request: IdeaAdminApi.IdeaUpdateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Idea> {
    return this.dispatcherDelegate({
      type: Action.ideaUpdateAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaUpdateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaUpdateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaUpdateAdmin(request, headerExtra),
    });
  }

  ideaVotersGetAdmin(request: IdeaAdminApi.IdeaVotersGetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaVotersAdminResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaVotersGetAdmin,
      meta: {
        ...metaExtra,
        action: Action.ideaVotersGetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaVotersGetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaAdminApi().ideaVotersGetAdmin(request, headerExtra),
    });
  }

  convoDeleteAdmin(request: LlmAdminApi.ConvoDeleteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.convoDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.convoDeleteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.convoDeleteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getLlmAdminApi().convoDeleteAdmin(request, headerExtra),
    });
  }

  convoDetailsAdmin(request: LlmAdminApi.ConvoDetailsAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ConvoDetailsResponse> {
    return this.dispatcherDelegate({
      type: Action.convoDetailsAdmin,
      meta: {
        ...metaExtra,
        action: Action.convoDetailsAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.convoDetailsAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getLlmAdminApi().convoDetailsAdmin(request, headerExtra),
    });
  }

  convoListAdmin(request: LlmAdminApi.ConvoListAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ConvoListResponse> {
    return this.dispatcherDelegate({
      type: Action.convoListAdmin,
      meta: {
        ...metaExtra,
        action: Action.convoListAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.convoListAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getLlmAdminApi().convoListAdmin(request, headerExtra),
    });
  }

  messageCreateAdmin(request: LlmAdminApi.MessageCreateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.CreateMessageResponse> {
    return this.dispatcherDelegate({
      type: Action.messageCreateAdmin,
      meta: {
        ...metaExtra,
        action: Action.messageCreateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.messageCreateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getLlmAdminApi().messageCreateAdmin(request, headerExtra),
    });
  }

  messageStreamGetAdmin(request: LlmAdminApi.MessageStreamGetAdminRequest, ): EventSource {
    return this.apiDelegate.getLlmAdminApi().messageStreamGetAdmin(request);
  }

  promptGetSuperAdmin(request: LlmSuperAdminApi.PromptGetSuperAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.PromptGetResponse> {
    return this.dispatcherDelegate({
      type: Action.promptGetSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.promptGetSuperAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.promptGetSuperAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getLlmSuperAdminApi().promptGetSuperAdmin(request, headerExtra),
    });
  }

  plansGet(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.PlansGetResponse> {
    return this.dispatcherDelegate({
      type: Action.plansGet,
      meta: {
        ...metaExtra,
        action: Action.plansGet,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.plansGet(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getPlanAdminApi().plansGet(headerExtra),
    });
  }

  plansGetSuperAdmin(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.AllPlansGetResponse> {
    return this.dispatcherDelegate({
      type: Action.plansGetSuperAdmin,
      meta: {
        ...metaExtra,
        action: Action.plansGetSuperAdmin,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.plansGetSuperAdmin(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getPlanSuperAdminApi().plansGetSuperAdmin(headerExtra),
    });
  }

  configGetAdmin(request: ProjectAdminApi.ConfigGetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.VersionedConfigAdmin> {
    return this.dispatcherDelegate({
      type: Action.configGetAdmin,
      meta: {
        ...metaExtra,
        action: Action.configGetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.configGetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().configGetAdmin(request, headerExtra),
    });
  }

  configGetAllAndUserBindAllAdmin(metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ConfigAndBindAllResult> {
    return this.dispatcherDelegate({
      type: Action.configGetAllAndUserBindAllAdmin,
      meta: {
        ...metaExtra,
        action: Action.configGetAllAndUserBindAllAdmin,
        request: undefined,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.configGetAllAndUserBindAllAdmin(metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().configGetAllAndUserBindAllAdmin(headerExtra),
    });
  }

  configSetAdmin(request: ProjectAdminApi.ConfigSetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.VersionedConfigAdmin> {
    return this.dispatcherDelegate({
      type: Action.configSetAdmin,
      meta: {
        ...metaExtra,
        action: Action.configSetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.configSetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().configSetAdmin(request, headerExtra),
    });
  }

  projectAdminsInviteAdmin(request: ProjectAdminApi.ProjectAdminsInviteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ProjectAdminsInviteResult> {
    return this.dispatcherDelegate({
      type: Action.projectAdminsInviteAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectAdminsInviteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectAdminsInviteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectAdminsInviteAdmin(request, headerExtra),
    });
  }

  projectAdminsListAdmin(request: ProjectAdminApi.ProjectAdminsListAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ProjectAdminsListResult> {
    return this.dispatcherDelegate({
      type: Action.projectAdminsListAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectAdminsListAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectAdminsListAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectAdminsListAdmin(request, headerExtra),
    });
  }

  projectAdminsRemoveAdmin(request: ProjectAdminApi.ProjectAdminsRemoveAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.projectAdminsRemoveAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectAdminsRemoveAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectAdminsRemoveAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectAdminsRemoveAdmin(request, headerExtra),
    });
  }

  projectCreateAdmin(request: ProjectAdminApi.ProjectCreateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.NewProjectResult> {
    return this.dispatcherDelegate({
      type: Action.projectCreateAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectCreateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectCreateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectCreateAdmin(request, headerExtra),
    });
  }

  projectDeleteAdmin(request: ProjectAdminApi.ProjectDeleteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.projectDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectDeleteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectDeleteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectDeleteAdmin(request, headerExtra),
    });
  }

  projectExportAdmin(request: ProjectAdminApi.ProjectExportAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<runtime.FileDownload> {
    return this.dispatcherDelegate({
      type: Action.projectExportAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectExportAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectExportAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectExportAdmin(request, headerExtra),
    });
  }

  projectImportPostAdmin(request: ProjectAdminApi.ProjectImportPostAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ImportResponse> {
    return this.dispatcherDelegate({
      type: Action.projectImportPostAdmin,
      meta: {
        ...metaExtra,
        action: Action.projectImportPostAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.projectImportPostAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectAdminApi().projectImportPostAdmin(request, headerExtra),
    });
  }

  supportMessage(request: SupportApi.SupportMessageRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.supportMessage,
      meta: {
        ...metaExtra,
        action: Action.supportMessage,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.supportMessage(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getSupportApi().supportMessage(request, headerExtra),
    });
  }

  userCreateAdmin(request: UserAdminApi.UserCreateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserAdmin> {
    return this.dispatcherDelegate({
      type: Action.userCreateAdmin,
      meta: {
        ...metaExtra,
        action: Action.userCreateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userCreateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userCreateAdmin(request, headerExtra),
    });
  }

  userDeleteAdmin(request: UserAdminApi.UserDeleteAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.userDeleteAdmin,
      meta: {
        ...metaExtra,
        action: Action.userDeleteAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userDeleteAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userDeleteAdmin(request, headerExtra),
    });
  }

  userDeleteBulkAdmin(request: UserAdminApi.UserDeleteBulkAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.userDeleteBulkAdmin,
      meta: {
        ...metaExtra,
        action: Action.userDeleteBulkAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userDeleteBulkAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userDeleteBulkAdmin(request, headerExtra),
    });
  }

  userGetAdmin(request: UserAdminApi.UserGetAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserAdmin> {
    return this.dispatcherDelegate({
      type: Action.userGetAdmin,
      meta: {
        ...metaExtra,
        action: Action.userGetAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userGetAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userGetAdmin(request, headerExtra),
    });
  }

  userHistogramAdmin(request: UserAdminApi.UserHistogramAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.HistogramResponse> {
    return this.dispatcherDelegate({
      type: Action.userHistogramAdmin,
      meta: {
        ...metaExtra,
        action: Action.userHistogramAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userHistogramAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userHistogramAdmin(request, headerExtra),
    });
  }

  userSearchAdmin(request: UserAdminApi.UserSearchAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.userSearchAdmin,
      meta: {
        ...metaExtra,
        action: Action.userSearchAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userSearchAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userSearchAdmin(request, headerExtra),
    });
  }

  userSubscribeAdmin(request: UserAdminApi.UserSubscribeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.userSubscribeAdmin,
      meta: {
        ...metaExtra,
        action: Action.userSubscribeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userSubscribeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userSubscribeAdmin(request, headerExtra),
    });
  }

  userUnsubscribeAdmin(request: UserAdminApi.UserUnsubscribeAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.userUnsubscribeAdmin,
      meta: {
        ...metaExtra,
        action: Action.userUnsubscribeAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userUnsubscribeAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userUnsubscribeAdmin(request, headerExtra),
    });
  }

  userUpdateAdmin(request: UserAdminApi.UserUpdateAdminRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserAdmin> {
    return this.dispatcherDelegate({
      type: Action.userUpdateAdmin,
      meta: {
        ...metaExtra,
        action: Action.userUpdateAdmin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userUpdateAdmin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserAdminApi().userUpdateAdmin(request, headerExtra),
    });
  }
}
