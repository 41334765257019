/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ErrorResponse2,
    ErrorResponse2FromJSON,
    ErrorResponse2ToJSON,
    Idea,
    IdeaFromJSON,
    IdeaToJSON,
    IdeaConnectResponse,
    IdeaConnectResponseFromJSON,
    IdeaConnectResponseToJSON,
    IdeaCreate,
    IdeaCreateFromJSON,
    IdeaCreateToJSON,
    IdeaGetAll,
    IdeaGetAllFromJSON,
    IdeaGetAllToJSON,
    IdeaGetAllResponse,
    IdeaGetAllResponseFromJSON,
    IdeaGetAllResponseToJSON,
    IdeaSearch,
    IdeaSearchFromJSON,
    IdeaSearchToJSON,
    IdeaUpdate,
    IdeaUpdateFromJSON,
    IdeaUpdateToJSON,
    IdeaWithVote,
    IdeaWithVoteFromJSON,
    IdeaWithVoteToJSON,
    IdeaWithVoteSearchResponse,
    IdeaWithVoteSearchResponseFromJSON,
    IdeaWithVoteSearchResponseToJSON,
} from '../models';

export interface IdeaCreateRequest {
    projectId: string;
    ideaCreate: IdeaCreate;
}

export interface IdeaDeleteRequest {
    projectId: string;
    ideaId: string;
}

export interface IdeaGetRequest {
    projectId: string;
    ideaId: string;
}

export interface IdeaGetAllRequest {
    projectId: string;
    ideaGetAll: IdeaGetAll;
}

export interface IdeaMergeRequest {
    projectId: string;
    ideaId: string;
    parentIdeaId: string;
}

export interface IdeaSearchRequest {
    projectId: string;
    ideaSearch: IdeaSearch;
    cursor?: string;
}

export interface IdeaUpdateRequest {
    projectId: string;
    ideaId: string;
    ideaUpdate: IdeaUpdate;
}

/**
 * no description
 */
export interface IdeaApiInterface {

    /**
     */
    ideaCreate(requestParameters: IdeaCreateRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVote>;

    /**
     */
    ideaDelete(requestParameters: IdeaDeleteRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    ideaGet(requestParameters: IdeaGetRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVote>;

    /**
     */
    ideaGetAll(requestParameters: IdeaGetAllRequest, headerExtra?:{[key:string]:string}): Promise<IdeaGetAllResponse>;

    /**
     */
    ideaMerge(requestParameters: IdeaMergeRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse>;

    /**
     */
    ideaSearch(requestParameters: IdeaSearchRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVoteSearchResponse>;

    /**
     */
    ideaUpdate(requestParameters: IdeaUpdateRequest, headerExtra?:{[key:string]:string}): Promise<Idea>;
}

/**
 * no description
 */
export class IdeaApi extends runtime.BaseAPI implements IdeaApiInterface {

    /**
     */
    async ideaCreateRaw(requestParameters: IdeaCreateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaWithVote>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaCreate.');
        }

        if (requestParameters.ideaCreate === null || requestParameters.ideaCreate === undefined) {
            throw new runtime.RequiredError('ideaCreate','Required parameter requestParameters.ideaCreate was null or undefined when calling ideaCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/idea`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaCreateToJSON(requestParameters.ideaCreate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaWithVoteFromJSON(jsonValue));
    }

    /**
     */
    async ideaCreate(requestParameters: IdeaCreateRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVote> {
        const response = await this.ideaCreateRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaDeleteRaw(requestParameters: IdeaDeleteRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaDelete.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ideaDelete(requestParameters: IdeaDeleteRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.ideaDeleteRaw(requestParameters, headerExtra);
    }

    /**
     */
    async ideaGetRaw(requestParameters: IdeaGetRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaWithVote>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaGet.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaWithVoteFromJSON(jsonValue));
    }

    /**
     */
    async ideaGet(requestParameters: IdeaGetRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVote> {
        const response = await this.ideaGetRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaGetAllRaw(requestParameters: IdeaGetAllRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaGetAllResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaGetAll.');
        }

        if (requestParameters.ideaGetAll === null || requestParameters.ideaGetAll === undefined) {
            throw new runtime.RequiredError('ideaGetAll','Required parameter requestParameters.ideaGetAll was null or undefined when calling ideaGetAll.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/ideagetall`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaGetAllToJSON(requestParameters.ideaGetAll),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaGetAllResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaGetAll(requestParameters: IdeaGetAllRequest, headerExtra?:{[key:string]:string}): Promise<IdeaGetAllResponse> {
        const response = await this.ideaGetAllRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaMergeRaw(requestParameters: IdeaMergeRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaConnectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaMerge.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaMerge.');
        }

        if (requestParameters.parentIdeaId === null || requestParameters.parentIdeaId === undefined) {
            throw new runtime.RequiredError('parentIdeaId','Required parameter requestParameters.parentIdeaId was null or undefined when calling ideaMerge.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}/merge/{parentIdeaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))).replace(`{${"parentIdeaId"}}`, encodeURIComponent(String(requestParameters.parentIdeaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaConnectResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaMerge(requestParameters: IdeaMergeRequest, headerExtra?:{[key:string]:string}): Promise<IdeaConnectResponse> {
        const response = await this.ideaMergeRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaSearchRaw(requestParameters: IdeaSearchRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<IdeaWithVoteSearchResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaSearch.');
        }

        if (requestParameters.ideaSearch === null || requestParameters.ideaSearch === undefined) {
            throw new runtime.RequiredError('ideaSearch','Required parameter requestParameters.ideaSearch was null or undefined when calling ideaSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/ideasearch`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaSearchToJSON(requestParameters.ideaSearch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaWithVoteSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async ideaSearch(requestParameters: IdeaSearchRequest, headerExtra?:{[key:string]:string}): Promise<IdeaWithVoteSearchResponse> {
        const response = await this.ideaSearchRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async ideaUpdateRaw(requestParameters: IdeaUpdateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<Idea>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling ideaUpdate.');
        }

        if (requestParameters.ideaId === null || requestParameters.ideaId === undefined) {
            throw new runtime.RequiredError('ideaId','Required parameter requestParameters.ideaId was null or undefined when calling ideaUpdate.');
        }

        if (requestParameters.ideaUpdate === null || requestParameters.ideaUpdate === undefined) {
            throw new runtime.RequiredError('ideaUpdate','Required parameter requestParameters.ideaUpdate was null or undefined when calling ideaUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/idea/{ideaId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"ideaId"}}`, encodeURIComponent(String(requestParameters.ideaId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IdeaUpdateToJSON(requestParameters.ideaUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaFromJSON(jsonValue));
    }

    /**
     */
    async ideaUpdate(requestParameters: IdeaUpdateRequest, headerExtra?:{[key:string]:string}): Promise<Idea> {
        const response = await this.ideaUpdateRaw(requestParameters, headerExtra);
        return await response.value();
    }

}
