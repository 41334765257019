/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    CommentWithVote,
    CommentWithVoteFromJSON,
    CommentWithVoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommentSearchResponse
 */
export interface CommentSearchResponse {
    /**
     * Cursor to fetch next set of results.
     * @type {string}
     * @memberof CommentSearchResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<CommentWithVote>}
     * @memberof CommentSearchResponse
     */
    results: Array<CommentWithVote>;
}

export function CommentSearchResponseFromJSON(json: any): CommentSearchResponse {
    return {
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        
        'results': (json['results'] as Array<any>).map(CommentWithVoteFromJSON),
        
    };
}

export function CommentSearchResponseToJSON(value?: CommentSearchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cursor': value.cursor,
        
        'results': (value.results as Array<any>).map(CommentWithVoteToJSON),
        
    };
}


