/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationMethodsOauth
 */
export interface NotificationMethodsOauth {
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    oauthId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    buttonTitle: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    authorizeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    tokenUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    scope: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    userProfileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    guidJsonPath: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    nameJsonPath?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    emailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMethodsOauth
     */
    emailJsonPath?: string;
}

export function NotificationMethodsOauthFromJSON(json: any): NotificationMethodsOauth {
    return {
        'oauthId': json['oauthId'],
        'buttonTitle': json['buttonTitle'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'clientId': json['clientId'],
        'authorizeUrl': json['authorizeUrl'],
        'tokenUrl': json['tokenUrl'],
        'scope': json['scope'],
        'userProfileUrl': json['userProfileUrl'],
        'guidJsonPath': json['guidJsonPath'],
        'nameJsonPath': !exists(json, 'nameJsonPath') ? undefined : json['nameJsonPath'],
        'emailUrl': !exists(json, 'emailUrl') ? undefined : json['emailUrl'],
        'emailJsonPath': !exists(json, 'emailJsonPath') ? undefined : json['emailJsonPath'],
    };
}

export function NotificationMethodsOauthToJSON(value?: NotificationMethodsOauth): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'oauthId': value.oauthId,
        'buttonTitle': value.buttonTitle,
        'icon': value.icon,
        'clientId': value.clientId,
        'authorizeUrl': value.authorizeUrl,
        'tokenUrl': value.tokenUrl,
        'scope': value.scope,
        'userProfileUrl': value.userProfileUrl,
        'guidJsonPath': value.guidJsonPath,
        'nameJsonPath': value.nameJsonPath,
        'emailUrl': value.emailUrl,
        'emailJsonPath': value.emailJsonPath,
    };
}


